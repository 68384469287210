// app/js/controllers/HomeCtrl.js
ServicesParentCtrl.controller('DSS-AccountController', function($rootScope, 
                                                    $scope, 
                                                    $route,
                                                    $routeParams,
                                                    $location, 
                                                    $localStorage, 
                                                    $sessionStorage, 
                                                    $window, 
                                                    $compile,
                                                    Authentication, 
                                                    Application, 
                                                    RouteFilter,
                                                    LoadLocalData,
                                                    DEX,
                                                    CiviCRM){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Service';
    $scope.page.header.title = 'Department of Social Services';
    $scope.page.header.backLink = '#!/services';
    $scope.page.sidebar.variant = 'serviceDSS';
    $scope.page.sidebar.selectedNav = 'Account';
    
    // DOM Styling Functions
    $scope.class = {};
    $scope.class.isApproved = function(active){
        if(active === 'APPROVED'){
            return 'isApproved';
        }else{
            return 'notApproved';
        }
    };
    
    if($rootScope.isCiviAccessSetup()){
        $scope.getDexUser = function() {
            LoadLocalData.get.dexUser().then(function(userData){
                if(!Array.isArray(userData.OutletActivities.OutletActivity)){
                    userData.OutletActivities.OutletActivity = [userData.OutletActivities.OutletActivity];
                }
                
                $rootScope.serviceAccount = userData;
            }, function(errorData){
                console.error('Issue retrieving Dex User data', errorData);
                $scope.issueFound = true;
                $('#issue-notification h2').text('Issue Loading User');
                $('#issue-notification').append('<p>'+ errorData +'</p>');
            });
        };
        $scope.getDexUser();
    }
    
});

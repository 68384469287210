// app/js/controllers/HomeCtrl.js
angular.module('DataTransmitter')
    .factory("Validate", function Validate($q, $http, $timeout, $localStorage){
    
    return {        
        data: {
            isSet: function(data){
                if(typeof data !== 'undefined' && data !== null){
                    // Check Length
                    if(typeof data === 'string'){
                        if(data === '' || !data.length){
                            return false;
                        }
                    }
                    
                    return true;
                }
                return false;
            }
        },
		number: {
			isZero: function(number){
				if(number === 0 || number === 0.00 || number === '0' || number === '0.00'){
					return true;
				}
			}
		}
    };
});

// app/js/controllers/HomeCtrl.js
ServicesParentCtrl.controller('DSS-ClientsController', function($rootScope, 
                                                                $scope, 
                                                                $route,
                                                                $routeParams,
                                                                $location, 
                                                                $localStorage, 
                                                                $sessionStorage, 
                                                                $window,
                                                                $sce,
                                                                $compile,
                                                                Validate,
                                                                Authentication, 
                                                                Application, 
                                                                RouteFilter,
                                                                LoadLocalData, 
                                                                CiviExchange,
                                                                CiviCRM,
                                                                DEX,
                                                                Notify,
                                                                Graph){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Service';
    $scope.page.header.title = 'Department of Social Services';
    $scope.page.header.backLink = '#!/services';
    $scope.page.sidebar.variant = 'serviceDSS';
    $scope.page.sidebar.selectedNav = 'Clients';
    
    var initClients = function(){
        $scope.clientsFound = false;
        $scope.outletsFound = false;
        $scope.issuesFound = false;
        $scope.dates = {
            start: '',
            end: '',
        };
        $scope.currentPage = 'clientData';
        $scope.showPage = function(pageName){
            $scope.currentPage = pageName;                 
        };
        $scope.selectedButton = 'Data';
        $scope.setSelectedButton = function(name){
            $scope.selectedButton = name;
        };
        $scope.clients = null;
        $scope.filteredClients = null;
        $scope.sessions = null;
        $scope.filteredSessions = null;
        $scope.localReferenceData = null;
        $scope.outletType = {
            code: '0',
            nativeId: null,
            selected: '0',
            availableOptions: [],
        };
        
        var display = {
            issueNotification: function(){
                if($scope.issuesFound || !$scope.clientsFound || !$scope.clientsLoaded || !$scope.outletsFound || !$scope.isCiviAccessSetup()){
                    return true;
                }
                
                return false;
            },
            noClients: function(){
                $scope.clientsFound = false;
            }
        };
        
        $scope.display = display;
        
        var serviceTypeForOutletActivities = '';
        
        var loadClientsLocal = Object.create(load);
        
        $scope.updateOutlet = function(){
            $scope.cancelRequests();
            $scope.isValidated = false;
            $scope.showPage('clientData');
            $scope.setSelectedButton('Data');
			$scope.issuesFound = false;
            
            var setFilteredData = function(filteredData){
                if(!Validate.data.isSet(filteredData) || 
                   !Validate.data.isSet(filteredData.clients) || 
                   !Validate.data.isSet(filteredData.sessions)){
                    display.noClients();
                    return false;
                }
                
                $scope.filteredClients = filteredData.clients;
                $scope.filteredSessions = filteredData.sessions;
                
                $scope.createLetterFilterList($scope.filteredClients);

                $scope.searchClients($scope.searchClientsVal, $scope.filteredClients);
            };
            
            $scope.filterSessionsByOutlet = function(sessionVariables){
                $('.search-wrap').append(loadClientsLocal.begin('filterSessionsByOutlet', 'inline', 'Searching CiviCRM Clients'));
                
                CiviCRM.find.sessions.byOutlet(sessionVariables).then(function(sessionsFound){
                    loadClientsLocal.remove(false, 'filterSessionsByOutlet');
                    
                    $scope.filterClientsBySessions(sessionsFound);
                }, function(error){
                    console.error('Issue trying to filter sessions by outlet', error);
                    if(loadClientsLocal.retry('', 'Error recieved ('+ error +').')){
                       $scope.filterSessionsByOutlet(sessionVariables);
                    }else{
                        display.noClients();
                    }
                });
            };
            
            $scope.filterClientsBySessions = function(sessionsFound){
                $('.search-wrap').append(loadClientsLocal.begin('filterClientsBySessions', 'inline', 'Searching CiviCRM Clients'));
                
                CiviCRM.find.clients.withSessions({sessions: sessionsFound}).then(function(csFound){
                    loadClientsLocal.remove(false, 'filterClientsBySessions');
                    
                    setFilteredData(csFound);
                }, function(error){
                    if(loadClientsLocal.retry('', 'Error recieved ('+ error +').')){
                        $scope.filterClientsBySessions(sessionsFound);
                    }else{
                        display.noClients();
                    }
                });
            };
            
            $scope.createLetterFilterList = function(clients){
                var temp = $compile(CiviCRM.display.clients.filterList({clients: clients}))($scope);
                angular.element(document.getElementById('CiviCRMFilterList')).html(temp);
            };
            
            $scope.initFilter = function(){
                console.log('Initialising Filter');
                if($scope.outletType.availableOptions.length){
                    
                    var rules;
                    for(var i = 0; i < $scope.outletType.availableOptions.length; i++){
                        var option = $scope.outletType.availableOptions[i];
                        console.log('OPTION', option);
                        
                        if(option.id === $scope.outletType.selected){
                            $scope.outletType.code = option.val;
                            $scope.outletType.nativeId = option.nativeId;
                            
                            if(Validate.data.isSet(option.rules)){
                                for(var j = 0; j < option.rules.length; j++){
                                    try {
                                        option.rules[j].rule = JSON.parse(option.rules[j].rule);
                                    }catch(e){}
                                }
                                rules = option.rules;
                            }
                        }
                    }
                    // Set selected outlet code
                    $scope.filterSessionsByOutlet({startDate: $scope.dates.start, endDate: $scope.dates.end, rules: rules});
                }else{
                    console.log('HERE');
                    $scope.outletsFound = false;
                    display.noClients();
                }
            };
            
            $scope.initFilter();
        };

        var clientPosition = 0;
        var pageLength = 0;
        $scope.SendProcessLog = '';
        $scope.clientsLoaded = false;
        $scope.sessionsLoaded = false;
        $scope.localReferenceDataLoaded = false;
        $scope.transferStarted = false;
        $scope.sendingContainer = '';

        $scope.searchClientsVal = '';
        $scope.firstChar = 'all';
        
        $scope.searchClients = function(query, clients) {
            $scope.filterClients($scope.filteredClients);
        };
        
        $scope.filterByCharacter = function(firstChar){
            if(!Validate.data.isSet(firstChar)){
                firstChar = 'all';
            }
            
            $scope.firstChar = firstChar;
            
            $scope.filterClients($scope.filteredClients);
        };
        
        $scope.filterClients = function(clients){
            $('.search-wrap').append(loadClientsLocal.begin('filterByCharacter', 'inline', 'Searching CiviCRM Clients'));
            
            CiviCRM.find.clients.query({data: clients, query: $scope.searchClientsVal}).then(function(clientsFound){
                console.info('Found clients by search query', $scope.firstChar, $scope.searchClientsVal, clientsFound);
                $scope.createLetterFilterList(clientsFound);
                
                if(!$('.data-title-filter li[data-char="'+ $scope.firstChar +'"]').length){
                    if($('.data-title-filter li:nth-child(1)').length){
                        $scope.firstChar = $('.data-title-filter li:nth-child(1)').attr('data-char');
                    }
                }
                
                $('.data-title-filter li.selected').each(function(){
                    $(this).removeClass('selected');
                });
                $('.data-title-filter li[data-char="'+ $scope.firstChar +'"]').addClass('selected');
                
                CiviCRM.find.clients.firstChar({data: clientsFound, query: $scope.firstChar}).then(function(clientsFound){
                    console.info('Found clients by first character', $scope.firstChar, clientsFound);
                    loadClientsLocal.remove(false, 'filterByCharacter');
                    $scope.displayClients(clientsFound);
                    $scope.clientsFound = true;
                    
                    if(clientsFound.length <= 0){
                        display.noClients();
                    }
                }, function(errorData){
                    console.error('Issue finding clients by first character', $scope.firstChar, errorData);
                    
                    if(loadClientsLocal.retry('', 'Error recieved ('+ errorData +').')){
                        console.error('This needs to be fixed!');
                        //$scope.filterByCharacter(firstChar, clients);
                    }else{
                        console.error('Complete Fail: Issue finding clients by first character', $scope.firstChar, errorData);
                        display.noClients();
                    }
                });
            }, function(errorData){
                console.error('Issue finding clients by search query', $scope.searchClientsVal, errorData);
                
                if(loadClientsLocal.retry('', 'Error recieved ('+ errorData +').')){
                    console.error('This needs to be fixed!');
                    //$scope.searchClients(query, clients);
                }else{
                    console.error('Complete Fail: Issue finding clients by search querys', $scope.searchClientsVal, errorData);
                    display.noClients();
                }
            });
            
            
        };

        $scope.displayClients = function(clients) {
            var newPosition = clientPosition + pageLength;
            var temp = $compile(CiviCRM.display.clients.main({first: clientPosition, last: newPosition, data: clients}))($scope);
            angular.element(document.getElementById('CiviCRMClients')).html(temp);

            clientPosition = newPosition;
        };

        $scope.clearDisplay = function(clients) {
            var temp = '';
            angular.element(document.getElementById('CiviCRMFilterList')).html(temp);
            angular.element(document.getElementById('CiviCRMClients')).html(temp);
        };

        $scope.initClientFilter = function() {
            if($scope.clientsLoaded && $scope.sessionsLoaded && $scope.localReferenceDataLoaded && $scope.outletsFound){
                $scope.updateOutlet();
                $scope.issuesFound = false;
            }
        };
        
        var loadOutlets = function(){
            try{
                var curID = 0;
                var orgOutlets = $rootScope.authorisedUser.organisation.outlets[1];

                for(var i = 0; i < orgOutlets.length; i++){
                    var outletNativeId = null;
                    
                    if(Validate.data.isSet(orgOutlets[i].id)){
                        outletNativeId = orgOutlets[i].id;
                    }
                    
                    $scope.outletType.availableOptions.push({id: i+'', nativeId: outletNativeId, name: orgOutlets[i].name, val: orgOutlets[i].outlet_id, rules: orgOutlets[i].outletRules});
                }

                $scope.outletsFound = true;
                $scope.initClientFilter();
            }catch(e){
                $scope.outletsFound = false;
                display.noClients();
            }
        };
        loadOutlets();

        $scope.getLocalReferenceData = function(update) {
            $scope.localReferenceDataLoaded = false;
            LoadLocalData.get.localReferenceData('', update).then(function(localReferenceData){
                console.info('Local Reference Data loaded', localReferenceData);
                $scope.localReferenceDataLoaded = true;
                $scope.localReferenceData = localReferenceData;
                $scope.initClientFilter();
            }, function(errorData){
                console.error('Issue retrieving local reference data', errorData);
                $scope.issuesFound = true;
            });
        };
        
        $scope.getCiviCRMSessions = function(update) {
            $scope.sessionsLoaded = false;
            LoadLocalData.get.sessions({startDate: $scope.dates.start, endDate: $scope.dates.end}, update).then(function(sessions){
                if(!Validate.data.isSet(sessions)){
                    console.error('No sessions found');
                    $scope.sessions = sessions;
                    $scope.issuesFound = true;
                    $scope.clearDisplay();
                }else{
                    console.info('Sessions loaded', sessions);
                    $scope.sessionsLoaded = true;
                    $scope.sessions = sessions;
                    $scope.initClientFilter();
                }
            }, function(errorData){
                console.error('Issue retrieving clients', errorData);
                $scope.issuesFound = true;
            });
        };

        $scope.getCiviCRMClients = function(update) {
            $scope.clientsLoaded = false;
            LoadLocalData.get.clients({startDate: $scope.dates.start, endDate: $scope.dates.end}, update).then(function(clients){
                if(!Validate.data.isSet(clients)){
                    console.error('No clients found');
                    $scope.clientsFound = false;
                    $scope.filteredClients = clients;
                    $scope.issuesFound = true;
                    $scope.clearDisplay();
                }else{
                    console.info('Clients loaded', clients);
                    $scope.clientsLoaded = true;
                    $scope.clientsFound = true;
                    $scope.filteredClients = clients;
                    $scope.initClientFilter();
                }
            }, function(errorData){
                console.error('Issue retrieving clients', errorData);
                $scope.issuesFound = true;
            });
        };
        
        $scope.IssueCount = 0;
        var addClient = Object.create(load);
        $scope.addOpenClientToDSS = function(id) {
            $scope.ValidateClient = addClient.begin('validateClient', 'inline', 'Sending Client');
            
            var clientData = CiviCRM.get.client(id);
            var sendClient = function(clientData){
                DEX.send.client(clientData).then(function(data){
                    console.info('Client Sent', data);
                    
                    if(data.valid && $scope.filteredSessions.length > 0){
                        $scope.currentClient.isValid = true;
                        $scope.currentClient.isSent = false;
                    }else if(data.valid){
                        $scope.currentClient.isValid = true;
                        $scope.currentClient.isSent = true;
                    }else{
                        // var retError = '';
                        
                        // for(var i = 0; i < data.data.length; i++){
                        //     var message = data.data[i].message;
                        //     retError += '<p class="'+ message.messageLevel +'">'+ message.messageTitle +': '+ message.messageDescription +'</p>';
                        // }
                        // $scope.IssueCount += data.data.length;
                        // $scope.IssueDetails += retError;

                        for(var i = 0; i < data.data.length; i++){
                            var message = data.data[i];
                            $scope.IssueDetails.push(message);
                        }

                        $scope.IssueCount = $scope.IssueDetails.length;
                        $scope.currentClient.isValid = false;
                        $scope.currentClient.isSent = false;
                        console.error('Issues Details Invalid Client', $scope.IssueDetails);
                    }

                    if ($scope.currentClient.isValid) {
                        $scope.LogDetails.push({
                            message: {
                                messageTitle: 'Sent Client',
                                messageDescription: 'Client Id - ' + data.client.ClientId
                            }
                        });
                    }

                    CiviCRM.get.userSessions({id: id, data: $scope.filteredSessions}).then(function(clientSessions){
                        console.info('Sessions Found', clientSessions);
                        
                        var totalAttemptCount = 0;
                        var usersSentCount = 0;

                        for(var i = 0; i < clientSessions.length; i++){
                            var clientSession = clientSessions[i];
                            clientSession.OutletActivityId = $scope.outletType.code;
                            
                            DEX.send.session(clientSession).then(function(sessionData){
                                console.info('Session Sent', sessionData);

                                $scope.LogDetails.push({
                                    message: {
                                        messageTitle: 'Sent Session',
                                        messageDescription: 'Case Id - ' + clientSession.CaseId + ', Session Id - ' + clientSession.SessionId
                                    }
                                });

                                totalAttemptCount++;
                                usersSentCount++;

                                if (usersSentCount >= clientSessions.length) {
                                    $scope.currentClient.isSent = true;
                                }

                                if (totalAttemptCount >= clientSessions.length) {
                                    $scope.ValidateClient = '';
                                    $scope.currentClient.isValidated = true;
                                }
                            }, function(errorData){
                                console.error('Sending Sessions', errorData);
                                // var retError = '';
                                // for(var i = 0; i < errorData.data.length; i++){
                                //     var message = errorData.data[i].message;
                                //     retError += '<p class="'+ message.messageLevel +'">'+ message.messageTitle +': '+ message.messageDescription +'</p>';
                                // }
                                // $scope.IssueCount += errorData.data.length;
                                // $scope.IssueDetails += retError;

                                for(var i = 0; i < errorData.data.length; i++){
                                    var message = errorData.data[i];
                                    $scope.IssueDetails.push(message);
                                }

                                $scope.IssueCount = $scope.IssueDetails.length;
                                $scope.currentClient.isValid = false;
                                $scope.currentClient.isSent = false;
                                console.error('Issues Details Error Session', $scope.IssueDetails);

                                totalAttemptCount++;

                                if (totalAttemptCount >= clientSessions.length) {
                                    $scope.ValidateClient = '';
                                    $scope.currentClient.isValidated = true;
                                }
                            });
                        }
                        
                    });
                    
                }, function(errorData){
                    console.error('Sending Client', errorData);
                    if(addClient.retry('', 'Error recieved ('+ errorData +').')){
                        sendClient(clientData);
                    }else{
                        // var retError = '<p class="error">Could not send Client information. Please contact your administrator if this issue persists.<br>('+ errorData +')</p>';
                        // $scope.IssueDetails = retError;


                                // $scope.IssueDetails += retError;

                        for(var i = 0; i < errorData.data.length; i++){
                            var message = errorData.data[i];
                            $scope.IssueDetails.push(message);
                        }

                        $scope.IssueCount = $scope.IssueDetails.length;

                        $scope.currentClient.isValid = false;
                        $scope.currentClient.isValidated = true;
                        console.error('Complete Fail: Sending Client', errorData);
                        console.error('Issues Details Error Client', $scope.IssueDetails);
                    }
                });
            };
            sendClient(clientData);
        };
        
        $scope.modal = function(url, pagename){
            $scope.modal.properties = {};

            $scope.modal.open = function(url){
                $scope.modal.properties.url = url;
                var html = '<div ng-include="\''+ url +'\'" ng-click="modal.close()" id="modal-blanket" class="modal-blanket"></div>';
                var temp = $compile(html)($scope);
                angular.element(document.getElementById('inner-content')).append('<div id="modal-container"></div>');
                angular.element(document.getElementById('modal-container')).html(temp);
            };

            $scope.modal.loadPage = function(pageName){
                $scope.modal.properties.pageName = pageName;
                $scope.modal.open($scope.modal.properties.url);
            };

            $scope.modal.close = function(){
                $scope.modal.properties = {};
                angular.element(document.getElementById('modal-container')).remove();
            };

            if(typeof url === 'string'){
                $scope.modal.properties.url = url;
                if(typeof pagename === 'string'){
                    $scope.modal.loadPage(pagename);
                }else{
                    $scope.modal.open(url);
                }
            }
        };

        $scope.openClient = function(clientsId){
            $scope.IssueCount = 0;
            $scope.LogDetails = [];
            $scope.IssueDetails = [];
            if(!Validate.data.isSet(clientsId) && typeof clientsId !== 'number'){
                console.error('Cannot open client. Client ID is invalid.', clientsId);
                return false;
            }

            // Build and inject modal
            var $content = $('#clients-content');
            
            $scope.currentClient = {};
            $scope.currentClient.showPage = 'personal';

            var client = CiviCRM.get.client(clientsId);
            console.info('Opening Client', client);
            $scope.currentClient.client = client;
            $scope.validateClient(client);
            
            CiviCRM.get.userSessions({id: clientsId, data: $scope.filteredSessions}).then(function(sessions){
                console.info('Found Client Sessions', sessions);
                $scope.currentClient.sessions = sessions;
                
                CiviCRM.get.cases({sessions: sessions}).then(function(cases){
                    console.info('Found Client Cases', cases);
                    $scope.currentClient.cases = cases;
                }, function(errorData){
                    console.error('Issue Retrieving Client Cases', errorData);
                });
            }, function(errorData){
                console.error('Issue Retrieving Client Sessions', errorData);
            });

            $scope.loadModalPage = function(pageName){
                console.info('Load Modal Page', pageName);
                $scope.currentClient.showPage = pageName;
                $scope.compileModal();
            };

            $scope.closeModal = function(){
                console.info('Close Modal');
                angular.element(document.getElementById('modal-container')).remove();
                //$location.path('/services/dss/clients', false);
            };

            $scope.compileModal = function(pageName){
                var html = '<div ng-include="\'views/services/modals/modal.html\'" ng-click="closeModal()" id="modal-blanket" class="modal-blanket"></div>';
                var temp = $compile(html)($scope);
                angular.element(document.getElementById('modal-container')).html(temp);
            };

            angular.element(document.getElementById('clients-content')).append('<div id="modal-container"></div>');
            $scope.compileModal();

            // Add animate for fade in
            setTimeout(function(){
                $content.find('.modal').addClass('modal--animate');
            }, 200);

            // Cache elements
            var $modalBlanket = $('.modal-blanket');
            var $modalBtn = $('.modal-exit-btn');
        };

        var validateClient = Object.create(load);
        $scope.validateClient = function(client){
            $scope.ValidateClient = validateClient.begin('validateClient', 'inline', 'Validating Client');
            
            DEX.validate.client(client).then(function(validatedClient){
                
                if(validatedClient.valid){
                    console.info('Client Validated', client);

                    $scope.LogDetails.push({
                        message: {
                            messageTitle: 'Validated Client',
                            messageDescription: 'Client Id - ' + client.ClientID
                        }
                    });
                    $scope.currentClient.isValid = true;

                    if(Validate.data.isSet(validatedClient.specialClient)){
                        $scope.currentClient.specialClient = validatedClient.specialClient;
                    }
                }else{
                    console.log('Client is not valid', client);
                    var retError = '';
                    
                    for(var i = 0; i < validatedClient.data.length; i++){
                        var message = validatedClient.data[i].message;
                        retError += '<p class="'+ message.messageLevel +'">'+ message.messageTitle +': '+ message.messageDescription +'</p>';
                    }
                    $scope.IssueCount += validatedClient.data.length;
                    $scope.IssueDetails += retError;
                    $scope.currentClient.isValid = false;
                }
                console.log("VALIDATED1", validatedClient);
                console.log("VALIDATED2", $scope.currentClient);
                $scope.currentClient.isValidated = true;
                $scope.ValidateClient = '';
            }, function(errorData){
                console.error('Issue validating client', errorData);
                if(validateClient.retry('', 'Error recieved ('+ errorData +').')){
                    $scope.validateClient(client);
                }else{
                    $scope.IssueDetails = errorData.data;
                    $scope.currentClient.isValid = false;
                    $scope.currentClient.isValidated = true;
                    
                    $scope.ValidateClient = '';
                    console.error('Complete Fail: Issue validating client', errorData);
                }
            });
        };
        
        // Accordion functionality
        $scope.Accordion = {
            expand: {
                element: function($event){
                    var $elem = angular.element($event.delegateTarget);
            
                    if($elem.hasClass('expanded')){
                        $elem.removeClass('expanded');
                    }else{
                        $elem.addClass('expanded');
                    }
                },
                all: function($event){
                    var $button = angular.element($event.delegateTarget);
                    var $elems = document.querySelectorAll('#sendingContainer .data.expandable');
                    var expanded = $button.hasClass('expanded');
                    
                    // Switch button state
                    if(expanded){
                        $button.removeClass('expanded');
                        $button.text('Show All');
                    }else{
                        $button.addClass('expanded');
                        $button.text('Hide All');
                    }
                    
                    for(var i = 0; i < $elems.length; i++){
                        var $elem = $elems[i];
                        if(expanded){
                            $elem.classList.remove('expanded');
                        }else{
                            $elem.classList.add('expanded');
                        }
                    }
                }
            }
        };
        
        /* Transfer Log Functions Start
           TODO: Move into own factory
        */
        // Round Float is duplicated
        var roundFloat = function(amount){
            if(typeof amount === 'undefined'){
                return 0;
            }
            
            var amount = parseFloat(amount);
            return Math.round(amount * 100) / 100;
        };
        
        var transferLog = {
            loading: Object.create(load),
            logId: '',
            log: [],
            get: {
                log: function(){
                    return transferLog.log;
                }
            },
            download: {
                XML: function(){
                    console.info('Attempting to download Transfer as XML');
                    DEX.download.bulkXML();
                },
                CSV: function(){
                    console.info('Attempting to download Transfer Log as CSV', transferLog.log);
                    DEX.download.csv(transferLog.log);
                }
            },
            update: {
                statistics: {
                    clients: {
                        total: function(amount){
                            transferLog.log.statistics.clients.total = amount;
                        },
                        successfull: function(amount){
                            transferLog.log.statistics.clients.successfull = amount;
                        },
                        failed: function(amount){
                            transferLog.log.statistics.clients.failed = amount;
                        },
                        data: {
                            genderCount: function(gender){
                                console.log('GENDER', gender);
                                /*switch(gender){
                                    case 'FEMALE':
                                        transferLog.log.statistics.clients.data.genderCount.female += 1;
                                        return;
                                    case 'MALE':
                                        transferLog.log.statistics.clients.data.genderCount.male += 1;
                                        return;
                                    case 'UNKNOWN':
                                        transferLog.log.statistics.clients.data.genderCount.unknown += 1;
                                        return;
                                    case 'INTERSEX':
                                        transferLog.log.statistics.clients.data.genderCount.intersex += 1;
                                        return;
                                    case 'NOTSTATED':
                                        transferLog.log.statistics.clients.data.genderCount.notstated += 1;
                                        return;
                                }*/
                                if(!Validate.data.isSet(gender)){
                                    if(!Validate.data.isSet(transferLog.log.statistics.clients.data.genderCount.unknown)){
                                        transferLog.log.statistics.clients.data.genderCount.unknown = 1;
                                    }else{
                                        transferLog.log.statistics.clients.data.genderCount.unknown += 1;
                                    }
                                }else if(typeof transferLog.log.statistics.clients.data.genderCount[gender.toLowerCase()] === 'undefined'){
                                    transferLog.log.statistics.clients.data.genderCount[gender.toLowerCase()] = 1;
                                }else{
                                    transferLog.log.statistics.clients.data.genderCount[gender.toLowerCase()] += 1;
                                }
                            },
                            averageAge: function(birthdate){
                                console.log('BIRTHDATE', birthdate);
                                var age = moment().diff(birthdate, 'years');
                                console.log('AGE', age);
                                transferLog.log.statistics.clients.data.age.count += 1;
                                transferLog.log.statistics.clients.data.age.total += age;
                                
                                var averageAge = transferLog.log.statistics.clients.data.age.total / transferLog.log.statistics.clients.data.age.count;
                                transferLog.log.statistics.clients.data.age.average = Math.round(averageAge);
                            },
                            atsiCount: function(atsiCode){
                                console.log('ATSI', atsiCode);
                                if(typeof transferLog.log.statistics.clients.data.atsiCount[atsiCode] === 'undefined'){
                                    transferLog.log.statistics.clients.data.atsiCount[atsiCode] = 1;
                                }else{
                                    transferLog.log.statistics.clients.data.atsiCount[atsiCode] += 1;
                                }
                            }
                        }
                    },
                    sessions: {
                        total: function(amount){
                            transferLog.log.statistics.sessions.total = amount;
                        },
                        successfull: function(amount){
                            transferLog.log.statistics.sessions.successfull = amount;
                        },
                        failed: function(amount){
                            transferLog.log.statistics.sessions.failed = amount;
                        },
                        data: {
                            maintenanceHours: function(amount){
                                console.log('MAINHOURS', amount);
                                amount = parseFloat(amount) + transferLog.log.statistics.sessions.data.maintenanceHours;
                                amount = roundFloat(amount);
                                if(typeof amount === 'number' && !isNaN(amount)){
                                    transferLog.log.statistics.sessions.data.maintenanceHours = amount;
                                }
                            },
                            modificationCost: {
                                total: function(amount){
                                    console.log('MODCOST', amount);
                                    amount = parseFloat(amount) + transferLog.log.statistics.sessions.data.modificationCost.total;
                                    amount = roundFloat(amount);
                                    if(typeof amount === 'number' && !isNaN(amount)){
                                        transferLog.log.statistics.sessions.data.modificationCost.count += 1;
                                        transferLog.log.statistics.sessions.data.modificationCost.total = amount;
                                        this.average(amount);
                                    }
                                },
                                average: function(amount){
                                    console.log('MODCOSTAVERAGE', amount);
                                    amount = amount / transferLog.log.statistics.sessions.data.modificationCost.count;
                                    amount = roundFloat(amount);
                                    if(typeof amount === 'number' && !isNaN(amount)){
                                        transferLog.log.statistics.sessions.data.modificationCost.average = amount;
                                    }
                                }
                            },
                            feesCharged: {
                                total: function(amount){
                                    console.log('FEES', amount);
                                    amount = parseFloat(amount) + transferLog.log.statistics.sessions.data.feesCharged.total;
                                    amount = roundFloat(amount);
                                    if(typeof amount === 'number' && !isNaN(amount)){
                                        transferLog.log.statistics.sessions.data.feesCharged.count += 1;
                                        transferLog.log.statistics.sessions.data.feesCharged.total = amount;
                                        this.average(amount);
                                    }
                                },
                                average: function(amount){
                                    console.log('FEESAVERAGE', amount);
                                    amount = amount / transferLog.log.statistics.sessions.data.feesCharged.count;
                                    amount = roundFloat(amount);
                                    if(typeof amount === 'number' && !isNaN(amount)){
                                        transferLog.log.statistics.sessions.data.feesCharged.average = amount;
                                    }
                                }
                            },
                            quantity: function(amount){
                                console.log('QUANTITY', amount);
                                amount = parseFloat(amount) + transferLog.log.statistics.sessions.data.quantity;
                                amount = roundFloat(amount);
                                if(typeof amount === 'number' && !isNaN(amount)){
                                    transferLog.log.statistics.sessions.data.quantity = amount;
                                }
                            }
                        }
                    }
                },
                status: function(id, status){
                    var result = transferLog.log.transferred.filter(function( obj ) {
                        if(id === obj.id){
                            obj.status = status;
                            return true;
                        }
                    });
                }
            },
            add: {
                client: function(client){
                    var clientId = client.ClientID;
                    var clientUId = client.ClientUID;
                    var clientFullName = client.LastName +', '+ client.FirstName;

                    transferLog.log.transferred.push({
                        'id': clientUId,
                        'origId': clientId,
                        'name': clientFullName,
                        'status': '',
                        'output': []
                    });
                },
                clientOutput: function(id, status, title, message, extended){
                    var result = transferLog.log.transferred.filter(function( obj ) {
                        if(id === obj.id){
                            var ext = [];
                            if(Validate.data.isSet(extended)){
                                ext = extended;
                            }

                            obj.output.push({
                                'status': status,
                                'title': title,
                                'message': message,
                                'extended': ext
                            });
                            return true;
                        }
                    });
                }
            },
            save: {
                process: function(type, serviceId, outletId){
                    var xml = DEX.get.bulkXML();
                    console.info('Processing save', transferLog.logId);
                    
                    if(!Validate.data.isSet(outletId)){
                        outletId = null;
                    }
                    
                    CiviExchange.create.transferLog({
                        id: transferLog.logId,
                        type: type,
                        serviceId: serviceId,
                        outletId: outletId,
                        data: transferLog.log,
                        xml: xml
                    }).then(function(data){
                        console.info('Log transferred successfully', transferLog.logId, data);

                        // If LogId is not set - set it for adding additional data (Validation/Transfer)
                        if(transferLog.logId === ''){
                            transferLog.logId = data.id;
                        }else{
                            transferLog.logId = '';
                        }
                    });
                },
                validation: function(){
                    console.info('Save Transfer Validation log');
                    this.process(0, 1, $scope.outletType.nativeId);
                },
                transfer: function(){
                    console.info('Save Transfer log');
                    this.process(1, 1, $scope.outletType.nativeId);
                }
            },
            init: function(){
                console.info('Transfer log initialising');
                var that = this;
                
                this.log = {
                    statistics: {
                        clients: {
                            successfull: 0,
                            failed: 0,
                            total: 0,
                            data: {
                                genderCount: {},
                                age: {
                                    count: 0,
                                    total: 0,
                                    average: 0
                                },
                                atsiCount: {},
                            }
                        },
                        sessions: {
                            successfull: 0,
                            failed: 0,
                            total: 0,
                            data: {
                                maintenanceHours: 0,
                                modificationCost: {
                                    count: 0,
                                    total: 0.00
                                },
                                feesCharged: {
                                    count: 0,
                                    total: 0.00
                                },
                                quantity: 0
                            }
                        }
                    },
                    'transferred': []
                };
                
                $scope.transferLog = transferLog.get.log();
                
                $scope.downloadBulkXML = function(){
                    that.download.XML();
                };

                $scope.downloadCSV = function(){
                    that.download.CSV();
                };
            },
			refresh: function(){
				this.logId = '';
			}
        };
        
        /* Transfer Log Functions End */
        
        
        
        var transferStatistics = (function(){
        
            var clientGenderPie;
            var clientATSIPie;

            return {
                client: {
                    update: function(client){
                        transferLog.update.statistics.clients.data.genderCount(client.GenderCode);
                        transferLog.update.statistics.clients.data.averageAge(client.BirthDate);
                        transferLog.update.statistics.clients.data.atsiCount(client.AboriginalOrTorresStraitIslanderOriginCode);
                        
                        // TODO: Get language name using code for statistics
                        //transferLog.update.statistics.clients.data.languageCount(client.LanguageSpokenAtHomeCode);
                        
                        this.gender.generatePie();
                        this.atsi.generatePie();
                    },
                    gender: {
                        generatePie: function(){
                            var data = getClientGenderData();
                            console.log('clientGenderPie', data);

                            /*if(Validate.data.isSet(clientGenderPie)){
                                clientGenderPie.update(data);
                            }else{*/
                                clientGenderPie = Graph.pieChart('clientGenderPie', data);
                            /*}*/
                        }
                    },
                    atsi: {
                        generatePie: function(){
                            var data = getClientATSIData();
                            console.log('clientATSIPie', data);

                            /*if(Validate.data.isSet(clientATSIPie)){
                                clientATSIPie.update(data);
                            }else{*/
                                clientATSIPie = Graph.pieChart('clientATSIPie', data);
                            /*}*/
                        }
                    }
                },
                session: {
                    update: function (session) {
                        if(Validate.data.isSet(session.Quantity))
                            transferLog.update.statistics.sessions.data.quantity(session.Quantity);
                        
                        if(Validate.data.isSet(session.Time))
                            transferLog.update.statistics.sessions.data.maintenanceHours(session.Time);

                        if(Validate.data.isSet(session.TotalCost))
                            transferLog.update.statistics.sessions.data.modificationCost.total(session.TotalCost);

                        if(Validate.data.isSet(session.FeesCharged))
                            transferLog.update.statistics.sessions.data.feesCharged.total(session.FeesCharged);
                    }
                }
            };
            
            function getClientGenderData(){
                var data = [];
                var genderCount = transferLog.log.statistics.clients.data.genderCount;
                
                for(gender in genderCount) {
                    data.push({
                        title: gender,
                        amount: genderCount[gender]
                    });
                }
                
                return data;
            }
            
            function getClientATSIData(){
                var data = [];
                var atsiCount = transferLog.log.statistics.clients.data.atsiCount;
                
                for(atsi in atsiCount) {
                    data.push({
                        title: atsi,
                        amount: atsiCount[atsi]
                    });
                }
                
                return data;
            }
        })();
        
        /* Transfer to DEX Start */
        
        var DEXTransfer = (function(){
            
            var loader = Object.create(load);
            
            var status = 'uninitalised';
            
            var values = {
                processRate: 20,
                clients: {
                    successfull: 0,
                    failed: 0,
                    total: 0,
                    completed: 0
                },
                sessions: {
                    successfull: 0,
                    failed: 0,
                    total: 0,
                    completed: 0
                }
            };
            
            var success = {
                client: function(type, client, successData){
                    var listTitle = 'Client';
                    var clientId = 'Not Found';
                    var clientMessage = '';

                    // set id's
                    if(Validate.data.isSet(client.ClientUID)){
                        clientId = client.ClientUID;
                    }
                    
                    var clientMessage = 'Client with ID '+ clientId;
                    var successMessage = type +' '+ clientMessage;
                    
                    success.process(clientId, listTitle, successMessage, successData);
                },
                session: function(type, session, successData){
                    var listTitle = 'Session';
                    if(Validate.data.isSet(successData.caseData)){
                        listTitle = 'Case';
                    }
                    
                    var clientId = 'Not Found';
                    var sessionId = 'Not Found';
                    var caseId = 'Not Found';
                    var sessionMessage = '';
                    var caseMessage = '';
                    
                    if(Validate.data.isSet(session.ClientUId)){
                        clientId = session.ClientUId;
                    }

                    if(Validate.data.isSet(session.SessionUId)){
                        sessionId = session.SessionUId;
                        sessionMessage = 'Session with ID '+ sessionId;
                    }

                    if(Validate.data.isSet(session.CaseUId)){
                        caseId = session.CaseUId;
                        caseMessage = 'Case with ID '+ caseId;
                    }
                    
                    var successMessage = type +' '+ caseMessage +' and '+ sessionMessage;
                    
                    success.process(clientId, listTitle, successMessage, successData);
                },
                process: function(clientId, listTitle, successMessage, successData){
                    if(Validate.data.isSet(clientId)){
                        if(successData.valid){
                            transferLog.update.status(clientId, 'Success');
                            transferLog.add.clientOutput(clientId, 'Success', listTitle, successMessage);
                            if(listTitle === 'Client'){
                                values.clients.successfull = values.clients.successfull + 1;
                                update.progress.clients();
                            }else{
                                values.sessions.successfull = values.sessions.successfull + 1;
                                update.progress.sessions();
                            }
                        }else{
                            successMessage = 'Issues found in '+ successMessage +' ('+ successData.data.length +'):';
                            failure.process(clientId, listTitle, successMessage, successData);
                        }
                    }
                }
            };
            
            var failure = {
                client: function(client, errorData){
                    var listTitle = 'Client';
                    var clientId = 'Not Found';

                    // set id's
                    if(Validate.data.isSet(client.ClientUID)){
                        clientId = client.ClientUID;
                    }
                    
                    var errorMessage = 'Issues found in Client with ID '+ clientId +' ('+ errorData.data.length +'):';
                    
                    failure.process(clientId, listTitle, errorMessage, errorData);
                },
                session: function(session, errorData){
                    var listTitle = 'Session';
                    var clientId = 'Not Found';
                    var sessionId = 'Not Found';
                    var caseId = 'Not Found';
                    
                    if(Validate.data.isSet(session.ClientUId)){
                        clientId = session.ClientUId;
                    }

                    if(Validate.data.isSet(session.SessionUId)){
                        sessionId = session.SessionUId;
                    }

                    if(Validate.data.isSet(session.CaseUId)){
                        caseId = session.CaseUId;
                    }
                    
                    var errorMessage = 'Issues found in Case with ID '+ caseId +' and Session with ID '+ sessionId +' ('+ errorData.data.length +'):';
                    
                    failure.process(clientId, listTitle, errorMessage, errorData);
                },
                process: function(clientId, listTitle, errorMessage, errorData){
                    var retError = [];
                    var nonFatal = true;
                    
                    // build extended erros object
                    for(var i = 0; i < errorData.data.length; i++){
                        console.log('ERROR DATA', errorData);
                        var message = errorData.data[i].message;
                        if(message.messageTitle !== 2001){
                            nonFatal = false;
                        }else{
                            message.messageLevel = 'information';
                        }

                        retError.push({
                            'success': 'Fail',
                            'level': message.messageLevel,
                            'message': message.messageTitle +': '+ message.messageDescription
                        });
                    }
                    
                    if(nonFatal){
                        transferLog.update.status(clientId, 'Success');
                        transferLog.add.clientOutput(clientId, 'Success', listTitle, errorMessage, retError);
                        if(listTitle === 'Client'){
                            values.clients.successfull = values.clients.successfull + 1;
                            update.progress.clients();
                        }else{
                            values.sessions.successfull = values.sessions.successfull + 1;
                            update.progress.sessions();
                        }
                    }else{
                        transferLog.update.status(clientId, 'Fail');
                        transferLog.add.clientOutput(clientId, 'Fail', listTitle, errorMessage, retError);
                        if(listTitle === 'Client'){
                            values.clients.failed = values.clients.failed + 1;
                            update.progress.clients();
                        }else{
                            values.sessions.failed = values.sessions.failed + 1;
                            update.progress.sessions();
                        }
                    }
                }
            };
            
            var validate = {
                clients: {
                    all: function(){
                        Notify.create.push('Department of Social Services', 'Clients are being validated');
                        update.title('Validating Clients');
                        console.info('Validating Clients');
                        update.progress.clients();
                        for(var i = 0; i < values.processRate; i++){
                            if(i >= $scope.filteredClients.length){
                                break;
                            }
                            
                            validate.clients.client($scope.filteredClients, i, true);
                        }
                    },
                    next: function(clients, index){
                        var newIndex = index + values.processRate;
                        values.clients.completed++;

                        console.info('Next Client', "Base Index: " + index, "Adjusted Index: " + newIndex, "Total: " + values.clients.total + "(" + clients.length + ")", "Completed: " + values.clients.completed);
                        
                        if(values.clients.completed >= values.clients.total){
                            console.info('Completed Client Validation');
                            console.info('Initialising Session Validation');
                            validate.sessions.all();
                            return;
                        }else if(newIndex >= values.clients.total){
                            return;
                        }
                        
                        validate.clients.client(clients, newIndex, true);
                    },
                    client: function(clients, index, firstRun){
                        if(status == 'cancelled'){
                            return;
                        }

                        if (index >= clients.length) {
                            console.error('Issue Validating Client', 'Index', index, 'exceeds array length', clients.length);
                            return;
                        }

                        var client = clients[index];
                        var loaderId = 'ValidateClient' + index;

                        console.info('Validating Client', index, 'of', clients.length, '\nClient Id:', client.ClientID, '\nName:', client.LastName, ',', client.FirstName, '\nFull Details:', client);

                        if(!Validate.data.isSet(firstRun) || firstRun){
                            $('.inner-content').append(loader.begin(loaderId, 'none', '', '', 2));
                            console.log("ADD CLIENT", client);
                            transferLog.add.client(client);
                        }

                        DEX.validate.client(client).then(function(data){
                            console.info('Post Client Validation '+client.ClientID+': '+client.LastName+', '+client.FirstName, data);

                            loader.remove(false, loaderId);
                            success.client('Validated', client, data);
                            
                            transferStatistics.client.update(data.client);

                            validate.clients.next(clients, index);

                        }, function(errorData){
                            if(loader.retry('', '', loaderId)){
                                console.error('Issue Validating Client ('+client.ClientID+'): '+client.LastName+', '+client.FirstName, '\nClient Data', client, '\nError Data', errorData);
                                console.info('Re-attempting to Validate Client');

                                validate.clients.client(clients, index, false);
                            }else{
                                console.error('Complete Failure Validating Client ('+client.ClientID+'): '+client.LastName+', '+client.FirstName, '\nClient Data', client, '\nError Data', errorData);

                                loader.remove(false, loaderId);
                                failure.client(client, errorData);

                                validate.clients.next(clients, index);
                            }
                        });
                    }
                },
                sessions: {
                    all: function(){
                        Notify.create.push('Department of Social Services', 'Sessions are being validated');
                        update.title('Validating Sessions');
                        console.info('Validating Sessions');
                        update.progress.sessions();
                        for(var i = 0; i < values.processRate; i++){
                            if(i >= $scope.filteredSessions.length){
                                break;
                            }
                            validate.sessions.session($scope.filteredSessions, i);
                        }
                    },
                    next: function(sessions, index){
                        var newIndex = index + values.processRate;
                        values.sessions.completed++;

                        console.info('Next Session', "Base Index: " + index, "Adjusted Index: " + newIndex, "Total: " + values.sessions.total + "(" + sessions.length + ")", "Completed: " + values.sessions.completed);
                        
                        if(values.sessions.completed >= values.sessions.total){
                            console.info('Completed Session Validation');
                            validate.complete();
                            return;
                        }else if(newIndex >= values.sessions.total){
                            return;
                        }
                        
                        validate.sessions.session(sessions, newIndex);
                    },
                    session: function(sessions, index){
                        if(status == 'cancelled'){
                            return;
                        }

                        if (index >= sessions.length) {
                            console.error('Issue Validating Session', 'Index', index, 'exceeds array length', sessions.length);
                            return;
                        }

                        var session = sessions[index];
                        var loaderId = 'ValidateSession'+index;
                        $('.inner-content').append(loader.begin(loaderId, 'none', '', '', 2));
                        session.OutletActivityId = $scope.outletType.code;

                        console.info('Validating Session', index, 'of', sessions.length, '\nClient Id:', session.ClientId, '\nSession Id:', session.SessionId, '\nFull Details:', session);

                        DEX.validate.session(session).then(function(data){
                            console.info('Post Session Validation', data);

                            loader.remove(false, loaderId);
                            success.session('Validated', session, data);
                            
                            transferStatistics.session.update(data.session);

                            validate.sessions.next(sessions, index);

                        }, function(errorData){
                            if(loader.retry('', '', loaderId)){
                                console.error('Issue Validating Session', '\nSession Data', session, '\nError Data', errorData);
                                console.info('Re-attempting to Validate Session');

                                validate.sessions.session(sessions, index);
                            }else{
                                console.error('Complete Failure Validating Session', session, '\nError Data', errorData);

                                loader.remove(false, loaderId);
                                failure.session(session, errorData);

                                validate.sessions.next(sessions, index);
                            }
                        });
                    }
                },
                complete: function(){
                    $scope.showCancel = false;
                    DEX.register.transfer({'type': 0, 'serviceId': 1, 'completed': 1});
                    transferLog.save.validation();
                    $scope.setTransferState('post-validation');
                    $scope.isValidated = true;
                    update.title('Validation Complete');
                    Notify.create.push('Department of Social Services', 'Validation has completed');
                }
            };
            
            var transfer = {
                clients: {
                    all: function(){
                        Notify.create.push('Department of Social Services', 'Clients are being transferred');
                        update.title('Transferring Clients');
                        console.info('Transferring Clients');
                        update.progress.clients();
                        for(var i = 0; i < values.processRate; i++){
                            if(i >= $scope.filteredClients.length){
                                break;
                            }
                            
                            transfer.clients.client($scope.filteredClients, i, true);
                        }
                    },
                    next: function(clients, index){
                        var newIndex = index + values.processRate;
                        values.clients.completed++;

                        console.info('Next Client', "Base Index: " + index, "Adjusted Index: " + newIndex, "Total: " + values.clients.total + "(" + clients.length + ")", "Completed: " + values.clients.completed);
                        
                        if(values.clients.completed >= values.clients.total){
                            console.info('Completed Client Transfer');
                            console.info('Initialising Session Transfer');
                            transfer.sessions.all();
                            return;
                        }else if(newIndex >= values.clients.total){
                            return;
                        }
                        
                        transfer.clients.client(clients, newIndex, true);
                    },
                    client: function(clients, index, firstRun){
                        if(status == 'cancelled'){
                            return;
                        }

                        if (index >= clients.length) {
                            console.error('Issue Transferring Client', 'Index', index, 'exceeds array length', clients.length);
                            return;
                        }
                        
                        var client = clients[index];
                        var loaderId = 'TransferClient'+index;

                        console.info('Transferring Client', index, 'of', clients.length, '\nClient Id:', client.ClientID, '\nName:', client.LastName, ',', client.FirstName, '\nFull Details:', client);
                        
                        if(!Validate.data.isSet(firstRun) || firstRun){
                            $('.inner-content').append(loader.begin(loaderId, 'none', '', '', 2));
                            transferLog.add.client(client);
                        }

                        DEX.send.client(client).then(function(data){
                            console.info('Post Client Transfer '+client.ClientID+': '+client.LastName+', '+client.FirstName, data);

                            loader.remove(false, loaderId);
                            success.client('Transferred', client, data);
                            
                            transferStatistics.client.update(data.client);

                            transfer.clients.next(clients, index);

                        }, function(errorData){
                            if(loader.retry('', '', loaderId)){
                                console.error('Issue Transferring Client ('+client.ClientID+'): '+client.LastName+', '+client.FirstName, '\nClient Data', client, '\nError Data', errorData);
                                console.info('Re-attempting to Transfer Client');

                                transfer.clients.client(clients, index, false);
                            }else{
                                console.error('Complete Failure Transferring Client ('+client.ClientID+'): '+client.LastName+', '+client.FirstName, '\nClient Data', client, '\nError Data', errorData);

                                loader.remove(false, loaderId);
                                failure.client(client, errorData);

                                transfer.clients.next(clients, index);
                            }
                        });
                    }
                },
                sessions: {
                    all: function(){
                        Notify.create.push('Department of Social Services', 'Sessions are being transferred');
                        update.title('Transferring Sessions');
                        console.info('Transferring Sessions');
                        update.progress.sessions();
                        for(var i = 0; i < values.processRate; i++){
                            if(i >= $scope.filteredSessions.length){
                                break;
                            }
                            transfer.sessions.session($scope.filteredSessions, i);
                        }
                    },
                    next: function(sessions, index){
                        var newIndex = index + values.processRate;
                        values.sessions.completed++;

                        console.info('Next Session', "Base Index: " + index, "Adjusted Index: " + newIndex, "Total: " + values.sessions.total + "(" + sessions.length + ")", "Completed: " + values.sessions.completed);
                        
                        if(values.sessions.completed >= values.sessions.total){
                            console.info('Completed Session Transfer');
                            transfer.complete();
                            return;
                        }else if(newIndex >= values.sessions.total){
                            return;
                        }

                        transfer.sessions.session(sessions, newIndex);
                    },
                    session: function(sessions, index){
                        if(status == 'cancelled'){
                            return;
                        }

                        if (index >= sessions.length) {
                            console.error('Issue Transferring Session', 'Index', index, 'exceeds array length', sessions.length);
                            return;
                        }

                        var session = sessions[index];
                        var loaderId = 'TransferSession'+index;
                        $('.inner-content').append(loader.begin(loaderId, 'none', '', '', 2));
                        session.OutletActivityId = $scope.outletType.code;

                        console.info('Transferring Session', index, 'of', sessions.length, '\nClient Id:', session.ClientId, '\nSession Id:', session.SessionId, '\nFull Details:', session);

                        DEX.send.session(session).then(function(data){
                            console.info('Post Session Transfer', data);

                            loader.remove(false, loaderId);
                            success.session('Transferred', session, data);
                            
                            transferStatistics.session.update(data.session);

                            transfer.sessions.next(sessions, index);

                        }, function(errorData){
                            if(loader.retry('', '', loaderId)){
                                console.error('Issue Transferring Session', '\nSession Data', session, '\nError Data', errorData);
                                console.info('Re-attempting to Transfer Session');

                                transfer.sessions.session(sessions, index);
                            }else{
                                console.error('Complete Failure Transferring Session', session, '\nError Data', errorData);

                                loader.remove(false, loaderId);
                                failure.session(session, errorData);

                                transfer.sessions.next(sessions, index);
                            }
                        });
                    }
                },
                complete: function(){
                    $scope.showCancel = false;
                    DEX.register.transfer({'type': 1, 'serviceId': 1, 'completed': 1});
                    transferLog.save.transfer();
                    $scope.setTransferState('post-transfer');
                    update.title('Transfer Complete');
                    Notify.create.push('Department of Social Services', 'Transfer has completed');
                }
            };
            
            var update = {
                title: function(title){
                    $('#transferTypeTitle').text(title);
                },
                init: function(){
                    update.cancel(true);
                    transferLog.init();
                    status = 'running';
                    $scope.transferStarted = true;
                    $scope.showCancel = true;
                    
                    values.clients.successfull = 0;
                    values.clients.failed = 0;
                    values.clients.total = $scope.filteredClients.length;
                    values.clients.completed = 0;
                    
                    values.sessions.successfull = 0;
                    values.sessions.failed = 0;
                    values.sessions.total = $scope.filteredSessions.length;
                    values.sessions.completed = 0;
                    
                    // Set totals in transfer log
                    transferLog.update.statistics.clients.total(values.clients.total);
                    transferLog.update.statistics.sessions.total(values.sessions.total);
                    
                    $scope.transferLogDisplay.current.remainder.total = 0;
                    $scope.transferLogDisplay.current.remainder.percentage = 0;
                    $scope.transferLogDisplay.clients.successfull.percentage = 0;
                    $scope.transferLogDisplay.clients.failed.percentage = 0;
                    $scope.transferLogDisplay.sessions.successfull.percentage = 0;
                    $scope.transferLogDisplay.sessions.failed.percentage = 0;
                },
                progress: {
                    clients: function(){
                        var successfull = values.clients.successfull;
                        var failed = values.clients.failed;
                        var total = values.clients.total;
                        
                        // Set successfull total in transfer log
                        transferLog.update.statistics.clients.successfull(successfull);
                        transferLog.update.statistics.clients.failed(failed);
                        
                        update.progress.bar('clients', successfull, failed, total);
                    },
                    sessions: function(){
                        var successfull = values.sessions.successfull;
                        var failed = values.sessions.failed;
                        var total = values.sessions.total;
                        
                        // Set successfull total in transfer log
                        transferLog.update.statistics.sessions.successfull(successfull);
                        transferLog.update.statistics.sessions.failed(failed);
                        
                        update.progress.bar('sessions', successfull, failed, total);
                    },
                    bar: function(type, successfull, failed, total){
                        console.info('Update Bar', type, successfull, failed, total);
                        // Update bar
                        var percentage = Math.round(((successfull + failed) / total) * 100);
                        var remainder = total - successfull - failed;
                        var percentageFormat = percentage+'%';
                        
                        $('.full .progressIndicator').width(percentageFormat);
                        
                        // Update total
                        
                        percentage = 100 - percentage;
                        $scope.transferLogDisplay.current.remainder.total = remainder;
                        $scope.transferLogDisplay.current.remainder.percentage = percentage;
                        
                        // Update successfull
                        percentage = Math.round((successfull / total) * 100);
                        $scope.transferLogDisplay[type].successfull.percentage = percentage;
                        
                        // Update failed
                        percentage = Math.round((failed / total) * 100);
                        $scope.transferLogDisplay[type].failed.percentage = percentage;
                    }
                },
                cancel: function(skipSave){
                    console.info('Canceling Requests');
                    status = 'cancelled';
                    $rootScope.CancelRequests();
                    $scope.showCancel = false;

                    if(($scope.transferState === 'validation') &&
					   !skipSave && $scope.transferStarted){
                        if(!$scope.isValidated){
                            transferLog.save.validation();
                        }else{
                            transferLog.save.transfer();
                        }
                    }
					
                    $scope.setTransferState('intro');
                }
            };
            
            return {
                setupListeners: function(){
                    setupListeners();
                }
            };
            
            function setupListeners(){
                $scope.isValidated = false;
                $scope.cancelled = false;
                $scope.showCancel = false;
                
                $scope.DEXTransfer = {};
                
                $scope.DEXTransfer.validate = function(){
                    console.info('Initialising Validation');
					transferLog.refresh();
                    update.init();
                    DEX.register.transfer({'type': 0, 'serviceId': 1, 'completed': 0});
                    $scope.setTransferState('validation');
                    validate.clients.all();
                };
                
                $scope.DEXTransfer.transfer = function(){
                    console.info('Initialising Transfer');
                    update.init();
                    DEX.register.transfer({'type': 1, 'serviceId': 1, 'completed': 0});
                    $scope.setTransferState('transfer');
                    transfer.clients.all();
                };
                
                $scope.DEXTransfer.delete = function(){
                    console.info('Initialising Deleteion');
                };

                $scope.openTransferIntroPage = function(){
                    $scope.showPage('clientTransferIntro');
                };

                $scope.openTransferPage = function(){
                    $scope.showPage('clientTransfer');
                };

                $scope.displayClient = function(clients, index) {
                    var type = 'deleteClients';
                    transferLog.add.client(type, clients, index);
                };
                
                $scope.transferState = 'not-running';
                $scope.setTransferState = function(name){
                    $scope.transferState = name;
                };
                
                $scope.transferLogDisplay = {
                    current: {
                        remainder: {
                            total: 0,
                            percentage: 0
                        }
                    },
                    clients: {
                        successfull: {
                            percentage: 0
                        },
                        failed: {
                            percentage: 0
                        }
                    },
                    sessions: {
                        successfull: {
                            percentage: 0
                        },
                        failed: {
                            percentage: 0
                        }
                    }
                };

                $scope.cancelRequests = function(){
                    update.cancel();
                };
            }
            
        })();
        
        DEXTransfer.setupListeners();
        
        var cb = function(start, end) {
            $scope.cancelRequests();
            $('#daterange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
            
            $scope.dates.start = start.format('YYYY-M-D');
            // TODO
            // Adding one day is necessary due to how the API on CiviCRM has been setup for date comparison.
            // I need to fix this when I get a chance.
            $scope.dates.end = end.add(1, 'day').format('YYYY-M-D');
            end.subtract(1, 'day').format('YYYY-M-D');
            console.log('START', $scope.dates.start, '\nEND', $scope.dates.end);
            
            $scope.isValidated = false;
            $scope.transferStarted = false;
            $scope.showPage('clientData');
            $scope.setSelectedButton('Data');
            $scope.getCiviCRMClients(true);
            $scope.getCiviCRMSessions(true);
            $scope.getLocalReferenceData(true);
        };
        
        cb(moment().startOf('month'), moment().endOf('month'));
        //cb(moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month'));
        //cb(moment('2016-01-01', 'YYYY-MM-DD'), moment('2016-03-10', 'YYYY-MM-DD'));

        $('#daterange').daterangepicker({
            "autoApply": true,
            locale: {
                format: 'YYYY-MM-DD'
            },
            ranges: {
                'This Month': [moment().startOf('month'), moment().endOf('month')],

                'Last Month': [moment().subtract(1, 'month').startOf('month'), 
                               moment().subtract(1, 'month').endOf('month')],

                'First Quater': [moment().set('month', 0).startOf('month'), 
                                 moment().set('month', 2).endOf('month')],

                'Second Quater': [moment().set('month', 3).startOf('month'), 
                                  moment().set('month', 5).endOf('month')],

                'Third Quater': [moment().set('month', 6).startOf('month'), 
                                 moment().set('month', 8).endOf('month')],

                'Fourth Quater': [moment().set('month', 9).startOf('month'), 
                                  moment().set('month', 11).endOf('month')],

                'First Half': [moment().set('month', 0).startOf('month'), 
                               moment().set('month', 5).endOf('month')],

                'Second Half': [moment().set('month', 6).startOf('month'), 
                                moment().set('month', 11).endOf('month')],
            }
        }, cb);
        $('#daterange').datepicker('hide');
    };
    
    if($rootScope.isCiviAccessSetup()){
        if(Validate.data.isSet(moment)){
            initClients();
        }else{
            console.error('Issue retrieveing current date');
            $scope.issuesFound = true;
        }
    }
});

// app/js/controllers/HomeCtrl.js
Service.factory("CiviExchangeUsers", function CiviExchangeUsers($q, $http, $timeout, $localStorage, apiService, FORMATTER){
    var users = null;
    
    var sendToApi = {
        get: function(deferred, data){
            apiService.get(data, true).then(function(data){
                if(data.response.success){
                    deferred.resolve(data.data);
                }else if(!data.response.success){
                    deferred.reject(data.response.message);
                }
            }, function(errorCode){
                var output = FORMATTER.process(errorCode);
                console.error('CiviExchange User: Issue with '+data.action, output);
                deferred.reject(output);
            });
        },
        post: function(deferred, data){
            apiService.post(data, true).then(function(data){
                if(data.response.success){
                    deferred.resolve(data.data);
                }else if(!data.response.success){
                    deferred.reject(data.response.message);
                }
            }, function(errorCode){
                var output = FORMATTER.process(errorCode);
                console.error('CiviExchange User: Issue with '+data.action, output);
                deferred.reject(output);
            });
        }
    };

    var formatUsersAll = function(users){
        var html = "";
        html += '<div class="data-list">';
        html += '<div class="data-title"><h2>Users</h2><div class="add btn" ng-click="openModal(\'addUser\')">+ Add</div></div>';
        html += '<div class="data">';
        html += '<div class="data-name1">Username</div>';
        html += '<div class="data-name1">Name</div>';
        html += "</div>";

        for(var newIndex in users){
            var value = users[newIndex];
            
            html += '<div class="data view-btn" ng-click="openModal(\'viewUser\', '+ value.id +')">';
            html += '<div class="data-name1">'+ value.username +'</div>';
            html += '<div class="data-name1">'+ value.last_name +', '+ value.first_name +'</div>';
            html += "</div>";
        }

        html += "</div>";

        return html;
    };
    
    var formatUsers = function(users){
        var html = "";
        html += '<div class="data-list">';
        html += '<div class="data-title"><h2>Users</h2><div class="add btn" ng-click="openModal(\'addUser\')">+ Add</div></div>';
        html += '<div class="data">';
        html += '<div class="data-name1">Username</div>';
        html += '<div class="data-name1">Name</div>';
        html += "</div>";

        for(var newIndex in users){
            var value = users[newIndex];
            
            html += '<div class="data view-btn" ng-click="openModal(\'viewUser\', '+ value.id +')">';
            html += '<div class="data-name1">'+ value.username +'</div>';
            html += '<div class="data-name1">'+ value.last_name +', '+ value.first_name +'</div>';
            html += "</div>";
        }

        html += "</div>";

        return html;
    };

    return {
        getUsers: function(update){
            var deferred = $q.defer();
            var data = {};
            data.action = 'getAllUsers';
            
            var errorMessage = 'CiviExchange Users: Issue with getAllUsers';
            
            if(users === null || update === true){
                apiService.get(data, true).then(function(data){
                    if(data.response.success){
                        users = data.data;
                        deferred.resolve(users);
                    }else if(!data.response.success){
                        console.error(errorMessage, data);
                        deferred.reject(data.response.message);
                    }
                }, function(errorCode){
                    var output = FORMATTER.process(errorCode);
                    console.error(errorMessage, output);
                    deferred.reject(output);
                });
            }else{
                deferred.resolve(users);
            }

            return deferred.promise;
        },
        
        getUser: function(id){
            var result = users.filter(function( obj ) {
                return obj.id == id;
            })[0];
            return result;
        },

        createNewUser: function(data){
            var deferred = $q.defer();
            
            data.action = 'createNewUser';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        updateUser: function(data){
            var deferred = $q.defer();
            
            data.action = 'updateUser';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        disableUser: function(data){
            var deferred = $q.defer();
            
            data.action = 'disableUser';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        deleteUser: function(data){
            var deferred = $q.defer();
            
            data.action = 'deleteUser';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        getOrganisationUsers: function(id){
            var deferred = $q.defer();
            
            var result = users.filter(function( obj ) {
                return obj.organisation_id == id;
            });
            
            deferred.resolve(result);
            return deferred.promise;
        },

        displayUsersFormatted: function(data){
            var html = "";
            html = formatUsersAll(users);
            return html;
        },

        displayOrganisationUsersFormatted: function(id){
            var html = "";
            var organisationUsers = this.getOrganisationUsers(id);
            html = formatUsers(organisationUsers);
            return html;
        },
        
        clear: function(){
            users = null;
        }
    };
});

// app/js/controllers/HomeCtrl.js
Service.factory("CiviCRMSessions", function CiviCRMSessions($q, $http, $timeout, $rootScope, Validate, $localStorage, $rootScope, apiService, FORMATTER){
    var sessions = null;
    
    var sessionsMainDisplay = function(data, first, last){
        if(data.length <= 0){
            return '';
        }
        
        var html = '<div class="client-list">';
        
        if(typeof first !== 'number'){
            first = 0;
        }
        
        if(typeof last !== 'number' || last <= first){
            last = data.length - 1;
        }
        
        var currentSession = '';
        var currentCase = '';
        
        for(var i = first; i <= last; i++){
            var value = data[i];
            var newCase = value.ClientId;
            
            if(newCase !== currentCase){
                currentCase = newCase;
                html += '<div class="client-title"><h2>Client: '+ currentCase +'</h2></div>';
            }
            
            html += '<div class="client view-btn" ng-click="openClient('+ value.ClientId +')">';
            
            html += '<p>Session: '+ value.SessionId +'</p>';
            html += '<p>'+ value.ClientId +'</p>';
            html += '<p>'+ value.ServiceTypeId +'</p>';
            html += '<p>'+ value.SessionDate +'</p>';
            
            html += "</div>";
        }
        
        html += "</div>";

        return html;
    };

    return {        
        getSessions: function(data, update){
            var deferred = $q.defer();
            var that = this;
            var error = 'CiviCRM Sessions: Issue retrieving sessions';

            if(!Validate.data.isSet(data.startDate) || 
               !Validate.data.isSet(data.endDate)){
                var error = 'CiviCRM Sessions: Data for getSessions was not specified correctly';
                console.error(error);
                deferred.reject(error);
            }
            
            if(sessions === null || update === true){
                var actions = {action: 'getCiviCRMSessions', start_date: data.startDate, end_date: data.endDate};

                apiService.post(actions, true).then(function(data){
                    try{
                        var reconstructedData = JSON.parse(data.data);

                        if(!data.response.success || !Validate.data.isSet(reconstructedData.results)){
                            throw error;
                        }
                       	that.processSessionData(reconstructedData.results).then(function(newData){
                            sessions = newData;
                            deferred.resolve(sessions);
                        }, function(error){
                            console.error('CiviCRM Sessions: Issue processing session data', error);
                            deferred.reject(error);
                        });
                    }catch(errorData){
                        console.error(error, errorData);
                        deferred.reject(error);
                    }
                }, function(errorData){
                    console.error(error, errorData);
                    var output = FORMATTER.process(errorData);
                    deferred.reject(output);
                });
            }else{
                deferred.resolve(sessions);
            }
            return deferred.promise;
        },
        
        getSession: function(id){
            var result = sessions.filter(function( obj ) {
                return obj.SessionID == id;
            })[0];
            return result;
        },
        
        processSessionData: function(data){
            var deferred = $q.defer();
            var that = this;
            
            that.cleanData(data).then(function(newData){
                sessions = newData;
                deferred.resolve(sessions);
            }, function(errorData){
                console.error('CiviCRM Sessions: Issue processing session data', errorData);
                deferred.reject(errorData);
            });
            
            return deferred.promise;
        },
        
        cleanData: function(data){
            var deferred = $q.defer();
            
            if(!Validate.data.isSet(data) || !data.length){
                deferred.reject('No data found.');
                return deferred.promise;
            }
			
			var orgUid = '';
			if(Validate.data.isSet($rootScope.authorisedUser.organisation.uid)){
				console.info('Org UID found');
				orgUid = '-'+ $rootScope.authorisedUser.organisation.uid;
			}
            
            for(var i = 0; i < data.length; i++){
				
				// Prepend Org UID
				data[i].ClientUId = data[i].ClientId + orgUid;
				data[i].CaseUId = data[i].CaseId + orgUid;
				data[i].SessionUId = data[i].SessionId + orgUid;
                
                // Split Extra Items
                if(Validate.data.isSet(data[i].ExtraItem)){
                    var extraItems = data[i].ExtraItem.split(',');
                    data[i].ExtraItem = [];
                    for(var j = 0; j < extraItems.length; j++){
                        if(extraItems[j] !== ''){
                            data[i].ExtraItem.push({ExtraItemCode: extraItems[j]});
                        }
                    }
                }
                
                // Split Referral Purpose Codes
                if(Validate.data.isSet(data[i].ReferralPurposeCodes)){
                    var ReferralPurposeCodes = data[i].ReferralPurposeCodes.split(',');
                    data[i].ReferralPurposeCodes = [];

                    for(var j = 0; j < ReferralPurposeCodes.length; j++){
                        if(ReferralPurposeCodes[j] !== ''){
                            data[i].ReferralPurposeCodes.push({PurposeCode: ReferralPurposeCodes[j]});
                        }
                    }
                }
                
                // Split Primary Reason for Assistance
                if(Validate.data.isSet(data[i].PrimaryReasonForAssistance)){
                    var PrimaryReasonForAssistance = data[i].PrimaryReasonForAssistance.split(',');
                    data[i].PrimaryReasonForAssistance = [];

                    for(var j = 0; j < PrimaryReasonForAssistance.length; j++){
                        if(PrimaryReasonForAssistance[j] !== ''){
                            data[i].PrimaryReasonForAssistance.push({
                                AssistanceNeededCode: PrimaryReasonForAssistance[j],
                                IsPrimary: true
                            });
                        }
                    }
                }
                
                // Split Secondary Reason for Assistance
                if(Validate.data.isSet(data[i].SecondaryReasonForAssistance)){
                    var SecondaryReasonForAssistance = data[i].SecondaryReasonForAssistance.split(',');
                    data[i].SecondaryReasonForAssistance = [];

                    for(var j = 0; j < SecondaryReasonForAssistance.length; j++){
                        if(SecondaryReasonForAssistance[j] !== ''){
                            data[i].SecondaryReasonForAssistance.push({
                                AssistanceNeededCode: SecondaryReasonForAssistance[j],
                                IsPrimary: false
                            });
                        }
                    }
                }
        
                var buildScores = {
                    scores: [],
                    clear: function(){
                        this.scores = []
                    },
                    add: function(name, score){
                        if(Validate.data.isSet(name) && Validate.data.isSet(score)){
                            this.scores.push({
                                ScoreCode: "" + name + score
                            });
                        }
                    }
                };
        
                var getScores = function(data, scoreTypeCode){
                    buildScores.clear();

                    if(Validate.data.isSet(scoreTypeCode)){
                        if(scoreTypeCode == 'CIRCUMSTANCES'){
                            buildScores.add("AGE", data.SessionScoreAge);
                            buildScores.add("COMMUNITY", data.SessionScoreCommunity);
                            buildScores.add("EMPLOYMENT", data.SessionScoreEmployment);
                            buildScores.add("FAMILY", data.SessionScoreFamily);
                            buildScores.add("HOUSING", data.SessionScoreHousing);
                            buildScores.add("MATERIAL", data.SessionScoreMaterial);
                            buildScores.add("MENTAL", data.SessionScoreMental);
                            buildScores.add("MONEY", data.SessionScoreMoney);
                            buildScores.add("PERSONAL", data.SessionScorePersonal);
                            buildScores.add("PHYSICAL", data.SessionScorePhysical);
                            buildScores.add("TRAINING", data.SessionScoreTraining);
                        }else if(scoreTypeCode == 'GOALS'){
                            buildScores.add("BEHAVIOURS", data.SessionScoreBehaviours);
                            buildScores.add("CONFIDENCE", data.SessionScoreConfidence);
                            buildScores.add("ENGAGEMENT", data.SessionScoreEngagement);
                            buildScores.add("IMPACT", data.SessionScoreImpact);
                            buildScores.add("KNOWLEDGE", data.SessionScoreKnowledge);
                            buildScores.add("SKILLS", data.SessionScoreSkills);
                        }else if(scoreTypeCode == 'GROUP' || scoreTypeCode == 'COMMUNITY'){
                            buildScores.add("COHESION", data.CaseScoreCohesion);
                            buildScores.add("GROUPNETWORKS", data.CaseScoreGroupNetworks);
                            buildScores.add("GROUPSKILLS", data.CaseScoreGroupSkills);
                            buildScores.add("ORGSKILLS", data.CaseScoreOrgSkills);
                        }else if(scoreTypeCode == 'SATISFACTION'){
                            buildScores.add("SERVICELISTENED", data.SessionScoreServiceListened);
                            buildScores.add("SERVICERECEIVED", data.SessionScoreServiceReceived);
                            buildScores.add("SITUATIONIMPROVED", data.SessionScoreSituationImproved);
                        }
                    }

                    return buildScores.scores;
                };
                
                // Split Session Score Type Codes
                if(Validate.data.isSet(data[i].SessionScoreTypeCode)){
                    var SessionScoreTypeCodes = data[i].SessionScoreTypeCode.split(',');
                    data[i].SessionAssessments = [];

                    for(var j = 0; j < SessionScoreTypeCodes.length; j++){
                        if(SessionScoreTypeCodes[j] !== ''){
                            var newAssessment = {
                                Assessment: {
                                    AssessmentPhaseCode: data[i].SessionAssessmentPhaseCode, 
                                    ScoreTypeCode: SessionScoreTypeCodes[j], 
                                    AssessedByCode: data[i].SessionAssessedByCode
                                }
                            };

                            var scores = getScores(data[i], SessionScoreTypeCodes[j]);

                            if(scores.length > 0){
                                newAssessment.Assessment.Scores = scores;
                            }

                            data[i].SessionAssessments.push(newAssessment);
                        }
                    }
                }
                
                // Split Case Score Type Codes
                if(Validate.data.isSet(data[i].CaseScoreTypeCode)){
                    var CaseScoreTypeCodes = data[i].CaseScoreTypeCode.split(',');
                    data[i].CaseAssessments = [];

                    for(var j = 0; j < CaseScoreTypeCodes.length; j++){
                        if(CaseScoreTypeCodes[j] !== ''){
                            var newAssessment = {
                                Assessment: {
                                    AssessmentPhaseCode: data[i].CaseAssessmentPhaseCode, 
                                    ScoreTypeCode: CaseScoreTypeCodes[j], 
                                    AssessedByCode: data[i].CaseAssessedByCode
                                }
                            };

                            var scores = getScores(data[i], CaseScoreTypeCodes[j]);

                            if(scores.length > 0){
                                newAssessment.Assessment.Scores = scores;
                            }
                            
                            data[i].CaseAssessments.push(newAssessment);
                        }
                    }
                }

                data[i].SessionTotalNumberOfUnidentifiedClients = 0;

                // Add unidentified clients
                if(Validate.data.isSet(data[i].SessionTotalNumberOfUnidentifiedClients1)){
                    data[i].SessionTotalNumberOfUnidentifiedClients += parseInt(data[i].SessionTotalNumberOfUnidentifiedClients1);
                }

                // Add unidentified clients
                if(Validate.data.isSet(data[i].SessionTotalNumberOfUnidentifiedClients2)){
                    data[i].SessionTotalNumberOfUnidentifiedClients += parseInt(data[i].SessionTotalNumberOfUnidentifiedClients2);
                }

                // Add unidentified clients
                if(Validate.data.isSet(data[i].SessionTotalNumberOfUnidentifiedClients3)){
                    data[i].SessionTotalNumberOfUnidentifiedClients += parseInt(data[i].SessionTotalNumberOfUnidentifiedClients3);
                }

                // Add unidentified clients
                if(Validate.data.isSet(data[i].SessionTotalNumberOfUnidentifiedClients4)){
                    data[i].SessionTotalNumberOfUnidentifiedClients += parseInt(data[i].SessionTotalNumberOfUnidentifiedClients4);
                }
            }
            
            deferred.resolve(data);
            return deferred.promise;
        },
        
        getUserSessions: function(data){
            var deferred = $q.defer();
            var that = this;
            
            if(!Validate.data.isSet(data.id)){
                return 'ID required';
            }
            
            if(!Validate.data.isSet(data.data)){
                data.data = sessions;
            }
            
            var result = data.data.filter(function( obj ) {
                return obj.ClientId == data.id;
            });
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        convertCountries: function(data){
            var deferred = $q.defer();
            var error = 'CiviCRM Sessions: Convert countries failed';
            
            var actions = {action: 'getCountries'};

            apiService.post(actions, true).then(function(countries){
                if(countries.response.countries.response.success){
                    var countriesData = countries.data.countries;
                    
                    for(var i = 0; i < data.length; i++){
                        for(var j = 0; j < countriesData.length; j++){
                            if(data[i].CountryOfBirthCode == countriesData[j].civicode){
                                data[i].CountryOfBirthCode = countriesData[j].dsscode;
                                break;
                            }
                        }
                    }
                    
                    var statesData = countries.data.states;
                    
                    for(var i = 0; i < data.length; i++){
                        for(var j = 0; j < statesData.length; j++){
                            if(data[i].State == statesData[j].civicode){
                                data[i].State = statesData[j].dsscode;
                                break;
                            }
                        }
                    }
                    
                    deferred.resolve(data);
                }else{
                    console.error(error, countries);
                    deferred.reject(countries.response.code +': '+ countries.response.message);
                }
                deferred.resolve(data);
            }, function(errorData){
                console.error(error, errorData);
                var output = FORMATTER.process(errorData);
                deferred.reject(output);
            });

            return deferred.promise;
        },
        
        findSessions: function(query){
            var deferred = $q.defer();
            
            var result = sessions.filter(function( obj ) {
                var firstName = obj.FirstName.toLowerCase();
                var lastName = obj.LastName.toLowerCase();
                if(firstName.indexOf(query.toLowerCase()) >= 0 || lastName.indexOf(query.toLowerCase()) >= 0){
                    return obj;
                }
            });
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        byOutlet: function(data){
            var deferred = $q.defer();
            
            if(!Validate.data.isSet(sessions)){
                deferred.reject('No sessions found');
                return deferred.promise;
            }
            
            if(!Validate.data.isSet(data.startDate) && !Validate.data.isSet(data.endDate)){
                deferred.reject('No date found');
                return deferred.promise;
            }
            
            if(!Validate.data.isSet(data.rules) || data.rules.length <= 0){
                deferred.reject('No outlet rules found');
                return deferred.promise;
            }
            
            var startDate = new Date(data.startDate);
            var endDate = new Date(data.endDate);
            var temp = JSON.parse(JSON.stringify(sessions));
            
            console.log('Pre Outlet filter', temp);
            console.log('Outlet Filter Rules', data.rules);
            var result = temp.filter(function(obj){
                var date = null;
                
                console.log('Processing session', obj.SessionId, obj);
                
                if(Validate.data.isSet(obj.haccInternalDate)){
                    date = new Date(obj.haccInternalDate);
                }else if(Validate.data.isSet(obj.SessionDate)){
                    date = new Date(obj.SessionDate);
                }
                
                if(date !== null && date >= startDate && date <= endDate){
                    for (var i = 0; i < data.rules.length; i++){
                        var serviceTypeIdRulesMet = false;
                        var caseIdRulesMet = false;
                        var requiredRulesMet = false;
                        var removalRulesMet = false;
                        var rule = data.rules[i].rule;

                        if(Validate.data.isSet(rule.convert) && rule.convert.length > 0){
                            for (var j = 0; j < rule.convert.length; j++){
                                console.log('Validate Service Type', obj.ServiceTypeId, rule.convert[j].orig, obj.ServiceTypeId == rule.convert[j].orig);
                                if(obj.ServiceTypeId == rule.convert[j].orig){
                                    obj.ServiceTypeId = rule.convert[j].new;
                                    serviceTypeIdRulesMet = true;
                                    break;
                                }
                            }
                        }else{
                            serviceTypeIdRulesMet = true;
                        }

                        if(Validate.data.isSet(rule.caseId) && rule.caseId.length > 0){
                            if(Validate.data.isSet(obj.CaseTypeId)){
                                for(var j = 0; j < rule.caseId.length; j++){
                                    if(obj.CaseTypeId == rule.caseId[j]){
                                        caseIdRulesMet = true;
                                        break;
                                    }
                                }
                            }
                        }else{
                            caseIdRulesMet = true;
                        }

                        if(Validate.data.isSet(rule.required) && rule.required.length > 0){
                            var fieldCount = 0;
                            for(var j = 0; j < rule.required.length; j++){
                                var requiredFieldName = rule.required[j];
                                if(Validate.data.isSet(obj[requiredFieldName]) && obj[requiredFieldName] > 0){
                                    fieldCount++;
                                }
                            }

                            if(fieldCount == rule.required.length){
                                requiredRulesMet = true;
                            }
                        }else{
                            requiredRulesMet = true;
                        }

                        if(Validate.data.isSet(rule.removal) && rule.removal.length > 0){
                            for(var j = 0; j < rule.removal.length; j++){
                                var removalFieldName = rule.removal[j];
                                if(Validate.data.isSet(obj[removalFieldName]) && obj[removalFieldName] > 0){
                                    obj[removalFieldName] = '';
                                    removalRulesMet = true;
                                }
                            }
                        }else{
                            removalRulesMet = true;
                        }
                        
                        if(serviceTypeIdRulesMet && caseIdRulesMet && requiredRulesMet && removalRulesMet){
                            return obj;
                        }
                    }
                }else{
                    if (date === null) {
                        console.error('Date not found', obj);
                    }
                }
                
                console.log('Session does not meet rules', obj.SessionId);
            });
            console.log('Post Outlet filter', result);
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        homeMaintenance: function(data){
            var deferred = $q.defer();
            var startDate = new Date(data.startDate);
            var endDate = new Date(data.endDate);
            var temp = JSON.parse(JSON.stringify(sessions));
            
            var result = temp.filter(function(obj){
                var date = null;
                
                if(Validate.data.isSet(obj.haccInternalDate)){
                    date = new Date(obj.haccInternalDate);
                }else if(Validate.data.isSet(obj.SessionDate)){
                    date = new Date(obj.SessionDate);
                }
                
                if(date !== null && date >= startDate && date <= endDate){
                    /* Care West 43 - To replace and remove rootScope access */
                    if($rootScope.authorisedUser.organisation_id == '43' && 
                       obj.SessionTime !== null && obj.SessionTime !== 0){
                        
                        if(obj.ServiceTypeId !== '89' && obj.ServiceTypeId !== '90'){
                            obj.Quantity = '';
                        }
                        
                        if(obj.ServiceTypeId == '87'){
                            obj.ServiceTypeId = 63;
                            return obj;
                        }else if(obj.ServiceTypeId == '88'){
                            obj.ServiceTypeId = 63;
                            return obj;
                        }else if(obj.ServiceTypeId == '94'){
                            obj.ServiceTypeId = 63;
                            return obj;
                        }else if(obj.ServiceTypeId == '95'){
                            obj.ServiceTypeId = 63;
                            return obj;
                        }else if(obj.ServiceTypeId == '89'){
                            obj.ServiceTypeId = 73;
                            return obj;
                        }else if(obj.ServiceTypeId == '90'){
                            obj.ServiceTypeId = 73;
                            return obj;
                        }
                    }else{
                        if(obj.ServiceTypeId == '101' && 
                           obj.SessionTime !== null && obj.SessionTime !== 0){
                            obj.ServiceTypeId = 54;
                            return obj;
                        }

                        if(obj.ServiceTypeId == '13' && 
                           obj.MaintenanceHours !== null && obj.MaintenanceHours > 0){
                            obj.ServiceTypeId = 54;
                            return obj;
                        }
                    }
                }
            });
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        homeModifications: function(data){
            var deferred = $q.defer();
            var startDate = new Date(data.startDate);
            var endDate = new Date(data.endDate);
            var temp = JSON.parse(JSON.stringify(sessions));
            
            if(!Validate.data.isSet(temp)){
                deferred.resolve(null);
                return deferred.promise;
            }
            
            var result = temp.filter(function(obj){
                var date = null;
                
                if(Validate.data.isSet(obj.haccInternalDate)){
                    date = new Date(obj.haccInternalDate);
                }else if(Validate.data.isSet(obj.SessionDate)){
                    date = new Date(obj.SessionDate);
                }
                
                if(date !== null && date >= startDate && date <= endDate){
                    /* Care West */
                    if($rootScope.authorisedUser.organisation_id == '43' && 
                       obj.SessionTime !== null && obj.SessionTime !== 0 && 
                       obj.CaseTypeId === '1'){
                        
                        if(obj.ServiceTypeId !== '89' && obj.ServiceTypeId !== '90'){
                            obj.Quantity = '';
                        }
                        
                        if(obj.ServiceTypeId == '91'){
                            obj.ServiceTypeId = 77;
                            return obj;
                        }else if(obj.ServiceTypeId == '106'){
                            obj.ServiceTypeId = 78;
                            return obj;
                        }else if(obj.ServiceTypeId == '107'){
                            obj.ServiceTypeId = 79;
                            return obj;
                        }else if(obj.ServiceTypeId == '80'){
                            obj.ServiceTypeId = 80;
                            return obj;
                        }else if(obj.ServiceTypeId == '118'){
                            obj.ServiceTypeId = 82;
                            return obj;
                        }else if(obj.ServiceTypeId == '102'){
                            obj.ServiceTypeId = 83;
                            return obj;
                        }else if(obj.ServiceTypeId == '93'){
                            obj.ServiceTypeId = 84;
                            return obj;
                        }else if(obj.ServiceTypeId == '103'){
                            obj.ServiceTypeId = 85;
                            return obj;
                        }else if(obj.ServiceTypeId == '104'){
                            obj.ServiceTypeId = 86;
                            return obj;
                        }else if(obj.ServiceTypeId == '105'){
                            obj.ServiceTypeId = 87;
                            return obj;
                        }else if(obj.ServiceTypeId == '100'){
                            obj.ServiceTypeId = 81;
                            return obj;
                        }
                    }else{
                        if(obj.ServiceTypeId == '13' && 
                           obj.TotalCost !== null && obj.TotalCost > 0){
                            obj.ServiceTypeId = 55;
                            return obj;
                        }
                    }
                }
            });
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        alliedHealth: function(data){
            var deferred = $q.defer();
            var startDate = new Date(data.startDate);
            var endDate = new Date(data.endDate);
            var temp = JSON.parse(JSON.stringify(sessions));
            
            var result = temp.filter(function(obj){
                var date = null;
                
                if(Validate.data.isSet(obj.haccInternalDate)){
                    date = new Date(obj.haccInternalDate);
                }else if(Validate.data.isSet(obj.SessionDate)){
                    date = new Date(obj.SessionDate);
                }
                
                if(date !== null && date >= startDate && date <= endDate){
                    /* Care West */
                    if($rootScope.authorisedUser.organisation_id == '43' && 
                       obj.SessionTime !== null && obj.SessionTime !== 0 && 
                       obj.CaseTypeId === '2'){
                        
                        if(obj.ServiceTypeId !== '89' && obj.ServiceTypeId !== '90'){
                            obj.Quantity = '';
                        }
                        
                        if(obj.ServiceTypeId == '108'){
                            obj.ServiceTypeId = 26;
                            return obj;
                        }else if(obj.ServiceTypeId == '109'){
                            obj.ServiceTypeId = 26;
                            return obj;
                        }else if(obj.ServiceTypeId == '110'){
                            obj.ServiceTypeId = 1;
                            return obj;
                        }else if(obj.ServiceTypeId == '111'){
                            obj.ServiceTypeId = 1;
                            return obj;
                        }else if(obj.ServiceTypeId == '112'){
                            obj.ServiceTypeId = 2;
                            return obj;
                        }else if(obj.ServiceTypeId == '113'){
                            obj.ServiceTypeId = 2;
                            return obj;
                        }else if(obj.ServiceTypeId == '114'){
                            obj.ServiceTypeId = 5;
                            return obj;
                        }else if(obj.ServiceTypeId == '115'){
                            obj.ServiceTypeId = 5;
                            return obj;
                        }else if(obj.ServiceTypeId == '116'){
                            obj.ServiceTypeId = 8;
                            return obj;
                        }else if(obj.ServiceTypeId == '117'){
                            obj.ServiceTypeId = 8;
                            return obj;
                        }
                    }else{
                        if(obj.ServiceTypeId == '94' || obj.ServiceTypeId == '95' && 
                           obj.SessionTime !== null && obj.SessionTime > 0){
                            obj.ServiceTypeId = 33;
                            return obj;
                        }
                    }
                }
            });
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        displaySessionsMain: function(data){
            if(!Validate.data.isSet(data.data)){
                data.data = sessions;
            }
            return sessionsMainDisplay(data.data, data.first, data.last);
        },
        
        findUniqueSessionTypeIDs: function(data){
            var deferred = $q.defer();
            var foundSessionIDs = [];
            
            for(var i = 0; i < sessions.length; i++){
                var foundMatch = false;
                for(var j = 0; j < foundSessionIDs.length; j++){
                    if(sessions[i].ServiceTypeId === foundSessionIDs[j].id){
                        foundSessionIDs[j].count++;
                        foundMatch = true;
                    }
                }
                
                if(!foundMatch){
                    var newTypeID = {id: sessions[i].ServiceTypeId, count: 1};
                    foundSessionIDs.push(newTypeID);
                }
            }
            
            foundSessionIDs.sort(function(a,b) {return (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0);} );
            
            deferred.resolve(foundSessionIDs);
            return deferred.promise;
        },
        
        clear: function(){
            sessions = null;
        }

    };
});

var loadingIndicator = {
    
    get: function(id, type, title, desc){
        
        if(typeof id === 'undefined' || id === ''){
            id = 'loadingWrap';
        }
        
        var html = '<div id="'+ id +'" class="loadingWrap '+ id +'';
        if(type == 'full'){
            html += ' full"><div class="positioner';
        }else{
            html += ' '+type;
        }
        html += '">';

        html += '<div class="bubble"></div>';
        html += '<h4 class="title">';
        if(typeof title != 'undefined' && title !== ""){
            html += title;
        }
        html += '</h4>';

        if(type == 'full'){
            html += '</div>';
            html += '<p class="desc">';
            if(typeof desc != 'undefined' && desc !== ""){
                html += desc;
            }
            html += '</p>';
        }
        html += '</div>';

        return html;
    },

    update: function(id, title, desc){
        var $loadMain;
        var $loadWrap = $('#'+id+'.loadingWrap');
        var $loadWrapC = $('.'+id+'.loadingWrap');
        
        if($loadWrap.length){
            $loadMain = $loadWrap;
        }else if($loadWrapC.length){
            $loadMain = $loadWrapC;
        }
        
        if(typeof $loadMain !== 'undefined' && typeof title != 'undefined' && title !== ""){
            $loadMain.find('.title').text(title);
        }
        if(typeof $loadMain !== 'undefined' && typeof desc != 'undefined' && desc !== ""){
            $loadMain.find('.desc').text(desc);
        }
    },

    remove: function(id, fade){
        var $loadMain;
        var $loadWrap = $('#'+id+'.loadingWrap');
        var $loadWrapC = $('.'+id+'.loadingWrap');
        
        if($loadWrap.length){
            $loadMain = $loadWrap;
        }else if($loadWrapC.length){
            $loadMain = $loadWrapC;
        }
        
        if(typeof $loadMain !== 'undefined' && fade){
            $loadMain.addClass('hidden');
            setTimeout(function(){
                $loadMain.remove();
            }, 1000);
        }else if (typeof $loadMain !== 'undefined' && $loadMain !== ''){
            $loadMain.remove();
        }
    }
};
angular.module('appRoutes')

.run(function (RouteFilter, Authentication, $location){
    RouteFilter.register('public', ['/', '/loading'], function(){
        return ! Authentication.exists();
    }, 'authentication');
    
    RouteFilter.register('auth', 
    ['/authentication'], function(){
        if(Authentication.exists()){
            /*if(!Authentication.isServiceAccessSetup('DSS')){
                if(Authentication.isOrganisationAdmin()){
                    $location.path('/setup');
                }else{
                    $location.path('/setupRequired');
                }
                return true;
            }*/
            $location.path('/services');
            return true;
        }
        return false;
    }, '/');
    
    RouteFilter.register('setup', 
    ['/setup'], function(){
        /*if(Authentication.exists() && 
           !Authentication.isServiceAccessSetup('DSS')){
            
            if(Authentication.isAdmin() || 
               Authentication.isOrganisationAdmin()){
                return true;
            }
        }*/
        return false;
    }, '/');
    
    RouteFilter.register('setupRequired', 
    ['/setupRequired'], function(){
        /*if(Authentication.exists() && 
           !Authentication.isServiceAccessSetup('DSS') && 
           !Authentication.isOrganisationAdmin()){
            return true;
        }*/
        return false;
    }, '/');

    RouteFilter.register('services',
    ['/services'],
    function(){
        if(Authentication.exists()){
            return true;
        }
        return false;
    }, '/');

    RouteFilter.register('otherServices',
    ['/services/other'],
    function(){
        if(Authentication.exists()){
            if(Authentication.isAdmin() || 
               Authentication.isOrganisationAdmin()){
                return true;
            }
        }
        return false;
    }, '/');

    RouteFilter.register('dss',
    ['/services/dss',
    '/services/dss/account',
    '/services/dss/clients',
    /\/services\/dss\/clients\/[0-9]+/,
    '/services/dss/logs',
    /\/services\/dss\/logs\/[0-9]+/,
    '/services/dss/stats'],
    function(){
        if(Authentication.exists() && 
           Authentication.isCiviAccessSetup() && 
           Authentication.isServiceAccessSetup('DSS')){
            return true;
        }
        return false;
    }, '/');

    RouteFilter.register('admin',
    ['/admin',
    '/admin/accounts'],
    function(){
        if(Authentication.exists()){
            if(Authentication.isAdmin() || 
               Authentication.isOrganisationAdmin()){
                return true;
            }
        }
        return false;
    }, '/');

    RouteFilter.register('activityLogs',
    ['/admin/activityLogs'],
    function(){
        if(Authentication.exists()){
            if(Authentication.isAdmin()){
                return true;
            }
        }
        return false;
    }, '/');

    RouteFilter.register('adminServices',
    ['/admin/services',
    '/admin/services/dss'],
    function(){
        if(Authentication.exists()){
            if(Authentication.isAdmin()){
                return true;
            }
        }
        return false;
    }, '/');

    RouteFilter.register('organisation',
    [/\/admin\/accounts\/[0-9]+/,
    /\/admin\/accounts\/[0-9]+\/users/,
    /\/admin\/accounts\/[0-9]+\/services/],
    function(){
        if(Authentication.exists()){
            if(Authentication.isAdmin() || 
               Authentication.isOrganisationAdmin()){
                return true;
            }
        }
        return false;
    }, '/');
});

// app/js/controllers/HomeCtrl.js
LoginCtrl.controller('SetupController', function($rootScope, 
                                                $scope, 
                                                $route,
                                                $routeParams,
                                                $location, 
                                                $localStorage, 
                                                $sessionStorage, 
                                                $window, 
                                                Authentication, 
                                                Application, 
                                                RouteFilter,
                                                CiviExchange,
                                                InputValidation,
                                                DEX,
                                                LOG){
    $rootScope.pageType = 'CiviExchange > Organisation Setup';
    $scope.pageTitle = $rootScope.authorisedUser.organisation.name;
    $rootScope.selectedNav = '';
    $scope.headerVariant = '';
    $scope.sidebarVariant = '';
    
    $scope.goHome = function() {
        $location.path('/authentication');
        $scope.safeApply();
    };
    
    var $organisationForm = $('#organisationCompletion-form');
    
    var $username = $organisationForm.find('#dssUsername');
    var $password = $organisationForm.find('#dssPassword');
    var $password2 = $organisationForm.find('#dssPassword2');
    
    $username.on('input', function(){
        var username = $username.val();
        if(InputValidation.length(username, $username, 'Please enter a Username.')){
        }
    });
    
    $password.on('input', function(){
        var password = $password.val();
        var password2 = $password2.val();
        if(InputValidation.length(password, $password, 'Please enter a Password.')){
            if(InputValidation.length(password2, $password2)){
                InputValidation.equality(password, password2, $password, $password2, 'Passwords don\'t match.');
            }
        }
    });
    
    $password2.on('input', function(){
        var password = $password.val();
        var password2 = $password2.val();
        if(InputValidation.length(password2, $password2, 'Please re-type your Password.')){
            InputValidation.equality(password, password2, $password, $password2, 'Passwords don\'t match.');
        }
    });
    
    $scope.updateUser = function(data){
        CiviExchange.update.organisation(data).then(function(){
            $username.val('');
            $password.val('');
            $password2.val('');
            
            Authentication.requestUser().then(function(){
                $scope.goHome();
            }, function(reason){
                console.log('Request User failed: '+ reason);
            });
        }, function(reason){
            
        });
    };
    
    var loadDexUser = Object.create(load);
    $scope.getDexUser = function(data) {
        $('.form').append(loadDexUser.begin('loadDexUser', 'full', 'Checking Department of Social Services Account'));
        DEX.validate.user({dssUsername: data.username, dssPassword: data.password}).then(function(feedback){
            $scope.feedback = '<p class="success">'+ feedback +'</p>';
            $scope.updateUser(data);
        }, function(error){
            loadDexUser.remove();
            $scope.feedback = '<p class="error">'+ error +'</p>';
        });
    };

    $organisationForm.find("#submit-button").click(function(event){
        event.preventDefault();
        var cSending = 'form__input--sending';
        var cSent = 'form__input--sent';
        var that = $(this);
        var valid = true;
        
        var username = $username.val();
        var password = $password.val();
        var password2 = $password2.val();
        /*var civiURL = $civiURL.val();*/
        
        valid = InputValidation.length(username, $username, 'Please enter a Username.');
        //valid = InputValidation.dssUsernameExists(username, $username, 'Username already exists.');

        valid = InputValidation.length(password, $password, 'Please enter a Password.');
        valid = InputValidation.length(password2, $password2, 'Please re-type your Password.');
        valid = InputValidation.equality(password, password2, $password, $password2, 'Passwords don\'t match.');

        /*valid = InputValidation.length(civiURL, $civiURL, 'Please enter a CiviCRM URL.');
        valid = InputValidation.isURL(civiURL, $civiURL, 'Please enter a valid URL.');*/

        if(!valid){
            console.log("Values not valid.");
            return false;
        }else{
            that.addClass(cSending);
        }
        
        var data = {};
        data.id = $rootScope.organisation_id;
        data.username = username;
        data.password = password;
        /*data.civiURL = civiURL;*/

        $scope.getDexUser(data);
    });
});

ServicesParentCtrl.controller('ServicesController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            $compile,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            LoadLocalData,
                                                            InputValidation){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Services';
    $scope.page.header.title = 'Available Services';
    $scope.page.header.backLink = '';
    $scope.page.sidebar.variant = 'services';
    $scope.page.sidebar.selectedNav = 'Services';
});
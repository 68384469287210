// app/js/controllers/HomeCtrl.js
Service.factory("CiviExchangeOrganisations", function CiviExchangeOrganisations($q, $http, $timeout, $localStorage, apiService, FORMATTER){
    var organisations = null;
    
    var sendToApi = {
        get: function(deferred, data){
            apiService.get(data, true).then(function(data){
                if(data.response.success){
                    deferred.resolve(data.data);
                }else if(!data.response.success){
                    deferred.reject(data.response.message);
                }
            }, function(errorCode){
                var output = FORMATTER.process(errorCode);
                console.error('CiviExchange Organisation: Issue with '+data.action, output);
                deferred.reject(output);
            });
        },
        post: function(deferred, data){
            apiService.post(data, true).then(function(data){
                if(data.response.success){
                    deferred.resolve(data.data);
                }else if(!data.response.success){
                    deferred.reject(data.response.message);
                }
            }, function(errorCode){
                var output = FORMATTER.process(errorCode);
                console.error('CiviExchange Organisation: Issue with '+data.action, output);
                deferred.reject(output);
            });
        }
    };

    return {
        getActivityLogs: function(data){
            var deferred = $q.defer();
            
            data.action = 'getActivityLogs';
            sendToApi.get(deferred, data);

            return deferred.promise;
        },
        
        getTransferLogs: function(data){
            var deferred = $q.defer();
            
            data.action = 'getTransferLogs';
            sendToApi.get(deferred, data);

            return deferred.promise;
        },
        
        getTransferLog: function(data){
            var deferred = $q.defer();
            
            data.action = 'getTransferLog';
            sendToApi.get(deferred, data);

            return deferred.promise;
        },
        
        storeTransferLog: function(data){
            var deferred = $q.defer();
            
            data.action = 'storeTransferLog';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        getOrganisations: function(update){
            var deferred = $q.defer();
            var data = {};
            data.action = 'getAllOrganisations';
            
            var errorMessage = 'CiviExchange Organisation: Issue with getAllOrganisations';
            
            if(organisations === null || update === true){
                apiService.get(data, true).then(function(apiData){
                    if(apiData.response.success){
                        if(apiData.data.length){
                            organisations = apiData.data;
                        }else{
                            organisations = [];
                            organisations.push(apiData.data);
                        }
                        deferred.resolve(organisations);
                    }else if(!apiData.response.success){
                        console.error(errorMessage, data);
                        deferred.reject(data.response.message);
                    }
                }, function(errorCode){
                    var output = FORMATTER.process(errorCode);
                    console.error(errorMessage, output);
                    deferred.reject(output);
                });
            }else{
                deferred.resolve(organisations);
            }

            return deferred.promise;
        },
        
        getOrganisation: function(id){
            var result = organisations.filter(function( obj ) {
                return obj.id == id;
            })[0];
            return result;
        },
        
        createNewOrganisation: function(data){//name, email
            var deferred = $q.defer();
            
            data.action = 'createNewOrganisation';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        createOrganisationService: function(data){//name, email
            var deferred = $q.defer();
            
            data.action = 'addServiceLogin';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        updateOrganisation: function(data){
            var deferred = $q.defer();
            
            data.action = 'updateOrganisation';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        validateOrganisation: function(data){
            var deferred = $q.defer();
            
            for(var i = 0; i < organisations.length; i++){
                if(organisations[i].name === data.name){
                    deferred.resolve(true);
                }
            }
            
            deferred.resolve(false);
            return deferred.promise;
        },
        
        disableOrganisation: function(data){
            var deferred = $q.defer();
            
            data.action = 'disableOrganisation';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        deleteOrganisation: function(data){
            var deferred = $q.defer();
            
            data.action = 'deleteOrganisation';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        updateCiviCRM: function(data){
            var deferred = $q.defer();
            
            data.action = 'updateCiviCRM';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },

        updateCiviCRMSettings: function(data){
            var deferred = $q.defer();
            
            data.action = 'updateCiviCRMSettings';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        updateOrganisationService: function(data){
            var deferred = $q.defer();
            
            data.action = 'updateServiceLogin';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        deleteOrganisationService: function(data){
            var deferred = $q.defer();
            
            data.action = 'deleteServiceLogin';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        createOrganisationServiceOutlet: function(data){
            var deferred = $q.defer();
            
            data.action = 'addServiceOutlet';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        updateOrganisationServiceOutlet: function(data){
            var deferred = $q.defer();
            
            data.action = 'updateServiceOutlet';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        deleteOrganisationServiceOutlet: function(data){
            var deferred = $q.defer();
            
            data.action = 'deleteServiceOutlet';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        createOutletRule: function(data){
            var deferred = $q.defer();
            
            data.action = 'addOutletRule';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        deleteOutletRule: function(data){
            var deferred = $q.defer();
            
            data.action = 'deleteOutletRule';
            sendToApi.post(deferred, data);

            return deferred.promise;
        },
        
        clear: function(){
            organisations = null;
        }
    };
});

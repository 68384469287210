ServicesParentCtrl.controller('DSS-LogsController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            DEX,
                                                            LoadLocalData){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Service';
    $scope.page.header.title = 'Department of Social Services';
    $scope.page.header.backLink = '#!/services';
    $scope.page.sidebar.variant = 'serviceDSS';
    $scope.page.sidebar.selectedNav = 'Transfer Logs';
    
    $scope.getType = function(type){
        if(type === '0'){
            return 'Validation';
        }else if(type === '1'){
            return 'Transfer';
        }
    };
    
    var transferLogBackup;
    
    var dates = {
        start: '',
        end: ''
    };
    
    $scope.usernames = {
        selected: 'All',
        available: [],
        findAvailable: function(logs){
            for(var i = 0; i < logs.length; i++){
                var userAlreadyRegistered = false;
                for(var j = 0; j < this.available.length; j++){
                    if(this.available[j].username === logs[i].username){
                        userAlreadyRegistered = true;
                        break;
                    }
                }
                if(!userAlreadyRegistered){
                    this.available.push({username: logs[i].username});
                }
            }
            this.filterLog();
        },
        filterLog: function(){
            console.log($scope.usernames.selected);
            if($scope.usernames.selected === 'All'){
                $scope.transferLogs = transferLogBackup;
            }else{
                $scope.transferLogs = transferLogBackup.filter(function( obj ) {
                    if(obj.username === $scope.usernames.selected){
                        return obj;
                    }
                });
            }
        }
    };
    
    var loadingTransferLogs = Object.create(load);
    
    var updateLogs = function(logs){
        loadingTransferLogs.remove();
        
        for(var i = 0; i < logs.length; i++){
            for(var j = 0; j < $rootScope.authorisedUser.organisation.outlets['1'].length; j++){
                var tempOutlet = $rootScope.authorisedUser.organisation.outlets['1'][j];
                
                if(logs[i].outlet_id == tempOutlet.id){
                    logs[i].outletName = tempOutlet.name;
                }
            }
        }
        
        $scope.transferLogs = logs;
        transferLogBackup = logs;
        $scope.usernames.findAvailable(logs);
    };
    
    var loadTransferLogs = function(startDate, endDate){
        var error = 'Loading Transfer Logs';
        
        CiviExchange.get.transferLogs({serviceId: 1, withData: 0, startDate: startDate, endDate: endDate}).then(function(transferLogs){
            updateLogs(transferLogs);
            console.log('Logs', transferLogs);
        }, function(errorData){
            console.error(error, errorData);
            if(loadingTransferLogs.retry('', 'Error recieved ('+ errorData +').')){
                return loadTransferLogs(startDate, endDate);
            }else{
                var retError = '<p class="error">Could not retrieve logs. Please contact your administrator if this issue persists.<br>('+ errorData +')</p>';
                console.error('Complete Fail: '+error, errorData);
                updateLogs('');
            }
        });
    };
    
    var cb = function(start, end) {
        $('#daterange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
        dates.start = start.startOf('day').format('YYYY-MM-DD HH:mm:ss');
        dates.end = end.endOf('day').format('YYYY-MM-DD HH:mm:ss');
        console.log('START', dates.start, '\nEND', dates.end);
                                 
        $('.inner-content').append(loadingTransferLogs.begin('loadTransferLogs', 'full', 'Retrieving logs'));
        loadTransferLogs(dates.start, dates.end);
    };
    
    cb(moment().subtract(1, 'month'), moment());
    //cb(moment().startOf('month'), moment().endOf('month'));
    
    $('#daterange').daterangepicker({
        "autoApply": true,
        locale: {
            format: 'YYYY-MM-DD'
        },
        ranges: {
            'This Month': [moment().startOf('month'), moment().endOf('month')],

            'Last Month': [moment().subtract(1, 'month').startOf('month'), 
                           moment().subtract(1, 'month').endOf('month')],

            'First Quater': [moment().set('month', 0).startOf('month'), 
                             moment().set('month', 2).endOf('month')],

            'Second Quater': [moment().set('month', 3).startOf('month'), 
                              moment().set('month', 5).endOf('month')],

            'Third Quater': [moment().set('month', 6).startOf('month'), 
                             moment().set('month', 8).endOf('month')],

            'Fourth Quater': [moment().set('month', 9).startOf('month'), 
                              moment().set('month', 11).endOf('month')],

            'First Half': [moment().set('month', 0).startOf('month'), 
                           moment().set('month', 5).endOf('month')],

            'Second Half': [moment().set('month', 6).startOf('month'), 
                            moment().set('month', 11).endOf('month')],
        }
    }, cb);
    $('#daterange').datepicker('hide');
});

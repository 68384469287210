angular.module('appRoutes')
    .factory('Authentication', function Authentication($q, $http, $timeout, $localStorage, Validate, apiService, FORMATTER){
        var authenticatedUser = null;
        var userOrganisation = null;
        var recaptchaRequired = false;

        return {
            requestUser: function(){
                var deferred = $q.defer();
                var error = 'Request User: User Request failed';

                if($localStorage.jwt){
                    apiService.get({action: 'currentUser'}, true).then(function(data){
                        if(data.response.success){
                            $localStorage.jwt = data.jwt;
                            authenticatedUser = data.userDetails;
                            userOrganisation = data.organisationDetails;
                            deferred.resolve(authenticatedUser);
                        }else if(!data.response.success){
                            console.error(error, data);
                            deferred.reject(error);
                        }
                    }, function(errorData){
                        var output = FORMATTER.process(errorData);
                        console.error(error, output);
                        deferred.reject(error);
                    });
                }else{
                    var detailedError = 'Request User: JWT could not be found';
                    console.warn(detailedError);
                    deferred.reject(error);
                }

                return deferred.promise;
            },

            requestRecaptchaRequired: function(){
                var deferred = $q.defer();
                var error = 'Request Recaptcha Required: Issue Verifying Recaptcha Required';

                apiService.get({action: 'recaptchaRequired'}, false).then(function(data){
                    if(data.response.success){
                        recaptchaRequired = data.recaptchaRequired;
                        deferred.resolve(authenticatedUser);
                    }else if(!data.response.success){
                        console.error(error, data);
                        deferred.reject(error);
                    }
                }, function(errorData){
                    console.error(error, errorData);
                    deferred.reject(error);
                });

                return deferred.promise;
            },

            getRecaptchaRequired: function(){
                return recaptchaRequired;
            },

            getUser: function(){
                return authenticatedUser;
            },
            
            getOrganisation: function(){
                return userOrganisation;
            },

            exists: function(){
                return authenticatedUser !== null;
            },

            login: function(username, password, recaptcha){
                var deferred = $q.defer();
                var that = this;
                var error = 'Login: Issue with Login';

                apiService.post({action: 'login', username: username, password: password, recaptcha: recaptcha}, false).then(function(data){
                    if(data.response.success){
                        $localStorage.jwt = data.jwt;
                        authenticatedUser = data.userDetails;
                        userOrganisation = data.organisationDetails;
                        deferred.resolve(authenticatedUser);
                    }else if(!data.response.success){
                        console.error(error, data);
                        deferred.reject(error);
                    }
                }, function(errorData){
                    console.error(error, errorData);
                    deferred.reject(error);
                });

                return deferred.promise;
            },


            logout: function(){
                var deferred = $q.defer();
                var error = 'Logout: Logout failed';

                apiService.post({action: 'logout'}, false).then(function(data){
                    $localStorage.jwt = null;
                    authenticatedUser = null;
                    console.log('Logout', data);
                    deferred.resolve(authenticatedUser);
                }, function(errorData){
                    console.error(error, errorData);
                    deferred.reject(error);
                });

                return deferred.promise;
            },

            isUserActive: function(){
                var deferred = $q.defer();
                var error = 'User Active: User Active verification failed';
                
                if(Validate.data.isSet($localStorage.jwt)){
                    apiService.get({action: 'isUserActive'}, true).then(function(data){
                        deferred.resolve(data.response.success);
                    }, function(errorData){
                        console.error(error, errorData);
                        deferred.reject(error);
                    });
                }else{
                    var detailedError = 'User Active: JWT could not be found';
                    console.warn(detailedError);
                    deferred.reject(error);
                }

                return deferred.promise;
            },

            isOrganisationActive: function(){
                var deferred = $q.defer();
                var error = 'Organisation Active: Organisation Active verification failed';
                
                if(Validate.data.isSet($localStorage.jwt)){
                    apiService.get({action: 'isOrganisationActive'}, true).then(function(data){
                        deferred.resolve(data.response.success);
                    }, function(errorData){
                        console.error(error, errorData);
                        deferred.reject(error);
                    });
                }else{
                    var detailedError = 'Organisation Active: JWT could not be found';
                    console.warn(detailedError);
                    deferred.reject(error);
                }

                return deferred.promise;
            },

            isAdmin: function(){
                return this.exists() && authenticatedUser.auth == 1;
            },

            isOrganisationAdmin: function(){
                return this.exists() && authenticatedUser.auth == 2;
            },

            isUsersOrganisation: function(id){
                if(this.exists() && Validate.data.isSet(id)){
                    if(id === authenticatedUser.organisation_id){
                        return true;
                    }
                }
                console.info('Users Organisation: Not current users organisation');
                return false;
            },

            isServiceAccessSetup: function(name){
                if(this.exists() && Validate.data.isSet(userOrganisation.service_logins)){
                    // DSS
                    if(name == 'DSS' && 
                       Validate.data.isSet(userOrganisation.service_logins[1]) && 
                       Validate.data.isSet(userOrganisation.service_logins[1].active) && 
                       userOrganisation.service_logins[1].active === 1){
                        return true;
                    }else if(name == 'MDS' && 
                       Validate.data.isSet(userOrganisation.service_logins[2]) && 
                       Validate.data.isSet(userOrganisation.service_logins[2].active) && 
                       userOrganisation.service_logins[2].active === 1){
                        return true;
                    }
                }
                console.info('Service Access Setup: Service access not setup: ', name);
                return false;
            },

            isCiviAccessSetup: function(){
                if(this.exists()){
                    if(Validate.data.isSet(userOrganisation.civiURL) && 
                       Validate.data.isSet(userOrganisation.civiKey)){
                        return true;
                    }
                }
                console.info('CiviCRM Access Setup: CiviCRM access not setup');
                return false;
            }
        };
    });

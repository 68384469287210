// app/js/services/Service.js
angular.module('apiService', []).factory('apiService', function apiService($q, $http, $timeout, $localStorage, $rootScope, Validate){
    return{
        // call to get all nerds
        get: function(data, authHeader, canCancel){
            return this.call('GET', data, authHeader, canCancel);
        },

        post: function(data, authHeader, canCancel){
            return this.call('POST', data, authHeader, canCancel);
        },

        call: function(type, data, authHeader, canCancel){
            var deferred = $q.defer();
            
            var header = null;
            
            if(authHeader){
                header = function(request){
                    request.setRequestHeader('Authorization', 'Bearer ' + $localStorage.jwt);
                };
            }
            
            $.ajax({
                url: 'includes/api/api.php',
                beforeSend: header,
                type: type,
                dataType: 'html',
                cache: false,
                data: data,
                success: function(returnData){
                    try{
                        var newData = JSON.parse(returnData);
                        deferred.resolve(newData);
                    }catch(e){
                        console.error('API Request: Error found in Response', '\nError', e, '\nSent Data', data, '\nReturned Data', returnData);
                        
                        var error = {response: {}};
                        error.response.success = false;
                        error.response.code = 0;
                        error.response.message = e;
                        deferred.reject(error);
                    }
                },
                error: function(error, textStatus){
                    if(textStatus === 'timeout'){
                        var timeout = {response: {}};
                        timeout.response.success = false;
                        timeout.response.code = 408;
                        timeout.response.message = 'Timeout: Request took too long to complete.';
                        
                        console.error('API Request: Timeout', '\nError', error, '\nSent Data', data);
                        deferred.reject(timeout);
                    }
                    
                    console.error('API Request: Error', '\nError', error, '\nSent Data', data);
                    deferred.reject(error);
                },
                timeout: 360000
            });
            
            if(Validate.data.isSet(canCancel) && canCancel){
                if(!Validate.data.isSet($rootScope.requests)){
                    $rootScope.requests = [];
                }
                
                $rootScope.requests.push(function(response){
                    console.warn('API Request: Cancelled', '\nData', data, 'Response', response);
                    deferred.reject(response);
                });
            }

            return deferred.promise;
        }
    };
});

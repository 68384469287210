// app/js/controllers/HomeCtrl.js
Service.factory("FORMATTER", function FORMATTER($q, $http, Validate){

    var has = function(obj, key) {
        return key.split(".").every(function(x) {
            if(typeof obj !== "object" || obj === null || !(x in obj)){
                return false;
            }
            obj = obj[x];
            return true;
        });
    };
    
    return {
        process: function(data){
            var output = {};
            var element = {};
            
            if(!Validate.data.isSet(data)){
                element.messageLevel = 'error';
                element.messageTitle = 'Not Found';
                element.messageDescription = 'No data was found.';
                output = {valid: false, data: {message: element}};
                
                console.error('Data Formatter: Data is undefined');
                return output;
            }
            
            if(Validate.data.isSet(data.responseText)){
                try {
                    data = JSON.parse(data.responseText);
                }catch(e){
                    element.messageLevel = 'error';
                    element.messageTitle = 'Something went wrong';
                    element.messageDescription = 'Could not find description. Returned data was in an unknown format.';
                    output = {valid: false, data: {message: element}};
                    
                    console.error('Data Formatter: Could not parse response text as JSON (data.responseText)', data);
                    return output;
                }
            }
            
            if(!Validate.data.isSet(data.response)){
                output = {valid: false, data: data};
                console.error('Data Formatter: No response found in data (data.response)', data);
            }else if(data.response.success){
                output = {valid: true, data: data};
            }else{
                output = {valid: false};
                output.data = [];

                if(has(data, 'response.message.0.Error')){
                    
                    for(var index in data.response.message){
                        var errorObj = data.response.message[index].Error;
                        
                        for(var errorIndex in errorObj){
                            if(errorObj.hasOwnProperty(errorIndex)) {
                                var errorTypeObj = errorObj[errorIndex];
                                for(var errorTypeIndex in errorTypeObj){
                                    if(errorTypeObj.hasOwnProperty(errorTypeIndex)) {
                                        errorTypeObj = errorTypeObj[errorTypeIndex];
                                        element = {};
                                        element.messageLevel = 'error';
                                        element.messageTitle = errorTypeIndex;
                                        element.messageDescription = errorTypeObj;
                                        output.data.push({message: element});
                                    }
                                }
                            }
                        }
                    }
                }else if(has(data, 'response.message.Messages.Message')){
                    var messages = data.response.message.Messages.Message;
                    
                    if(typeof messages[0] !== 'object'){
                        messages = [];
                        messages[0] = data.response.message.Messages.Message;
                    }
                    
                    for(var i = 0; i < messages.length; i++){
                        var message = messages[i];
                        var messageLevel = message.MessageLevel;
                        var messageDescription = message.MessageDescription;
                        
                        element = {};
                        element.messageLevel = messageLevel.toLowerCase();
                        element.messageTitle = messageLevel;
                        element.messageDescription = messageDescription;
                        output.data.push({message: element});
                    }
                }else if(has(data, 'response.message')){
                    var messageCode = data.response.code;
                    var message = data.response.message;
                    
                    element.messageLevel = 'error';
                    element.messageTitle = messageCode;
                    element.messageDescription = message;
                    output.data.push({message: element});
                }else{
                    element.messageLevel = 'error';
                    element.messageTitle = '0';
                    element.messageDescription = 'Recieved an unknown error';
                    output.data.push({message: element});
                    console.error('Data Formatter: Could not find error in given format', data);
                }
            }
            
            return output;
        }
    };
});

// app/js/controllers/LoginCtrl.js
var LoginCtrl = angular.module('LoginCtrl', ['ngStorage', 'vcRecaptcha']);

LoginCtrl.controller('LoginController', function(
    $rootScope,
    $scope,
    $location,
    $localStorage,
    $sessionStorage,
    $window,
    Validate,
    Authentication,
    RouteFilter,
    vcRecaptchaService,
    RecaptchaVerification){
    
    $scope.recaptchaRequired = true;
    
    $scope.canAccess = function(route){
        return RouteFilter.canAccess(route);
    };

    $scope.recaptcha = function() {
        Authentication.requestRecaptchaRequired().then(function(){
            console.info('Checking whether to serve recaptcha');
            $scope.recaptchaRequired = Authentication.getRecaptchaRequired();
            console.assert(!$scope.recaptchaRequired, 'Recaptcha is required');
        });
    };

    $scope.recaptcha();

    $scope.safeApply = function(fn) {
        var phase = this.$root.$$phase;
        if(phase == '$apply' || phase == '$digest'){
            this.$eval(fn);
        }else{
            this.$apply(fn);
        }
    };

    $scope.goHome = function() {
        $location.path('/authentication');
        $scope.safeApply();
    };

    $scope.login = function(username) {
        $sessionStorage.loggedInUser = username;
        $scope.goHome();
    };

    $scope.setRecaptchaId = function(widgetId) {
        $scope.recaptchaId = widgetId;
    };
    
    function loginNotification(status){
        if(!Validate.data.isSet(status)){
            $('#loginNotification').removeClass('success');
            $('#loginNotification').addClass('fail');
            $('#loginNotification p').text('Something went wrong');
        }else if(status === 'fail'){
            $('#loginNotification').removeClass('success');
            $('#loginNotification').addClass('fail');
            $('#loginNotification p').text('User Credentials Incorrect');
        }else if(status == 'success'){
            $('#loginNotification').removeClass('fail');
            $('#loginNotification').addClass('success');
            $('#loginNotification p').text('Login Successful');
        }else if(status == 'fail-recaptcha'){
            $('#loginNotification').removeClass('success');
            $('#loginNotification').addClass('fail');
            $('#loginNotification p').text('User Credentials Incorrect. Too many incorrect login attempts.');
        }else{
            $('#loginNotification').removeClass('success');
            $('#loginNotification').addClass('fail');
            $('#loginNotification p').text(status);
        }
    }

    $("#login-button").click(function(event){
        event.preventDefault();

        var $form = $('#login-form');

        var $username = $form.find('#username');
        var $password = $form.find('#password');
        var cError = 'form__input--error';
        var cSending = 'form__input--sending';
        var cSent = 'form__input--sent';
        var that = $(this);
        var error = false;
        var rec = false;

        var username = $username.val();
        if(!username.length){
            $username.addClass(cError);
            $username.attr('title', 'Please add a name.');
            error = true;
        }else{
            $username.removeClass(cError);
            $username.attr('title', '');
        }

        var password = $password.val();
        if(!password.length){
            $password.addClass(cError);
            $password.attr('title', 'Please add a password.');
            error = true;
        }else{
            $password.removeClass(cError);
            $password.attr('title', '');
        }

        if(error){
            return false;
        }else{
            that.addClass(cSending);
        }
        
        if($scope.recaptchaRequired){
            rec = vcRecaptchaService.getResponse($scope.recaptchaId);
            vcRecaptchaService.reload($scope.recaptchaId);
        }else{
            rec = true;
        }
        
        if(rec){
            console.info('Attempting login');
            Authentication.login(username, password, rec).then(function(){
                console.info('Successfull Login');
                
                loginNotification('success');
                $('form').fadeOut(500);
                $('.wrapper').addClass('form-success');

                setTimeout(function(){
                    $scope.login(username);
                }, 2000);
            }, function(errorData){
                console.error('Login request failed', errorData);
                
                if($scope.recaptchaRequired){
                    loginNotification('fail-recaptcha');
                }else{
                    loginNotification('fail');
                }
                
                $password.val('');
                $password.focus();
                $scope.recaptcha();
            });
        }
    });

});

angular.module('LoginCtrl').factory("RecaptchaVerification", function RecaptchaVerification($q, $http, $timeout){


    return {
        requestRecaptchaVerification: function(response){
            var deferred = $q.defer();
            console.log("Request Recaptcha Verification");

            $.ajax({
                url: 'https://www.google.com/recaptcha/api/siteverify',
                type: 'POST',
                dataType: 'html',
                cache: false,
                data: {secret: '6LcbVxYTAAAAAJuM9nIICTQkmiLycFWMNfHW-ljr', response: response},
                success: function(data){
                    console.info("Captcha");
                },
                error: function(error){
                    console.error('Captcha', error)
                    deferred.reject(error);
                }
            });

            return deferred.promise;
        }

    };
});

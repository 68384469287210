// app/js/controllers/HomeCtrl.js
Service.factory("DEXUser", function DEXUser($q, $http, $timeout, $localStorage, Validate, apiService, FORMATTER){
    var user = null;
    
    var userMainDisplay = function(data){
        if(data.length <= 0){
            return '';
        }
        
        var html = "";
        
        html += '<div class="dss-profile content-header">';
        html += '<h2 id="organisation-name">'+ data.Organisation.Name +'</h2>';
        html += '</div>';
        html += '<div class="content-subheader">';
        html += '<table id="dss-user-details">';
        
        if(data.UserName !== ''){
            html += '<tr><td>Username: </td><td><p id="dss-user-username">'+ data.UserName +'</p></td></tr>';
        }

        if(data.FirstName !== '' && data.FamilyName !== ''){
            html += '<tr><td>Name: </td><td><p id="dss-user-name">'+ data.FirstName +" "+ data.FamilyName +'</p></td></tr>';
        }

        if(data.Phone !== ''){
            html += '<tr><td>Phone: </td><td><p id="dss-user-phone">'+ data.Phone +'</p></td></tr>';
        }

        if(data.Email !== ''){
            html += '<tr><td>Email: </td><td><p id="dss-user-email">'+ data.Email +'</p></td></tr>';
        }

        if(data.Organisation.ABN !== ''){
            html += '<tr><td>ABN: </td><td><p id="dss-user-abn">'+ data.Organisation.ABN +'</p></td></tr>';
        }
        
        html += '</table>';
        html += '</div>';

        return html;
    };
    
    var outletsMainDisplay = function(data, first, last){
        if(!Validate.data.isSet(data)){
            return '';
        }
        
        var outletActivities = data.OutletActivities.OutletActivity;
        if(typeof outletActivities[0] === 'object'){
            outletActivities.sort(function(a,b) {
                var firstVal = a.OutletName.toUpperCase();
                var secondVal = b.OutletName.toUpperCase();
                return (firstVal > secondVal) ? 1 : ((secondVal > firstVal) ? -1 : 0);
            });
        }else{
            outletActivities = [];
            outletActivities[0] = data.OutletActivities.OutletActivity;
        }
        
        var html = "";
        
        html += '<div class="data-list">';
        
        html += '<div class="data-title"><h2>Outlets</h2></div>';
        html += '<div class="data">';
        html += '<div class="data-id">ID</div>';
        html += '<div class="data-id">Outlet ID</div>';
        html += '<div class="data-name2">Outlet Name</div>';
        html += '<div class="data-name1">Activity Name</div>';
        html += '<div class="data-name1">Program Name</div>';
        html += '<div class="data-id">Is AGD</div>';

        html += '<div class="data-id2">Start</div>';
        html += '<div class="data-id2">End</div>';
        html += "</div>";
        
        for(var newIndex in outletActivities){
            var value = outletActivities[newIndex];
            
            html += '<div class="data view-btn">';
            
            html += '<div class="data-id">'+ value.OutletActivityId +'</div>';
            html += '<div class="data-id">'+ value.OutletId +'</div>';
            
            html += '<div class="data-name2">'+ value.OutletName +'</div>';
            html += '<div class="data-name1">'+ value.ActivityName +'</div>';
            html += '<div class="data-name1">'+ value.ProgramName +'</div>';
            html += '<div class="data-id">'+ value.IsAgdType +'</div>';
            
            html += '<div class="data-id2">'+ value.StartDate +'</div>';
            html += '<div class="data-id2">'+ value.EndDate +'</div>';
            
            html += "</div>";
        }
        
        html += "</div>";

        return html;
    };

    return {        
        getUser: function(data, update){
            var deferred = $q.defer();
            var error = 'DEX Get User: Issue retrieving DEX user';
            
            if(user === null || update === true){                
                var actions = {action: 'getUser'};

                apiService.post(actions, true).then(function(data){
                    var output = FORMATTER.process(data);
                    if(data.response.success){
                        user = data.getUser;
                        deferred.resolve(user);
                    }else{
                        console.error(error, output);
                        deferred.reject(output);
                    }
                }, function(errorData){
                    var output = FORMATTER.process(errorData);
                    console.error(error, output);
                    deferred.reject(error);
                });
            }else{
                deferred.resolve(user);
            }

            return deferred.promise;
        },
        
        getServiceTypeForOutletActivity: function(data){
            var deferred = $q.defer();
            var error = 'Get Service Type for Outlet Activity: Issue retrieveing DEX Service Type for Outlet Activity';
                         
            var actions = {action: 'getServiceTypeForOutletActivity',
                          OutletActivityId: data.OutletActivityId};

            apiService.post(actions, true).then(function(data){
                var output = FORMATTER.process(data);
                if(data.response.success){
                    deferred.resolve(data.ActivityServiceTypes.ServiceType);
                }else{
                    console.error(error, output);
                    deferred.reject(output);
                }
            }, function(errorData){
                var output = FORMATTER.process(errorData);
                console.error(error, output);
                deferred.reject(error);
            });

            return deferred.promise;
        },
        
        validateUser: function(data, update){
            var deferred = $q.defer();
            var error = 'Validate DEX User: Issue with DEX user. Could not connect to DEX.';
            
            if(Validate.data.isSet(data.dssUsername) && Validate.data.isSet(data.dssPassword)){                
                data.action = 'ping';

                apiService.post(data, true).then(function(data){
                    if(data.response.success){
                        deferred.resolve('Department of Social Services account details correct');
                    }else if(!data.response.success){
                        error = 'Validate DEX User: Department of Social Services account details incorrect';
                        console.error(error, data);
                        deferred.reject(error);
                    }
                }, function(errorData){
                    var output = FORMATTER.process(errorData);
                    console.error(error, output);
                    deferred.reject(output);
                });
            }else{
                error = 'Validate DEX User: DEX User details not defined';
                console.error(error);
                deferred.reject(error);
            }

            return deferred.promise;
        },
        
        displayUser: function(data){
            if(!Validate.data.isSet(data)){
                data = {};
            }
            if(!Validate.data.isSet(data.data)){
                data.data = user;
            }
            
            return userMainDisplay(data.data);
        },
        
        displayOutlets: function(data){
            if(!Validate.data.isSet(data)){
                data = {};
            }
            if(!Validate.data.isSet(data.data)){
                data.data = user;
            }
            if(typeof data.first !== 'number'){
                data.first = 0;
            }
            if(typeof data.last !== 'number' || data.last <= data.first){
                data.last = data.length - 1;
            }
            
            return outletsMainDisplay(data.data, data.first, data.last);
        },
        
        clear: function(){
            user = null;
        }
    };
});



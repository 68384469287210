// app/js/appRoutes.js
angular.module('appRoutes', []).config(['$routeProvider', '$locationProvider', function($routeProvider, $locationProvider){
    $routeProvider
        // login page
        .when('/', {
            templateUrl: 'views/login.html',
            controller: 'LoginController'
        })

        // loading page
        .when('/loading', {
            templateUrl: 'views/loading.html',
            controller: 'LoadingController'
        })
    
        .when('/authentication', {
            templateUrl: 'views/loading.html',
            controller: 'LoadingController'
        })

        // setup page
        .when('/setup', {
            templateUrl: 'views/setup/setup.html',
            controller: 'SetupController'
        })

        // setupRequired page
        .when('/setupRequired', {
            templateUrl: 'views/setup/setupRequired.html',
            controller: 'SetupRequiredController'
        })

        // home page
        .when('/services', {
            templateUrl: 'views/services/services.html',
            controller: 'ServicesController'
        })

        // home page
        .when('/services/other', {
            templateUrl: 'views/services/other.html',
            controller: 'OtherServicesController'
        })
    
        // home page
        .when('/services/dss', {
            templateUrl: 'views/services/dss/clients.html',
            controller: 'DSS-ClientsController'
        })
    
        // home page
        .when('/services/dss/clients', {
            templateUrl: 'views/services/dss/clients.html',
            controller: 'DSS-ClientsController'
        })
    
        // home page
        .when('/services/dss/account', {
            templateUrl: 'views/services/dss/account.html',
            controller: 'DSS-AccountController'
        })
        
        .when('/services/dss/clients/:clientsId*', {
            templateUrl: 'views/services/dss/clients.html',
            controller: 'DSS-ClientsController'
        })
    
        // home page
        .when('/services/dss/logs', {
            templateUrl: 'views/services/dss/logs.html',
            controller: 'DSS-LogsController'
        })
    
        // home page
        .when('/services/dss/logs/:logID*', {
            templateUrl: 'views/services/dss/logs/log.html',
            controller: 'DSS-Logs-LogController'
        })
    
        // home page
        /*.when('/services/dss/stats', {
            templateUrl: 'views/services/dss/stats.html',
            controller: 'DSS-StatsController'
        })*/

        // home page
        .when('/admin', {
            templateUrl: 'views/admin/accounts.html',
            controller: 'AccountsController'
        })

        // home page
        .when('/admin/accounts', {
            templateUrl: 'views/admin/accounts.html',
            controller: 'AccountsController'
        })
    
        // home page
        .when('/admin/services', {
            templateUrl: 'views/admin/services.html',
            controller: 'ServicesAdminController'
        })
    
        // home page
        .when('/admin/services/dss', {
            templateUrl: 'views/admin/services/dss.html',
            controller: 'DSS-ServicesAdminController'
        })

        // home page
        .when('/admin/accounts/:organisationID/users', {
            templateUrl: 'views/admin/organisation/users.html',
            controller: 'AccountUsersController'
        })

        // home page
        .when('/admin/accounts/:organisationID/services', {
            templateUrl: 'views/admin/organisation/services.html',
            controller: 'AccountServicesController'
        })

        // home page
        .when('/admin/accounts/:organisationID*', {
            templateUrl: 'views/admin/organisation/organisation.html',
            controller: 'OrganisationController'
        })

        // home page
        .when('/admin/activityLogs', {
            templateUrl: 'views/admin/activityLogs.html',
            controller: 'ActivityLogsController'
        })

        // if doesn't exists
        .otherwise({
            redirectTo: '/'
        });

    $locationProvider.html5Mode(false);
    $locationProvider.hashPrefix('!');
}])

.run(function(Authentication, Application, $rootScope, $location, RouteFilter, $localStorage, setCommonDataFunctions){
    console.info('Initialising Application');
    Authentication.requestUser().then(function(data){
        Application.makeReady();
    }, function(errorData){
        Application.makeReady();
    });

    $rootScope.$on('$locationChangeStart', function(scope, next, current) {
        if($location.path() === '/loading'){
            return;
        }

        if(!Application.isReady()){
            $localStorage.requestedRoute = $location.path();
            $location.path('loading');
        }
        
        RouteFilter.run($location.path());
        setCommonDataFunctions();
    });
})

.factory('setCommonDataFunctions', function(Authentication, $rootScope, $location, $route, $routeParams, Validate, RouteFilter, $sessionStorage, $localStorage, CiviExchange, CiviCRM, DEX){
    
    return function() {
        $rootScope.CancelRequests = function(){
            var response = {response: {}};
            response.response.success = false;
            response.response.code = 408;
            response.response.message = 'Cancelled';
            
            for(var i = 0; i < $rootScope.requests.length; i++){
                if(Validate.data.isSet($rootScope.requests[i])){
                    $rootScope.requests[i](response);
                }
            }
            $rootScope.requests = [];
        };
        
        if(!Validate.data.isSet($rootScope.requests)){
            $rootScope.requests = [];
        }else{
            $rootScope.CancelRequests();
        }
        
        // Logout User
        $rootScope.logout = function() {
            console.info('Logout: Attempting user logout');
            Authentication.logout().then(function(){
                console.info('Logout: Logout Successful. Clearing locally stored data.');
                delete $sessionStorage;
                delete $localStorage;
                
                CiviExchange.clearAll();
                CiviCRM.clearAll();
                DEX.clearAll();
                
                $rootScope.gotoLogin();
            });
        };
        
        $rootScope.safeApply = function(fn) {
            var phase = this.$root.$$phase;
            if(phase == '$apply' || phase == '$digest'){
                this.$eval(fn);
            }else{
                this.$apply(fn);
            }
        };

        $rootScope.gotoLogin = function() {
            $location.path('/');
        };

        $rootScope.canAccess = function(route){
            return RouteFilter.canAccess(route);
        };

        $rootScope.checkToolName = function(name){
            if(Validate.data.isSet($routeParams.clientsId)){
                return name == 'clients';
            }

            return $routeParams.name == name;
        };
        
        var original = $rootScope.path;
        $rootScope.path = function (path, reload) {
            if (reload === false) {
                var lastRoute = $route.current;
                var un = $rootScope.$on('$locationChangeSuccess', function () {
                    $route.current = lastRoute;
                    un();
                });
            }

            return original.apply($location, [path]);
        };
        
        // Reload user credemtials
        $rootScope.reloadUser = function(){
            console.info('Reload User: Updating User Credentials');
            if(Authentication.exists()){
                $rootScope.authorisedUser = Authentication.getUser();
                $rootScope.authorisedUser.organisation = Authentication.getOrganisation();
                
                $rootScope.isServiceAccessSetup = function(name){
                    return Authentication.isServiceAccessSetup(name);
                };

                $rootScope.isCiviAccessSetup = function(){
                    return Authentication.isCiviAccessSetup();
                };

                $rootScope.isAdmin = function(){
                    return Authentication.isAdmin();
                };

                $rootScope.isOrganisationAdmin = function(){
                    return Authentication.isOrganisationAdmin();
                };

                $rootScope.isUsersOrganisation = function(id){
                    return Authentication.isUsersOrganisation(id);
                };
            }else{
                console.error('Reload User: Issue confirming user authentication');
            }
        };
        $rootScope.reloadUser();
        
        // Logout if user cannot be found
        console.info('User Active: Checking User Active Status');
        Authentication.requestUser().then(function(){
            Authentication.isUserActive().then(function(active){
                if(!active){
                    console.warn('User Active: User is currently disabled. User will now be logged out.');
                    $rootScope.logout();
                }
            }, function(){
                console.error('User Active: Issue authenticating User active status. User will now be logged out.');
                $rootScope.logout();
            });

            Authentication.isOrganisationActive().then(function(active){
                if(!active && !Authentication.isOrganisationAdmin()){
                    console.warn('User Active: User Organisation is currently disabled. User will now be logged out.');
                    $rootScope.logout();
                }
            }, function(){
                console.error('User Active: Issue authenticating User Organisation active status. User will now be logged out.');
                $rootScope.logout();
            });
        }, function(){
            console.error('User Active: Issue authenticating User. User will now be logged out.');
            $rootScope.logout();
        });
    };
});




AdminParentCtrl.controller('ServicesAdminController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            $compile,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            LoadLocalData,
                                                            InputValidation){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Administration';
    $scope.page.header.title = 'Service Admin';
    $scope.page.header.backLink = '';
    $scope.page.sidebar.variant = 'accountsAdmin';
    $scope.page.sidebar.selectedNav = 'Services';
});
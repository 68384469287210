var load = {
    
    attemptCount: 1,
    attemptLimit: 5,
    loadId: '',
    loadType: '',
    loadTitle: '',
    loadDesc: '',
    
    setLimit: function(amt){
        this.attemptLimit = amt;
    },
        
    begin: function(id, type, title, desc, retryLimit){
        if(this.attemptCount == 1){
            if(typeof id !== 'undefined' && id !== '' && 
               typeof type !== 'undefined' && type !== '' && 
               typeof title !== 'undefined'){
                
                if(typeof retryLimit !== 'undefined'){
                    this.setLimit(retryLimit);
                }
                
                this.loadId = id;
                this.loadType = type;
                this.loadTitle = title;
                this.loadDesc = desc;
                
                return loadingIndicator.get(this.loadId, this.loadType, this.loadTitle, this.loadDesc);
            }
        }
    },
    
    retry: function(title, desc, id){
        console.log('RETRY', this.attemptCount, this.attemptLimit);
        this.attemptCount++;
        var loadId = this.loadId;
        if(typeof id !== 'undefined'){
            loadId = id;
        }
        
        if(this.attemptCount <= this.attemptLimit){
            if(title !== ''){
                this.loadTitle = title;
            }
            this.loadDesc = desc;
            this.update(this.loadTitle, this.loadDesc +' '+ this.attemptCount +'/'+ this.attemptLimit, loadId);
            return true;
        }else{
            loadingIndicator.remove(loadId);
            return false;
        }
    },
    
    update: function(title, desc, id){
        var loadId = this.loadId;
        if(typeof id !== 'undefined'){
            loadId = id;
        }
        if(title !== ''){
            this.loadTitle = title;
        }
        this.loadDesc = desc;
        loadingIndicator.update(loadId, this.loadTitle, desc);
    },
    
    remove: function(fade, id){
        this.attemptCount = 1;
        if(typeof fade === 'undefined'){
            fade = true;
        }
        var loadId = this.loadId;
        if(typeof id !== 'undefined'){
            loadId = id;
        }
        loadingIndicator.remove(loadId, fade);
    }
};
// app/js/controllers/HomeCtrl.js
angular.module('DataTransmitter')
    .factory("Notify", function Notify($q, $http, $timeout, $localStorage){
    
    return {        
        create: {
            push: function(text, description){
                var sendNotification = function(text, description){
                    var options = {
                        body: description,
                        icon: 'imgs/favicons/favicon-196x196.png'
                    };
                    var notification = new Notification('CiviExchange\n'+text, options);
                };
                
                // Let's check if the browser supports notifications
                if (!("Notification" in window)) {
                    console.info("This browser does not support desktop notification");
                }

                // Let's check whether notification permissions have already been granted
                else if (Notification.permission === "granted") {
                    // If it's okay let's create a notification
                    sendNotification(text, description);
                }

                // Otherwise, we need to ask the user for permission
                else if (Notification.permission !== "denied") {
                    Notification.requestPermission(function (permission) {
                        // If the user accepts, let's create a notification
                        if (permission === "granted") {
                            sendNotification(text, description);
                        }
                    });
                }
            }
        }
    };
});

var AdminParentCtrl = angular.module('AdminParentCtrl', ['ngStorage']);

AdminParentCtrl.controller('AccountsController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            $compile,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            LoadLocalData,
                                                            InputValidation,
                                                            Validate){
    // Redirect to User Organisation if Organisation Administrator
    if(Authentication.isOrganisationAdmin()){
        $location.path('/admin/accounts/'+$rootScope.authorisedUser.organisation_id);
    }
    
    // Global Variables
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Administration';
    $scope.page.header.title = 'Organisations';
    $scope.page.header.backLink = '';
    $scope.page.sidebar.variant = 'accountsAdmin';
    $scope.page.sidebar.selectedNav = 'Accounts';
    
    var currentOrganisationID;
    
    var initOrganisations = function(){
        $scope.organisations = {
            code: '',
            availableOrganisations: [],
        };

        $scope.updateOrganisations = function(organisations){
            if($scope.organisations.code === ''){
                $scope.organisations.code = organisations[0].id;
            }
            $scope.organisations.availableOrganisations = [];

            for(var i = 0; i < organisations.length; i++){
                $scope.organisations.availableOrganisations[i] = {value: organisations[i].id, name: organisations[i].name};
            }
        };

        $scope.getOrganisations = function(update) {
            LoadLocalData.get.organisations('', update).then(function(organisations){
                console.info('Successfully loaded organisations', organisations);
                
                // Alphabetical order by name sort
                organisations.sort(function(a,b) {return (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0);} );
                
                $scope.allOrganisations = organisations;
                $scope.organisationsLoaded = true;
                $scope.updateOrganisations($scope.allOrganisations);
                
                if(Validate.data.isSet($rootScope.currentOrganisation)){
                    $scope.updateOrganisation($rootScope.currentOrganisation.id);
                }
            }, function(errorData){
                console.error('Issue retrieving organisations', errorData);
                $scope.referenceData = errorData;
            });
        };
        $scope.getOrganisations(false);
    };
    
    $scope.updateOrganisation = function(id){
        $rootScope.currentOrganisation = CiviExchange.get.organisation(id);
    };
    
    var validateOrganisation = {
        update: false,
        $organisationForm: null,
        $organisationName: null,
        $organisationUid: null,
        $civiURL: null,
        init: function($elem){
            this.$organisationForm = $elem;
            this.$organisationName = this.$organisationForm.find('#name .form__input');
            this.$organisationUid = this.$organisationForm.find('#uid .form__input');
            this.$civiURL = this.$organisationForm.find('#civiURL .form__input');
        },
        clearInputs: function(){
            this.$organisationName.val('');
            this.$organisationUid.val('');
            this.$civiURL.val('');
        },
        id: function(id){
            if(InputValidation.length(id)){
                if(InputValidation.isMaxLength(id, 10)){
                    return true;
                }
            }
            return false;
        },
        name: function(currentOrg){
            var organisationName = this.$organisationName.val();
            if(InputValidation.length(organisationName, this.$organisationName, 'Please enter a Name')){
                if(InputValidation.isMaxLength(organisationName, 255, this.$organisationName, 'Too long')){
                    if(typeof currentOrg !== 'string'){
                        currentOrg = '';
                    }
                    if(!InputValidation.organisationNameExists(organisationName, this.$organisationName, 'Name already exists', currentOrg)){
                        return true;
                    }
                }
            }
            return false;
        },
        uid: function(){
            var organisationUid = this.$organisationUid.val();
			if(InputValidation.isMaxLength(organisationUid, 255, this.$organisationUid, 'Too long')){
				return true;
			}
            return false;
        },
        civiURLLength: function(){
            var civiURL = this.$civiURL.val();
            if(InputValidation.length(civiURL)){
                return true;
            }
            return false;
        },
        civiURL: function(){
            var civiURL = this.$civiURL.val();
            if(InputValidation.length(civiURL)){
                if(InputValidation.isMaxLength(civiURL, 1000, this.$civiURL, 'Too long')){
                    if(InputValidation.isURL(civiURL, this.$civiURL, 'Please enter a valid URL')){
                        return true;
                    }
                }
            }
            return false;
        }
    };
    
    $scope.setupUpdateOrganisationForm = function(id){
        var orgId = id;
        var $form = $('#modal-form');
        var $feedback = $form.find('#feedback');
        validateOrganisation.init($form);
        validateOrganisation.update = true;

        validateOrganisation.$organisationName.on('input', function(){
            validateOrganisation.name($scope.currentOrganisation.name);
        });

        validateOrganisation.$organisationUid.on('input', function(){
            validateOrganisation.uid();
        });

        $form.find("#submit-button").click(function(event){
            event.preventDefault();
            var cSending = 'form__input--sending';
            var cSent = 'form__input--sent';
            var that = $(this);
            var valid = true;
            var data = {};
            
            if(!validateOrganisation.id(orgId)){
               valid = false;
            }
               
            if(!validateOrganisation.name($scope.currentOrganisation.name)){
               valid = false;
            }
               
            if(!validateOrganisation.uid()){
               valid = false;
            }
            
            if(!valid){
                console.error('Incorrect values found in form data');
                return false;
            }
            
            data.id = orgId;
            data.name = validateOrganisation.$organisationName.val();
            data.uid = validateOrganisation.$organisationUid.val();
            
            CiviExchange.validate.organisation(data).then(function(exists){
                if(exists){
                    CiviExchange.update.organisation(data).then(function(){
                        console.info('Successfully Updated organisation');
                        $scope.getOrganisations(true);
                        $scope.modal.close();
                    }, function(errorData){
                        console.error('Issue updating organisation', errorData);
                        $feedback.html('<p class="error">Issue updating organisation</p>');
                    });
                }else{
                    console.error('Could not find Organisation');
                    $feedback.html('<p class="error">Could not find Organisation</p>');
                }
            }, function(errorData){
                console.error('Issue verifying whether Organisation already exists', errorData);
                $feedback.html('<p class="error">Issue verifying whether Organisation already exists</p>');
            });
        });
    };
    
    $scope.disableOrganisation = function(id, active){
        var valid = true;
        var data = {};

        if(!validateOrganisation.id(id)){
           valid = false;
        }

        if(!valid){
            console.error('Incorrect values found in form data');
            return false;
        }

        data.id = id;
        
        if(active == '0'){
            data.active = '1';
        }else{
            data.active = '0';
        }
        
        $scope.disableOrganisationSubmit = {};
        $scope.disableOrganisationSubmit.allow = false;
        $scope.disableOrganisationSubmit.submit = function(){
            if(!$scope.disableOrganisationSubmit.allow && 
               data.active === '0'){
                $scope.modal.notification('disableOrganisation');
                return false;
            }
            
            CiviExchange.disable.organisation(data).then(function(){
                console.info('Successfully disabled organisation');
                $scope.getOrganisations(true);
            }, function(errorData){
                console.error('Issue disabling organisation', errorData);
            });
        };
        
        $scope.disableOrganisationSubmit.submit();
    };
    
    $scope.disableOrganisationConfirm = function(){
        $scope.disableOrganisationSubmit.allow = true;
        $scope.modal.notification('');
        $scope.disableOrganisationSubmit.submit();
    };
    
    $scope.setupAddOrganisationForm = function(){
        var $form = $('#modal-form');
        var $feedback = $form.find('#feedback');
        validateOrganisation.init($form);
        validateOrganisation.update = false;

        validateOrganisation.$organisationName.on('input', function(){
            validateOrganisation.name();
        });

        validateOrganisation.$organisationUid.on('input', function(){
            validateOrganisation.uid();
        });

        $form.find("#submit-button").click(function(event){
            event.preventDefault();
            var cSending = 'form__input--sending';
            var cSent = 'form__input--sent';
            var that = $(this);
            var valid = true;
            var data = {};
            
            if(!validateOrganisation.name()){
               valid = false;
            }
               
            if(!validateOrganisation.uid()){
               valid = false;
            }
            
            if(!valid){
                console.error('Incorrect values found in form data');
                return false;
            }
			
            data.name = validateOrganisation.$organisationName.val();
            data.uid = validateOrganisation.$organisationUid.val();
            
            CiviExchange.validate.organisation(data).then(function(exists){
                if(!exists){
                    CiviExchange.create.organisation(data).then(function(){
                        console.info('Successfully created organisation');
                        //validateOrganisation.clearInputs();
                        $scope.getOrganisations(true);
                        $scope.modal.close();
                    }, function(errorData){
                        console.error('Issue creating organisation', errorData);
                        $feedback.html('<p class="error">Issue creating Organisation</p>');
                    });
                }else{
                    console.error('Organisation already exists');
                    $feedback.html('<p class="error">Organisation already exists</p>');
                }
            }, function(errorData){
                console.error('Issue verifying whether Organisation already exists', errorData);
                $feedback.html('<p class="error">Issue verifying whether Organisation already exists</p>');
            });
        });
    };
    
    $scope.modal = function(url, pagename){
        $scope.modal.properties = {};
        
        $scope.modal.open = function(url){
            $scope.modal.properties.url = url;
            var html = '<div ng-include="\''+ url +'\'" ng-click="modal.close()" id="modal-blanket" class="modal-blanket"></div>';
            var temp = $compile(html)($scope);
            angular.element(document.getElementById('inner-content')).append('<div id="modal-container"></div>');
            angular.element(document.getElementById('modal-container')).html(temp);
        };
    
        $scope.modal.loadPage = function(pageName){
            $scope.modal.notification('');
            $scope.modal.properties.pageName = pageName;
            $scope.modal.open($scope.modal.properties.url);
        };

        $scope.modal.close = function(){
            $scope.modal.properties = {};
            angular.element(document.getElementById('modal-container')).remove();
        };

        $scope.modal.notification = function(notificationName){
            $scope.modal.properties.notificationName = notificationName;
            $scope.modal.open($scope.modal.properties.url);
        };
        
        if(typeof url === 'string'){
            $scope.modal.properties.url = url;
            if(typeof pagename === 'string'){
                $scope.modal.loadPage(pagename);
            }else{
                $scope.modal.open(url);
            }
        }
    };
    
    if($rootScope.canAccess('/admin') && Authentication.isAdmin()){
        initOrganisations();
    }else if($rootScope.canAccess('/admin') && Authentication.isOrganisationAdmin()){
        initOrganisations();
    }
});
// app/js/controllers/HomeCtrl.js
Service.factory("DEX", function DEX($q, $http, $timeout, $localStorage, Validate, apiService, DEXReferenceData, DEXUser, DEXClients){
    var dataSet = function(data){
        if(!Validate.data.isSet(data)){
            console.error('DEX API: Data is required');
            return false;
        }
        return true;
    };
    
    var updateSet = function(update){
        if(update !== true){
            return false;
        }
        return true;
    };
    
    return {
        get: {
            user: function(data, update){
                update = updateSet(update);
                return DEXUser.getUser(data, update);
            },
            externalReferenceData: function(data, update){
                update = updateSet(update);
                return DEXReferenceData.getExternalReferenceData(data, update);
            },
            localReferenceData: function(data, update){
                update = updateSet(update);
                return DEXReferenceData.getLocalReferenceData(data, update);
            },
            bulkXML: function(){
                return DEXClients.getXML();
            },
            serviceTypeForOutletActivity: function(data, update){
                if(!dataSet(data)){
                    return false;
                }
                update = updateSet(update);
                return DEXUser.getServiceTypeForOutletActivity(data);
            }
        },
        
        update: {
            referenceData: {
                byCode: function(code){
                    return DEXReferenceData.update(code);
                },
                mismatch: function(){
                    return DEXReferenceData.updateMismatch();
                }
            }
        },
        
        display: { 
            referenceData: {
                main: function(data){
                    return DEXReferenceData.getCompareFormatted(data);
                }
            },
            user: {
                main: function(data){
                    return DEXUser.displayUser(data);
                }
            },
            outlets: {
                main: function(data){
                    return DEXUser.displayOutlets(data);
                }
            }
        },
        
        find: {
            referenceData: {
                mismatch: function(){
                    return DEXReferenceData.mismatchFound();
                },
                mismatchTotalAmount: function(){
                    return DEXReferenceData.mismatchTotalAmount();
                }
            }
        },
        
        validate: {
            client: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.validateClient(data);
            },
            clients: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.validateClients(data);
            },
            session: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.validateCase(data);
            },
            // DSS USER
            user: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXUser.validateUser(data);
            },
            organisation: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXUser.validateOrganisation(data);
            }
        },
        
        send: {
            client: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.sendClient(data);
            },
            session: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.sendCase(data);
            }
        },
        
        delete: {
            client: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.deleteClient(data);
            },
            session: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.deleteSession(data);
            }
        },
        
        register: {
            transfer: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.registerTransfer(data);
            }
        },
        
        download: {
            bulkXML: function(data){
                return DEXClients.downloadXML(data);
            },
            csv: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return DEXClients.downloadCSV(data);
            }
        },
        
        clearAll: function(){
            DEXUser.clear();
            DEXClients.clear();
            DEXReferenceData.clear();
        }
    };
});

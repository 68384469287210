AdminParentCtrl.controller('AccountUsersController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            $compile,
                                                            Validate,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            LoadLocalData,
                                                            InputValidation){
    // Global Variables
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.variant = 'organisationAdmin';
    $scope.page.header.breadcrumbs = 'Organisation Administration';
    $scope.page.header.title = '';
    $scope.page.header.backLink = '#!/admin/accounts';
    $scope.page.sidebar.variant = 'organisationAdmin';
    $scope.page.sidebar.selectedNav = 'Users';
    $scope.organisationID = $routeParams.organisationID;
    
    // DOM Styling Functions
    $scope.class = {};
    $scope.class.isActive = function(active){
        if(active === '0'){
            return 'locked';
        }else if(active === '1'){
            return 'unlocked';
        }
    };
    
    $scope.usersLoaded = false;
    $scope.organisationsLoaded = false;
    $scope.users = {
        code: '0',
        availableAuth: [
            {value: '0', name: 'User'},
            {value: '2', name: 'Organisation Administrator'}
        ],
    };
    
    $scope.getAuthName = function(auth){
        for(var i = 0; i < $scope.users.availableAuth.length; i++){
            if($scope.users.availableAuth[i].value == auth){
                return $scope.users.availableAuth[i].name;
            }
        }
        
        return auth;
    };
    
    $scope.isCurrentUser = function(id){
        return $rootScope.authorisedUser.id === id;
    };
    
    $scope.setCurrentOrganisation = function(id, update){
        LoadLocalData.get.organisations('', update).then(function(organisations){
            $rootScope.currentOrganisation = CiviExchange.get.organisation(id);
            $scope.page.header.title = $rootScope.currentOrganisation.name;
            $scope.updateOrganisations(organisations);
        }, function(error){
            $scope.referenceData = error;
        });
    };
    
    var initOrganisations = function(){
        $scope.organisations = {
            code: '',
            availableOrganisations: [],
        };

        $scope.updateOrganisations = function(organisations){
            if($scope.organisations.code === ''){
                $scope.organisations.code = $scope.organisationID;
            }
            $scope.organisations.availableOrganisations = [];

            for(var i = 0; i < organisations.length; i++){
                $scope.organisations.availableOrganisations[i] = {value: organisations[i].id, name: organisations[i].name};
            }
        };
        
        $scope.setCurrentOrganisation($scope.organisationID, false);
    };
    
    var initUsers = function(){
        $scope.getUsers = function(update) {
            LoadLocalData.get.users('', update).then(function(users){
                CiviExchange.get.organisationUsers($scope.organisationID, update).then(function(users){
                    console.info('Successfully loaded users', users);

                    // Alphabetical order by name sort
                    users.sort(function(a,b) {return (a.username.toUpperCase() > b.username.toUpperCase()) ? 1 : ((b.username.toUpperCase() > a.username.toUpperCase()) ? -1 : 0);} );

                    $scope.allUsers = users;
                    $scope.usersLoaded = true;
                    
                    if(Validate.data.isSet($scope.selectedUser)){
                        $scope.setSelectedUser($scope.selectedUser.id);
                    }
                }, function(error){
                    $scope.referenceData = error;
                });
            }, function(errorData){
                console.error('Issue loading users', errorData);
                $scope.referenceData = errorData;
            });
        };
        $scope.getUsers(false);
    };
    
    var validateUser = {
        update: false,
        $userForm: null,
        $first_name: null,
        $last_name: null,
        $email: null,
        $username: null,
        $password: null,
        $password2: null,
        $organisation: null,
        $auth: null,
        init: function($elem){
            this.$userForm = $elem;
            this.$first_name = this.$userForm.find('#firstName .form__input');
            this.$last_name = this.$userForm.find('#lastName .form__input');
            this.$email = this.$userForm.find('#email .form__input');
            this.$username = this.$userForm.find('#username .form__input');
            this.$password = this.$userForm.find('#password .form__input');
            this.$password2 = this.$userForm.find('#password2 .form__input');
            this.$organisation = this.$userForm.find('#organisation .form__input');
            this.$auth = this.$userForm.find('#auth .form__input');
        },
        clearInputs: function(){
            this.$first_name.val('');
            this.$last_name.val('');
            this.$email.val('');
            this.$username.val('');
            this.$password.val('');
            this.$password2.val('');
        },
        removeError: function($elem){
            InputValidation.removeError($elem);
        },
        id: function(id){
            if(InputValidation.length(id)){
                if(InputValidation.isMaxLength(id, 10)){
                    return true;
                }
            }
            return false;
        },
        first_name: function(){
            var name = this.$first_name.val();
            if(InputValidation.length(name, this.$first_name, 'Please enter a Name')){
                if(InputValidation.isMaxLength(name, 255, this.$first_name, 'Too long')){
                    return true;
                }
            }
            return false;
        },
        last_name: function(){
            var name = this.$last_name.val();
            if(InputValidation.length(name, this.$last_name, 'Please enter a Name')){
                if(InputValidation.isMaxLength(name, 255, this.$last_name, 'Too long')){
                    return true;
                }
            }
            return false;
        },
        email: function(){
            var email = this.$email.val();
            if(InputValidation.length(email, this.$email, 'Please enter an Email address')){
                if(InputValidation.isMaxLength(email, 255, this.$email, 'Too long')){
                    if(InputValidation.email(email, this.$email, 'Please enter a Valid Email address')){
                        return true;
                    }
                }
            }
            return false;
        },
        usernameLength: function(){
            var username = this.$username.val();
            if(InputValidation.length(username)){
                return true;
            }
            return false;
        },
        username: function(currentUser){
            var username = this.$username.val();
            if(InputValidation.length(username, this.$username, 'Please enter a username')){
                if(InputValidation.isMaxLength(username, 255, this.$username, 'Too long')){
                    if(!InputValidation.containsSpecialCharacters(username, this.$username, 'Username can only contain special characters _ and -')){
                        if(typeof currentUser !== 'string'){
                            currentUser = '';
                        }
                        if(!InputValidation.usernameExists(username, this.$username, 'Username already exists', currentUser)){
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        passwordLength: function(){
            var password = this.$password.val();
            var password2 = this.$password2.val();
            if(InputValidation.length(password) || InputValidation.length(password2)){
                return true;
            }
            return false;
        },
        password1: function(){
            var password = this.$password.val();
            var password2 = this.$password2.val();
            if(InputValidation.length(password, this.$password, 'Please enter a Password')){
                if(InputValidation.isMaxLength(password, 255, this.$password, 'Too long')){
                    if(InputValidation.length(password2, this.$password2)){
                        if(InputValidation.equality(password, password2, this.$password, this.$password2, 'Passwords don\'t match.')){
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        password2: function(){
            var password = this.$password.val();
            var password2 = this.$password2.val();
            InputValidation.length(password, this.$password, 'Please enter a Password');
            if(InputValidation.length(password2, this.$password2, 'Please re-type your Password')){
                if(InputValidation.isMaxLength(password2, 255, this.$password2, 'Too long')){
                    if(InputValidation.equality(password, password2, this.password, this.$password2, 'Passwords don\'t match.')){
                        return true;
                    }
                }
            }
            return false;
        },
        organisation: function(){
            var organisation = ''+$scope.organisations.code;
            if(InputValidation.length(organisation, this.$organisation, 'Please enter a Name')){
                if(InputValidation.isMaxLength(organisation, 5, this.$organisation, 'Too long')){
                    return true;
                }
            }
            return false;
        },
        auth: function(){
            var auth = ''+$scope.users.code;
            if(InputValidation.length(auth, this.$auth, 'Please select an authorisation level')){
                if(InputValidation.isMaxLength(auth, 2, this.$auth, 'Too long')){
                    return true;
                }
            }
            return false;
        }
    };
    
    var validateOrganisation = {
        update: false,
        $organisationForm: null,
        $organisationName: null,
        $civiURL: null,
        init: function($elem){
            this.$organisationForm = $elem;
            this.$organisationName = this.$organisationForm.find('#name .form__input');
            this.$civiURL = this.$organisationForm.find('#civiURL .form__input');
        },
        clearInputs: function(){
            this.$organisationName.val('');
            this.$civiURL.val('');
        },
        id: function(id){
            if(InputValidation.length(id)){
                if(InputValidation.isMaxLength(id, 10)){
                    return true;
                }
            }
            return false;
        },
        name: function(currentOrg){
            var organisationName = this.$organisationName.val();
            if(InputValidation.length(organisationName, this.$organisationName, 'Please enter a Name')){
                if(InputValidation.isMaxLength(organisationName, 255, this.$organisationName, 'Too long')){
                    if(typeof currentOrg !== 'string'){
                        currentOrg = '';
                    }
                    if(!InputValidation.organisationNameExists(organisationName, this.$organisationName, 'Name already exists', currentOrg)){
                        return true;
                    }
                }
            }
            return false;
        },
        civiURLLength: function(){
            var civiURL = this.$civiURL.val();
            if(InputValidation.length(civiURL)){
                return true;
            }
            return false;
        },
        civiURL: function(){
            var civiURL = this.$civiURL.val();
            if(InputValidation.length(civiURL)){
                if(InputValidation.isMaxLength(civiURL, 1000, this.$civiURL, 'Too long')){
                    if(InputValidation.isURL(civiURL, this.$civiURL, 'Please enter a valid URL')){
                        return true;
                    }
                }
            }
            return false;
        }
    };
    
    $scope.disableUser = function(organisationId, userId, active){
        var valid = true;
        var data = {};

        if(!validateOrganisation.id(organisationId)){
           valid = false;
        }
            
        if(!validateUser.id(userId)){
           valid = false;
        }

        if(!valid){
            console.error('Incorrect values found in form data');
            return false;
        }

        data.org_id = organisationId;
        data.user_id = userId;
        
        if(active == '0'){
            data.active = '1';
        }else{
            data.active = '0';
        }
        
        $scope.disableUserSubmit = {};
        $scope.disableUserSubmit.allow = false;
        $scope.disableUserSubmit.submit = function(){
            if(!$scope.disableUserSubmit.allow && 
               data.active === '0'){
                $scope.modal.notification('disableUser');
                return false;
            }

            CiviExchange.disable.user(data).then(function(){
                console.info('Successfully disabled user');
                //validateOrganisation.clearInputs();
                $scope.getUsers(true);
            }, function(errorData){
                console.error('Issue disabling user', errorData);
            });
        };
        
        $scope.disableUserSubmit.submit();
    };
    
    $scope.disableCurrentUser = function(){
        $scope.disableUserSubmit.allow = true;
        $scope.modal.notification('');
        $scope.disableUserSubmit.submit();
    };
    
    $scope.deleteUser = function(organisationId, userId){
        var valid = true;
        var data = {};

        if(!validateOrganisation.id(organisationId)){
           valid = false;
        }
            
        if(!validateUser.id(userId)){
           valid = false;
        }

        if(!valid){
            console.error('Incorrect values found in form data');
            return false;
        }

        data.org_id = organisationId;
        data.user_id = userId;
        
        $scope.deleteUserSubmit = {};
        $scope.deleteUserSubmit.allow = false;
        $scope.deleteUserSubmit.submit = function(){
            if(!$scope.deleteUserSubmit.allow){
                $scope.modal.notification('deleteUser');
                return false;
            }
            
            CiviExchange.delete.user(data).then(function(){
                console.info('Successfully deleted user');
                $scope.getUsers(true);
                $scope.modal.close();
            }, function(errorData){
                console.error('Issue deleting user', errorData);
            });
        };
        
        $scope.deleteUserSubmit.submit();
    };
    
    $scope.deleteCurrentUser = function(){
        $scope.deleteUserSubmit.allow = true;
        $scope.deleteUserSubmit.submit();
    };
    
    $scope.setupAddUserForm = function(){
        var $form = $('#modal-form');
        var $feedback = $form.find('#feedback');
        validateUser.init($form);
        validateUser.update = false;

        validateUser.$first_name.on('input', function(){
            validateUser.first_name();
        });

        validateUser.$last_name.on('input', function(){
            validateUser.last_name();
        });

        validateUser.$email.on('input', function(){
            validateUser.email();
        });

        validateUser.$username.on('input', function(){
            validateUser.username();
        });

        validateUser.$password.on('input', function(){
            validateUser.password1();
        });

        validateUser.$password2.on('input', function(){
            validateUser.password2();
        });

        $form.find("#submit-button").click(function(event){
            event.preventDefault();
            var cSending = 'form__input--sending';
            var cSent = 'form__input--sent';
            var that = $(this);
            var valid = true;
            var data = {};
            
            if(!validateUser.first_name()){
               valid = false;
            }
            
            if(!validateUser.last_name()){
               valid = false;
            }
            
            if(!validateUser.email()){
               valid = false;
            }
            
            if(!validateUser.username()){
               valid = false;
            }
            
            if(!validateUser.password1()){
               valid = false;
            }
            
            if(!validateUser.password2()){
               valid = false;
            }
            
            if(!validateUser.organisation()){
               valid = false;
            }
            
            if(!validateUser.auth()){
               valid = false;
            }

            if(!valid){
                console.error('Incorrect values found in form data');
                return false;
            }
            
            data.first_name = validateUser.$first_name.val();
            data.last_name = validateUser.$last_name.val();
            data.email = validateUser.$email.val();
            data.username = validateUser.$username.val();
            data.password = validateUser.$password.val();
            data.organisation_id = ''+$scope.organisations.code;
            data.auth = ''+$scope.users.code;

            CiviExchange.create.user(data).then(function(){
                console.info('Successfully added user');
                //validateUser.clearInputs();
                $scope.getUsers(true);
                $scope.modal.close();
            }, function(errorData){
                console.error('Issue adding user', errorData);
                $feedback.html('<p class="error">Issue adding user</p>');
            });
        });
    };
    
    $scope.setupUpdateUserForm = function(id){
        var userId = ''+id;
        var $form = $('#modal-form');
        var $feedback = $form.find('#feedback');
        
        validateUser.init($form);
        validateUser.update = true;

        validateUser.$first_name.on('input', function(){
            validateUser.first_name();
        });

        validateUser.$last_name.on('input', function(){
            validateUser.last_name();
        });

        validateUser.$email.on('input', function(){
            validateUser.email();
        });

        validateUser.$username.on('input', function(){
            validateUser.username($scope.selectedUser.username);
        });

        validateUser.$password.on('input', function(){
            if(validateUser.passwordLength()){
                validateUser.password1();
            }else{
                validateUser.removeError(validateUser.$password);
                validateUser.removeError(validateUser.$password2);
            }
        });

        validateUser.$password2.on('input', function(){
            if(validateUser.passwordLength()){
                validateUser.password2();
            }else{
                validateUser.removeError(validateUser.$password);
                validateUser.removeError(validateUser.$password2);
            }
        });

        $form.find("#submit-button").click(function(event){
            event.preventDefault();
            
            var cSending = 'form__input--sending';
            var cSent = 'form__input--sent';
            var that = $(this);
            var valid = true;
            var data = {};

            if(!validateUser.id(userId)){
               valid = false;
            }

            if(!validateUser.first_name()){
               valid = false;
            }

            if(!validateUser.last_name()){
               valid = false;
            }

            if(!validateUser.email()){
               valid = false;
            }

            if(!validateUser.username($scope.selectedUser.username)){
               valid = false;
            }


            if(validateUser.passwordLength()){
                if(!validateUser.password1()){
                   valid = false;
                }

                if(!validateUser.password2()){
                   valid = false;
                }
            }

            if(!validateUser.organisation()){
               valid = false;
            }

            if(!validateUser.auth()){
               valid = false;
            }

            if(!valid){
                console.error('Incorrect values found in form data');
                return false;
            }

            data.id = userId;
            data.first_name = validateUser.$first_name.val();
            data.last_name = validateUser.$last_name.val();
            data.email = validateUser.$email.val();
            data.username = validateUser.$username.val();
            data.password = validateUser.$password.val();
            data.organisation_id = ''+$scope.organisations.code;
            data.auth = ''+$scope.users.code;
            
            $scope.updateUser = {};
            $scope.updateUser.allow = false;
            $scope.updateUser.submit = function(){
                if($scope.isCurrentUser(data.id) && !$scope.updateUser.allow && 
                   data.username !== $rootScope.authorisedUser.username){
                    $scope.modal.notification('userLogin');
                    return false;
                }

                CiviExchange.update.user(data).then(function(){
                    console.info('Successfully updated user');
                    //validateUser.clearInputs();
                    $scope.getUsers(true);
                    if($scope.isCurrentUser(data.id)){
                        if($scope.updateUser.allow){
                            $rootScope.logout();
                        }else{
                            $rootScope.reloadUser();
                        }
                    }
                    $scope.modal.close();
                }, function(errorData){
                    console.error('Issue updating user', errorData);
                    $feedback.html('<p class="error">Issue updating user</p>');
                });
            };
            
            $scope.updateUser.submit();
        });
    };
    
    $scope.updateCurrentUser = function(){
        $scope.updateUser.allow = true;
        $scope.updateUser.submit();
    };
    
    $scope.setSelectedUser = function(id){
        $scope.selectedUser = CiviExchange.get.user(id);
        $scope.users.code = $scope.selectedUser.auth;
    };
    
    $scope.modal = function(url, pagename){
        $scope.modal.properties = {};
        
        $scope.modal.open = function(url){
            $scope.modal.properties.url = url;
            var html = '<div ng-include="\''+ url +'\'" ng-click="modal.close()" id="modal-blanket" class="modal-blanket"></div>';
            var temp = $compile(html)($scope);
            angular.element(document.getElementById('inner-content')).append('<div id="modal-container"></div>');
            angular.element(document.getElementById('modal-container')).html(temp);
        };
    
        $scope.modal.loadPage = function(pageName){
            $scope.modal.notification('');
            $scope.modal.properties.pageName = pageName;
            $scope.modal.open($scope.modal.properties.url);
        };

        $scope.modal.close = function(){
            $scope.modal.properties = {};
            angular.element(document.getElementById('modal-container')).remove();
        };

        $scope.modal.notification = function(notificationName){
            $scope.modal.properties.notificationName = notificationName;
            $scope.modal.open($scope.modal.properties.url);
        };
        
        if(typeof url === 'string'){
            $scope.modal.properties.url = url;
            if(typeof pagename === 'string'){
                $scope.modal.loadPage(pagename);
            }else{
                $scope.modal.open(url);
            }
        }
    };
    
    if($rootScope.canAccess('/admin') && Authentication.isAdmin()){
        $scope.users.availableAuth.push({value: 1, name: 'Super Administrator'});
        initUsers();
        initOrganisations();
    }else if($rootScope.canAccess('/admin') && Authentication.isOrganisationAdmin()){
        initUsers();
        initOrganisations();
    }
});
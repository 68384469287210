ServicesParentCtrl.controller('DSS-Logs-LogController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            Validate,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            DEX,
                                                            LoadLocalData,
                                                            Graph){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Service > Log';
    $scope.page.header.title = 'Department of Social Services';
    $scope.page.header.backLink = '#!/services/dss/logs';
    $scope.page.sidebar.variant = 'serviceDSS';
    $scope.page.sidebar.selectedNav = 'Transfer Logs';
    
    $scope.logID = $routeParams.logID;
    $scope.selectedLog = {};
    
    $scope.downloadBulkXML = function(xml){
        DEX.download.bulkXML(xml);
    };
    
    $scope.downloadLog = function(data){
        DEX.download.csv(data);
    };
    
    $scope.currentPage = 'log';
    $scope.showPage = function(pageName){
        $scope.currentPage = pageName;                 
    };
    
    $scope.selectedButton = 'Log';
    $scope.setSelectedButton = function(name){
        $scope.selectedButton = name;
    };
        
    $scope.Accordion = {
        expand: {
            element: function($event){
                var $elem = angular.element($event.delegateTarget);

                if($elem.hasClass('expanded')){
                    $elem.removeClass('expanded');
                }else{
                    $elem.addClass('expanded');
                }
            },
            all: function($event){
                var $button = angular.element($event.delegateTarget);
                var $elems = document.querySelectorAll('#sendingContainer .data.expandable');
                var expanded = $button.hasClass('expanded');

                // Switch button state
                if(expanded){
                    $button.removeClass('expanded');
                    $button.text('Show All');
                }else{
                    $button.addClass('expanded');
                    $button.text('Hide All');
                }

                for(var i = 0; i < $elems.length; i++){
                    var $elem = $elems[i];
                    if(expanded){
                        $elem.classList.remove('expanded');
                    }else{
                        $elem.classList.add('expanded');
                    }
                }
            }
        }
    };
    
    $scope.viewLog = function(type){
        if(type === 'validation' && Validate.data.isSet($scope.transferLog.processedData.validation)){
            $scope.selectedLog.type = type;
            $scope.selectedLog.data = $scope.transferLog.processedData.validation;
        }else if(type == 'transfer' && Validate.data.isSet($scope.transferLog.processedData.transfer)){
            $scope.selectedLog.type = type;
            $scope.selectedLog.data = $scope.transferLog.processedData.transfer;
        }
        
        transferStatistics.client.update();
        console.log('Selected Log', $scope.selectedLog);
    };
    
    var loadingTransferLog = Object.create(load);
    var loadTransferLog = function(){
        CiviExchange.get.transferLog({log_id: $scope.logID, withData: 1}).then(function(transferLog){
            loadingTransferLog.remove();
            
            $scope.transferLog = transferLog;
            $scope.transferLog.processedData = {};
            
            console.log('Transfer Log', transferLog);

            if(Validate.data.isSet(transferLog.validation_data)){
                console.log('Validation Data', transferLog.validation_data);
                $scope.transferLog.processedData.validation = JSON.parse(transferLog.validation_data);
                // Alphabetical order by name sort
                transferLog.processedData.validation.transferred.sort(function(a,b) {return (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0);} );

                $scope.viewLog('validation');
            }
            if(Validate.data.isSet(transferLog.transfer_data)){
                $scope.transferLog.processedData.transfer = JSON.parse(transferLog.transfer_data);
                // Alphabetical order by name sort
                transferLog.processedData.transfer.transferred.sort(function(a,b) {return (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0);} );

                if(!Validate.data.isSet($scope.selectedLog)){
                    $scope.viewLog('transfer');
                }
            }
            
            console.log('Data', $scope.transferLog.processedData);
        }, function(errorData){
            var error = 'Issue retrieving log data';
            console.error(error, errorData);
            if(loadingTransferLog.retry('', 'Error recieved ('+ errorData +').')){
                return loadTransferLog();
            }else{
                var retError = '<p class="error">Could not retrieve log. Please contact your administrator if this issue persists.<br>('+ errorData +')</p>';
                console.error('Complete Fail: '+error, errorData);
            }
        });
    };
    
    var transferStatistics = (function(){
        
            var clientGenderPie;
            var clientATSIPie;

            return {
                client: {
                    update: function(){
                        this.gender.generatePie();
                        this.atsi.generatePie();
                    },
                    gender: {
                        generatePie: function(){
                            var data = getClientGenderData();
                            console.log('clientGenderPie', data);

                            /*if(Validate.data.isSet(clientGenderPie)){
                                clientGenderPie.update(data);
                            }else{*/
                                clientGenderPie = Graph.pieChart('clientGenderPie', data);
                            /*}*/
                        }
                    },
                    atsi: {
                        generatePie: function(){
                            var data = getClientATSIData();
                            console.log('clientATSIPie', data);

                            /*if(Validate.data.isSet(clientATSIPie)){
                                clientATSIPie.update(data);
                            }else{*/
                                clientATSIPie = Graph.pieChart('clientATSIPie', data);
                            /*}*/
                        }
                    }
                }
            };
            
            function getClientGenderData(){
                var data = [];
                var genderCount = $scope.selectedLog.data.statistics.clients.data.genderCount;
                
                for(gender in genderCount) {
                    data.push({
                        title: gender,
                        amount: genderCount[gender]
                    });
                }
                
                return data;
            }
            
            function getClientATSIData(){
                var data = [];
                var atsiCount = $scope.selectedLog.data.statistics.clients.data.atsiCount;
                
                for(atsi in atsiCount) {
                    data.push({
                        title: atsi,
                        amount: atsiCount[atsi]
                    });
                }
                
                return data;
            }
        })();
    
    $('.inner-content').append(loadingTransferLog.begin('loadTransferLogs', 'full', 'Retrieving log'));
    loadTransferLog();
    
    
});

// app/js/controllers/HomeCtrl.js
angular.module('DataTransmitter')
    .factory('LoadLocalData', function($q, $http, $timeout, $localStorage, Validate, apiService, DEX, CiviExchange, CiviCRM){
    
    var updateSet = function(update){
        if(update !== true){
            return false;
        }
        return true;
    };
    
    var loadTypeSet = function(loadType){
        if(!Validate.data.isSet(loadType)){
            return 'full';
        }
        return loadType;
    };
    
    return {
        get: {
            clients: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadClients = Object.create(load);
                $('.inner-content').append(loadClients.begin('loadClients', loadType, 'Retrieving CiviCRM Clients'));

                function getClients() {
                    CiviCRM.get.clients(data, update).then(function(clients){
                        loadClients.remove();
                        deferred.resolve(clients);
                    }, function(error){
                        if(loadClients.retry('', 'Error recieved ('+ error +').')){
                            return getClients();
                        }else{
                            loadClients.remove();
                            var retError = '<p class="error">Could not retrieve CiviCRM Clients information. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            console.error('Could not retrieve CiviCRM Clients information', error);
                            deferred.reject(retError);
                        }
                    });
                }

                getClients();
                return deferred.promise;
            },
            
            cases: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadCases = Object.create(load);
                $('.inner-content').append(loadCases.begin('loadCases', loadType, 'Retrieving CiviCRM Cases'));

                function getCases(){
                    CiviCRM.get.cases(data, update).then(function(cases){
                        loadCases.remove();
                        deferred.resolve(cases);
                    }, function(error){
                        if(loadCases.retry('', 'Error recieved ('+ error +').')){
                            return getCases();
                        }else{
                            loadCases.remove();
                            var retError = '<p class="error">Could not retrieve CiviCRM Cases information. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            console.error('Could not retrieve CiviCRM Cases information', error);
                            deferred.reject(retError);
                        }
                    });
                }

                getCases();
                return deferred.promise;
            },
            
            sessions: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadSessions = Object.create(load);
                $('.inner-content').append(loadSessions.begin('loadSessions', loadType, 'Retrieving CiviCRM Sessions'));

                function getSessions() {
                    CiviCRM.get.sessions(data, update).then(function(sessions){
                        loadSessions.remove();
                        deferred.resolve(sessions);
                    }, function(error){
                        if(loadSessions.retry('', 'Error recieved ('+ error +').')){
                            return getSessions();
                        }else{
                            loadSessions.remove();
                            var retError = '<p class="error">Could not retrieve CiviCRM Sessions information. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            deferred.reject(retError);
                        }
                    });
                }

                getSessions();
                return deferred.promise;
            },
            
            externalReferenceData: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadExternalData = Object.create(load);
                $('.inner-content').append(loadExternalData.begin('loadExternalData', loadType, 'Retrieving external data'));

                function getExternalReferenceData(){
                    DEX.get.externalReferenceData(data, update).then(function(referenceData){
                        loadExternalData.remove();
                        deferred.resolve(referenceData);
                    }, function(error){
                        if(loadExternalData.retry('', 'Error recieved ('+ error +').')){
                            return getExternalReferenceData();
                        }else{
                            loadExternalData.remove();
                            var retError = '<p class="error">Could not retrieve Reference Data from DSS. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            console.error('Could not retrieve Reference Data from DSS', error);
                            deferred.reject(retError);
                        }
                    });
                }

                getExternalReferenceData();
                return deferred.promise;
            },
            
            localReferenceData: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadLocalData = Object.create(load);
                $('.inner-content').append(loadLocalData.begin('loadLocalData', loadType, 'Retrieving local reference data'));

                function getLocalReferenceData() {
                    DEX.get.localReferenceData(data, update).then(function(referenceData){
                        loadLocalData.remove();
                        deferred.resolve(referenceData);
                    }, function(error){
                        if(loadLocalData.retry('', 'Error recieved ('+ error +').')){
                            return getLocalReferenceData();
                        }else{
                            loadLocalData.remove();
                            var retError = '<p class="error">Could not retrieve Reference Data. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            console.error('Could not retrieve Reference Data', error);
                            deferred.reject(retError);
                        }
                    });
                }

                getLocalReferenceData();
                return deferred.promise;
            },
            
            users: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadUsers = Object.create(load);
                $('.inner-content').append(loadUsers.begin('loadUsers', loadType, 'Retrieving Users'));
                console.log('Users Update', update);
                function getUsers(){
                    CiviExchange.get.users(update).then(function(users){
                        loadUsers.remove();
                        deferred.resolve(users);
                    }, function(error){
                        if(loadUsers.retry('', 'Error recieved ('+ error +').')){
                            return getUsers();
                        }else{
                            loadUsers.remove();
                            var retError = '<p class="error">Could not retrieve Users. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            console.error('Could not retrieve Users', error);
                            deferred.reject(retError);
                        }
                    });
                }

                getUsers();
                return deferred.promise;
            },
            
            organisations: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadOrganisations = Object.create(load);
                $('.inner-content').append(loadOrganisations.begin('loadOrganisations', loadType, 'Retrieving Organisations'));
                console.log('Org Update', update);
                function getOrganisations(){
                    CiviExchange.get.organisations(update).then(function(organisations){
                        loadOrganisations.remove();
                        deferred.resolve(organisations);
                    }, function(error){
                        if(loadOrganisations.retry('', 'Error recieved ('+ error +').')){
                            return getOrganisations();
                        }else{
                            loadOrganisations.remove();
                            var retError = '<p class="error">Could not retrieve Organisations. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            console.error('Could not retrieve Organisations', error);
                            deferred.reject(retError);
                        }
                    });
                }

                getOrganisations();
                return deferred.promise;
            },
            
            dexUser: function(data, update, loadType){
                update = updateSet(update);
                loadType = loadTypeSet(loadType);
                
                var deferred = $q.defer();
                var loadDexUser = Object.create(load);
                $('.inner-content').append(loadDexUser.begin('loadDexUser', loadType, 'Retrieving DEX user information'));

                function getDexUser(){
                    DEX.get.user(data, update).then(function(userData){
                        loadDexUser.remove();
                        deferred.resolve(userData);
                    }, function(error){
                        if(loadDexUser.retry('', 'Error recieved ('+ error +').')){
                            return getDexUser();
                        }else{
                            loadDexUser.remove();
                            var retError = '<p class="error">Could not retrieve DEX user information. Please contact your administrator if this issue persists.<br>('+ error +')</p>';

                            console.error('Could not retrieve DEX user information', error);
                            deferred.reject(retError);
                        }
                    });
                }

                getDexUser();
                return deferred.promise;
            }
        }
    };
});

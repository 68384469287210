ServicesParentCtrl.controller('DSS-StatsController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            DEX,
                                                            LoadLocalData){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'CiviExchange > Service > Statistics';
    $scope.page.header.title = 'Department of Social Services';
    $scope.page.header.backLink = '#!/services';
    $scope.page.sidebar.variant = 'serviceDSS';
    $scope.page.sidebar.selectedNav = 'Statistics';
});

AdminParentCtrl.controller('OrganisationController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            $compile,
                                                            Validate,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            LoadLocalData,
                                                            InputValidation){
    if(!$scope.isAdmin()){
        $location.path('/admin/accounts/'+ $routeParams.organisationID +'/users');
    }
    
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.variant = 'organisationAdmin';
    $scope.page.header.breadcrumbs = 'Organisation Administration';
    $scope.page.header.title = '';
    $scope.page.header.backLink = '#!/admin/accounts';
    $scope.page.sidebar.variant = 'organisationAdmin';
    $scope.page.sidebar.selectedNav = 'CiviCRM';
    $scope.organisationID = $routeParams.organisationID;
    
    $scope.usersLoaded = false;
    $scope.organisationsLoaded = false;
    $scope.users = {
        code: 0,
        availableAuth: [
            {value: 0, name: 'User'},
            {value: 2, name: 'Organisation Administrator'}
        ],
    };
    
    $scope.checkOrganisation = {
        civiURL:{
            defined: function(){
                return Validate.data.isSet($rootScope.currentOrganisation.civiURL);
            }
        },
        settings:{
            defined: function(){
                return Validate.data.isSet($rootScope.currentOrganisation.settings);
            }
        }
    };
    
    $scope.inputValues= {
        civiCRMSettings: {
            convertCountry: false
        }
    };
    
    var setCurrentOrganisation = function(id, update){
        LoadLocalData.get.organisations('', update).then(function(organisations){
            $rootScope.currentOrganisation = CiviExchange.get.organisation(id);
            $scope.page.header.title = $rootScope.currentOrganisation.name;
            $scope.inputValues.civiCRMSettings.convertCountry = $rootScope.currentOrganisation.settings.convert_country == 0 ? false : true;

            console.log("Organisation", $rootScope.currentOrganisation);
        }, function(error){
            $scope.referenceData = error;
        });
    };
    
    var initOrganisations = function(){
        $scope.organisations = {
            code: '',
            availableOrganisations: [],
        };
        
        setCurrentOrganisation($scope.organisationID);
    };
    
    var validateOrganisation = {
        update: false,
        $organisationForm: null,
        $organisationName: null,
        $civiURL: null,
        init: function($elem){
            this.$organisationForm = $elem;
            this.$organisationName = this.$organisationForm.find('#name .form__input');
            this.$civiURL = this.$organisationForm.find('#civiURL .form__input');
        },
        clearInputs: function(){
            this.$organisationName.val('');
            this.$civiURL.val('');
        },
        id: function(id){
            if(InputValidation.length(id)){
                if(InputValidation.isMaxLength(id, 10)){
                    return true;
                }
            }
            return false;
        },
        name: function(currentOrg){
            var organisationName = this.$organisationName.val();
            if(InputValidation.length(organisationName, this.$organisationName, 'Please enter a Name')){
                if(InputValidation.isMaxLength(organisationName, 255, this.$organisationName, 'Too long')){
                    if(typeof currentOrg !== 'string'){
                        currentOrg = '';
                    }
                    if(!InputValidation.organisationNameExists(organisationName, this.$organisationName, 'Name already exists', currentOrg)){
                        return true;
                    }
                }
            }
            return false;
        },
        civiURLLength: function(){
            var civiURL = this.$civiURL.val();
            if(InputValidation.length(civiURL)){
                return true;
            }
            return false;
        },
        civiURL: function(){
            var civiURL = this.$civiURL.val();
            if(InputValidation.length(civiURL, this.$civiURL, 'Please enter a URL')){
                if(InputValidation.isURL(civiURL, this.$civiURL, 'Please enter a valid URL')){
                    return true;
                }
            }
            return false;
        }
    };
    
    var validateOrganisationSettings = {
        update: false,
        $organisationForm: null,
        $organisationName: null,
        $convertCountry: null,
        init: function($elem){
            this.$organisationForm = $elem;
            this.$convertCountry = this.$organisationForm.find('#convertCountry .form__input');
        },
        clearInputs: function(){
        },
        id: function(id){
            if(InputValidation.length(id)){
                if(InputValidation.isMaxLength(id, 10)){
                    return true;
                }
            }
            return false;
        },
        convertCountry: function(){
            var convertCountry = $scope.inputValues.civiCRMSettings.convertCountry;
            if(InputValidation.isBool(convertCountry, this.$convertCountry, 'Please enter a valid')){
                return true;
            }
            return false;
        }
    };
    
    $scope.setupUpdateCiviCRMForm = function(id){
        var orgId = id;
        var $form = $('#modal-form');
        var $feedback = $form.find('#feedback');
        validateOrganisation.init($form);
        validateOrganisation.update = true;

        validateOrganisation.$civiURL.on('input', function(){
            validateOrganisation.civiURL();
        });

        $form.find("#submit-button").click(function(event){
            event.preventDefault();
            var cSending = 'form__input--sending';
            var cSent = 'form__input--sent';
            var that = $(this);
            var valid = true;
            var data = {};
            
            if(!validateOrganisation.id(orgId)){
               valid = false;
            }
            
            if(validateOrganisation.civiURLLength()){
                if(!validateOrganisation.civiURL()){
                   valid = false;
                }else{
                    data.civiURL = validateOrganisation.$civiURL.val();
                }
            }
            
            if(!valid){
                console.error('Incorrect values found in form data');
                return false;
            }
            
            data.id = orgId;
            data.name = validateOrganisation.$organisationName.val();
            
            CiviExchange.update.civicrm(data).then(function(){
                console.info('Successfully updated CiviCRM');
                //validateOrganisation.clearInputs();
                setCurrentOrganisation($scope.organisationID, true);
                $scope.modal.close();
            }, function(errorData){
                console.error('Issue updating CiviCRM', errorData);
                $feedback.html('<p class="error">Issue updating CiviCRM</p>');
            });
        });
    };
    
    $scope.setupUpdateCiviCRMSettingsForm = function(id){
        var orgId = id;
        var $form = $('#modal-form');
        var $feedback = $form.find('#feedback');
        validateOrganisationSettings.init($form);
        validateOrganisationSettings.update = true;

        validateOrganisationSettings.$convertCountry.on('input', function(){
            validateOrganisationSettings.convertCountry();
        });

        $form.find("#submit-button").click(function(event){
            event.preventDefault();
            var cSending = 'form__input--sending';
            var cSent = 'form__input--sent';
            var that = $(this);
            var valid = true;
            var data = {};
            
            if(!validateOrganisationSettings.id(orgId)){
               valid = false;
            }
            
            if(!validateOrganisationSettings.convertCountry()){
                valid = false;
            }else{
                if($scope.inputValues.civiCRMSettings.convertCountry){
                    data.convertCountry = 1;
                }else{
                    data.convertCountry = 0;
                }
            }
            
            if(!valid){
                console.error('Incorrect values found in form data');
                return false;
            }
            
            data.id = orgId;
            
            CiviExchange.update.civicrmSettings(data).then(function(){
                console.info('Successfully updated CiviCRM Settings');
                //validateOrganisation.clearInputs();
                setCurrentOrganisation($scope.organisationID, true);
                $scope.modal.close();
            }, function(errorData){
                console.error('Issue updating CiviCRM Settings', errorData);
                $feedback.html('<p class="error">Issue updating CiviCRM Settings</p>');
            });
        });
    };
    
    $scope.modal = function(url, pagename){
        $scope.modal.properties = {};
        
        $scope.modal.open = function(url){
            $scope.modal.properties.url = url;
            var html = '<div ng-include="\''+ url +'\'" ng-click="modal.close()" id="modal-blanket" class="modal-blanket"></div>';
            var temp = $compile(html)($scope);
            angular.element(document.getElementById('inner-content')).append('<div id="modal-container"></div>');
            angular.element(document.getElementById('modal-container')).html(temp);
        };
    
        $scope.modal.loadPage = function(pageName){
            $scope.modal.properties.pageName = pageName;
            $scope.modal.open($scope.modal.properties.url);
        };

        $scope.modal.close = function(){
            $scope.modal.properties = {};
            angular.element(document.getElementById('modal-container')).remove();
        };

        $scope.modal.notification = function(notificationName){
            $scope.modal.properties.notificationName = notificationName;
            $scope.modal.open($scope.modal.properties.url);
        };
        
        if(typeof url === 'string'){
            $scope.modal.properties.url = url;
            if(typeof pagename === 'string'){
                $scope.modal.loadPage(pagename);
            }else{
                $scope.modal.open(url);
            }
        }
    };
    
    if($rootScope.canAccess('/admin') && Authentication.isAdmin()){
        initOrganisations();
    }else if($rootScope.canAccess('/admin') && Authentication.isOrganisationAdmin()){
        initOrganisations();
    }
    
});
//! All code and conventions are protected by copyright

/*!
    Owner Information
    Name:   CiviAssist IP
*/

/*!
    Developer Information
    Name:   Geoffrey McIntyre
    Email:  geoffreymcintyre89@gmail.com
    Date:   16/08/16
*/

// app/js/app.js
angular.module('DataTransmitter', ['ngRoute', 'appRoutes', 'Service', 'LoginCtrl', 'AdminParentCtrl', 'ServicesParentCtrl', 'LoadingCtrl', 'apiService']);

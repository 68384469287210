// app/js/controllers/HomeCtrl.js
Service.factory("DEXReferenceData", function DEXReferenceData($q, $http, $timeout, $localStorage, Validate, apiService, FORMATTER){
    var externalReferenceData = null;
    var localReferenceData = null;
    var mismatch = [];
    var totalMismatch = 0;

    var formatCompare = function(index, data, datasec){
        var foundMismatch = false;
        var mismatchCount = 0;
        var html = "";
        var finalHtml = "";

        finalHtml += "<div class=\"table_title\"><h2>"+ index +"</h2>";
        html += "<table>";
        html += "<tr>";
        html += "<th>";
        html += "<h3>Code</h3>";
        html += "</th>";
        html += "<th>";
        html += "<h3>Description</h3>";
        html += "</th>";
        html += "<th>";
        html += "<h3>Order Number</h3>";
        html += "</th>";
        html += "<th>";
        html += "<h3>Code Type</h3>";
        html += "</th>";
        html += "<th>";
        html += "<h3>Updated Date Time</h3>";
        html += "</th>";
        html += "</tr>";

        for(var newIndex in data){
            var value = data[newIndex];
            var valuesec = datasec[newIndex];
            html += "<tr>";
            html += "<td>";
            html += "<p>"+ value.Code +"</p>";
            if(value.Code !== valuesec.Code){
                foundMismatch = true;
                mismatchCount++;
                html += "<p class=\"difference\">"+ valuesec.Code +"</p>";
            }
            html += "</td>";
            html += "<td>";
            html += "<p>"+ value.Description +"</p>";
            if(value.Description !== valuesec.Description){
                foundMismatch = true;
                mismatchCount++;
                html += "<p class=\"difference\">"+ valuesec.Description +"</p>";
            }
            html += "</td>";
            html += "<td>";
            html += "<p>"+ value.OrderNumber +"</p>";
            if(value.OrderNumber != valuesec.OrderNumber){
                foundMismatch = true;
                mismatchCount++;
                html += "<p class=\"difference\">"+ valuesec.OrderNumber +"</p>";
            }
            html += "</td>";
            html += "<td>";
            html += "<p>"+ value.CodeType +"</p>";
            if(value.CodeType !== valuesec.CodeType){
                foundMismatch = true;
                mismatchCount++;
                html += "<p class=\"difference\">"+ valuesec.v +"</p>";
            }
            html += "</td>";
            html += "<td>";
            html += "<p>"+ value.UpdatedDateTime +"</p>";
            if(value.UpdatedDateTime !== valuesec.UpdatedDateTime){
                foundMismatch = true;
                mismatchCount++;
                html += "<p class=\"difference\">"+ valuesec.UpdatedDateTime +"</p>";
            }
            html += "</td>";
            html += "</tr>";
        }

        html += "</table>";

        if(foundMismatch){
            finalHtml += "<p class=\"difference\">Mismatches Found "+ mismatchCount +"</p>";
            mismatch.push(index);
        }

        finalHtml += "</div>";
        finalHtml += html;

        return finalHtml;
    };
    
    var formatCompareData = function(index, data, datasec){
        var foundMismatch = false;
        var mismatchCount = 0;
        
        var comparedData = [];

        for(var newIndex in data){
            var value = data[newIndex];
            var valuesec = {
                Code: '',
                Description: '',
                OrderNumber: '',
                CodeType: '',
                UpdatedDateTime: '',
            };
            
            comparedData[newIndex] = {};
            comparedData[newIndex].mismatch = {};
            comparedData[newIndex].Code = value.Code;
            comparedData[newIndex].Description = value.Description;
            comparedData[newIndex].OrderNumber = value.OrderNumber;
            comparedData[newIndex].CodeType = value.CodeType;
            comparedData[newIndex].UpdatedDateTime = value.UpdatedDateTime;
            
            for(var index in datasec){
                var testDataSec = datasec[index];
                if(value.Code === testDataSec.Code){
                    valuesec = testDataSec;
                }
            }
            
            //console.log(value, valuesec);
            
            if(valuesec.Code === ''){
                mismatchCount++;
                comparedData[newIndex].mismatch.Code = 'No local value found';
            }else if(value.Code !== valuesec.Code){
                mismatchCount++;
                comparedData[newIndex].mismatch.Code = valuesec.Code;
            }
            
            if(valuesec.Description === ''){
                mismatchCount++;
                comparedData[newIndex].mismatch.Description = 'No local value found';
            }else if(value.Description !== valuesec.Description){
                mismatchCount++;
                comparedData[newIndex].mismatch.Description = valuesec.Description;
            }
            
            if(valuesec.OrderNumber === ''){
                mismatchCount++;
                comparedData[newIndex].mismatch.OrderNumber = 'No local value found';
            }else if(value.OrderNumber+'' !== valuesec.OrderNumber){
                mismatchCount++;
                comparedData[newIndex].mismatch.OrderNumber = valuesec.OrderNumber;
            }
            
            if(valuesec.CodeType === ''){
                mismatchCount++;
                comparedData[newIndex].mismatch.CodeType = 'No local value found';
            }else if(value.CodeType !== valuesec.CodeType){
                mismatchCount++;
                comparedData[newIndex].mismatch.CodeType = valuesec.CodeType;
            }
            
            if(valuesec.UpdatedDateTime === ''){
                mismatchCount++;
                comparedData[newIndex].mismatch.UpdatedDateTime = 'No local value found';
            }else if(value.UpdatedDateTime !== valuesec.UpdatedDateTime){
                mismatchCount++;
                comparedData[newIndex].mismatch.UpdatedDateTime = valuesec.UpdatedDateTime;
            }
            
        }
        
        totalMismatch += mismatchCount;
        comparedData.totalMismatches = mismatchCount;

        return comparedData;
    };

    return {
        getExternalReferenceData: function(data, update){
            var deferred = $q.defer();
            var error = 'Get External DEX Reference Data: Issue retrieving external DEX reference data';
            
            if(!Validate.data.isSet(data) || 
               !Validate.data.isSet(data.code)){
                data += {code: ''};
            }
            
            if(!Validate.data.isSet(externalReferenceData) || 
               !Validate.data.isSet(externalReferenceData[data.code]) || update === true){
                
                var actions;
                if(Validate.data.isSet(externalReferenceData) && 
                   Validate.data.isSet(externalReferenceData[data.code])){
                    deferred.resolve(externalReferenceData[data.code]);
                    return deferred.promise;
                }else if(Validate.data.isSet(data.code)){
                    actions = {action: 'getReferenceData', ReferenceDataCode: data.code};
                }else{
                    actions = {action: 'getReferenceData', ReferenceDataCode: 'All'};
                }
                
                apiService.post(actions, true).then(function(data){
                    var output = FORMATTER.process(data);
                    
                    if(data.response.success){
                        if(externalReferenceData !== null){
                            externalReferenceData = $.extend(externalReferenceData, data.referenceData);
                        }else{
                            externalReferenceData = data.referenceData;
                        }
                        
                        // Sort alphabetically
                        /*var temp = {};
                        Object.keys(externalReferenceData).sort().forEach(function(v, i) {
                            temp[v] = externalReferenceData[v];
                        });
                        externalReferenceData = temp;*/
                        
                        for(var index in externalReferenceData){
                            externalReferenceData[index].sort(function(a,b) {
                                return (a.Code.toLowerCase() > b.Code.toLowerCase()) ? 1 : ((b.Code.toLowerCase() > a.Code.toLowerCase()) ? -1 : 0);
                            });
                        }
                        
                        deferred.resolve(data);
                    }else if(!data.response.success){
                        console.error(error, output);
                        deferred.reject(error);
                    }
                }, function(errorData){
                    var output = '';
                    if(Validate.data.isSet(errorData.responseText)){
                        output = FORMATTER.process(errorData);
                    }
                    
                    console.error(error, errorData);
                    deferred.reject(output);
                });
            }else{
                if(Validate.data.isSet(data.code) && Validate.data.isSet(externalReferenceData[data.code])){
                    var refData = {referenceData: {}};
                    refData.referenceData[data.code] = externalReferenceData[data.code];
                    deferred.resolve(refData);
                }else{
                    deferred.resolve(externalReferenceData);
                }
            }

            return deferred.promise;
        },

        getLocalReferenceData: function(data, update){
            var deferred = $q.defer();
            var error = 'Get Local DEX Reference Data: Issue retrieving local DEX reference data';
            
            if(!Validate.data.isSet(data) || 
               !Validate.data.isSet(data.code)){
                data += {code: ''};
            }
            
            if(!Validate.data.isSet(localReferenceData) || !Validate.data.isSet(localReferenceData[data.code]) || update === true){
                var actions;
                if(Validate.data.isSet(localReferenceData) && 
                   Validate.data.isSet(localReferenceData[data.code])){
                    deferred.resolve(localReferenceData[data.code]);
                    return deferred.promise;
                }else if(Validate.data.isSet(data.code)){
                    actions = {action: 'getLocalReferenceData', referenceDataCode: data.code};
                }else{
                    actions = {action: 'getLocalReferenceData', code: 'All'};
                }

                apiService.post(actions, true).then(function(data){
                    var output = FORMATTER.process(data);
                    
                    if(data.response.success){
                        if(localReferenceData !== null){
                            localReferenceData = $.extend(localReferenceData, data.referenceData);
                        }else{
                            localReferenceData = data.referenceData;
                        }
                        
                        // Sort alphabetically
                        /*var temp = {};
                        Object.keys(localReferenceData).sort().forEach(function(v, i) {
                            temp[v] = localReferenceData[v];
                        });
                        localReferenceData = temp;*/
                        for(var index in localReferenceData){
                            localReferenceData[index].sort(function(a,b) {
                                return (a.Code.toLowerCase() > b.Code.toLowerCase()) ? 1 : ((b.Code.toLowerCase() > a.Code.toLowerCase()) ? -1 : 0);
                            });
                        }
                        
                        deferred.resolve(localReferenceData);
                    }else if(!data.response.success){
                        console.error(error, data);
                        deferred.reject(error);
                    }
                }, function(errorData){
                    var output = '';
                    if(Validate.data.isSet(errorData.responseText)){
                        output = FORMATTER.process(errorData.responseText);
                    }
                    
                    console.error(error, errorData);
                    deferred.reject(output);
                });
            }else{
                if(Validate.data.isSet(data.code) && Validate.data.isSet(localReferenceData[data.code])){
                    var refData = {referenceData: {}};
                    refData.referenceData[data.code] = localReferenceData[data.code];
                    deferred.resolve(refData);
                }else{
                    deferred.resolve(localReferenceData);
                }
            }

            return deferred.promise;
        },

        getCompareFormatted: function(code){
            totalMismatch = 0;
            var comparedData = {};
            var attr = null;
            var attrsec = null;
            
            if(Validate.data.isSet(code)){
                if (externalReferenceData.hasOwnProperty(code) && localReferenceData.hasOwnProperty(code)) {
                    //localReferenceData[code].sort(function(a,b) {return (a.LastName.toUpperCase() > b.LastName.toUpperCase()) ? 1 : ((b.LastName.toUpperCase() > a.LastName.toUpperCase()) ? -1 : 0);} );
                    //localReferenceData[code].sort(function(a,b) {return (a.LastName.toUpperCase() > b.LastName.toUpperCase()) ? 1 : ((b.LastName.toUpperCase() > a.LastName.toUpperCase()) ? -1 : 0);} );
                    attr = externalReferenceData[code];
                    attrsec = localReferenceData[code];

                    comparedData[code] = formatCompareData(code, attr, attrsec);
                }
            }else{
                for(var index in localReferenceData){
                    if (externalReferenceData.hasOwnProperty(index) && localReferenceData.hasOwnProperty(index)) {
                        attr = externalReferenceData[index];
                        attrsec = localReferenceData[index];
                        
                        comparedData[index] = formatCompareData(index, attr, attrsec);
                    }
                }
            }
            
            return comparedData;
        },

        updateMismatch: function(){
            var deferred = $q.defer();

            for(var i = 0; i < mismatch.length; i++){
                deferred.resolve(this.update(mismatch[i]));
            }

            mismatch = [];
            return deferred.promise;
        },

        update: function(code){
            var deferred = $q.defer();
            var error = 'Update DEX Reference Data: Issue updating reference data';

            apiService.post({action: 'updateReferenceData', ReferenceDataCode: code}, true).then(function(data){
                console.log('Updated Data', data);
                var output = FORMATTER.process(data);
                
                if(data.response.success){
                    if(localReferenceData !== null){
                        localReferenceData = $.extend(localReferenceData, data.referenceData);
                    }else{
                        localReferenceData = data.referenceData;
                    }
                    deferred.resolve(data);
                }else if(!data.response.success){
                    console.error(error, data);
                    deferred.reject(error);
                }
            }, function(errorData){
                var output = '';
                if(Validate.data.isSet(errorData.responseText)){
                    output = FORMATTER.process(errorData);
                }
                
                console.error(error, errorData);
                deferred.reject(output);
            });

            return deferred.promise;
        },

        mismatchFound: function(){
            return totalMismatch > 0;
        },

        mismatchTotalAmount: function(){
            return totalMismatch;
        },
        
        clear: function(){
            externalReferenceData = null;
            localReferenceData = null;
            mismatch = [];
        }

    };
});

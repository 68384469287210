// app/js/controllers/HomeCtrl.js
angular.module('DataTransmitter')
    .factory("Graph", function Graph($q, $http, $timeout, $localStorage){
    
    var color = d3.scaleOrdinal()
	                   .range([
                           "#FFCA01",
                           "#F56C4E",
                           "#838690",
                           "#FF7601",
                           "#03DEE8",
                           "#2705FF"
                       ]);
    
    // function to handle pieChart.
    function pieChart(id, data){
        var pC = {};
        var width = 300,
            height = 300,
            // Think back to 5th grade. Radius is 1/2 of the diameter. What is the limiting factor on the diameter? Width or height, whichever is smaller 
            radius = Math.min(width, height) / 2;
        
        var pie = d3.pie().value(function(d){
            return d.amount;
        })(data);
        
        var arc = d3.arc()
                    .outerRadius(radius - 10)
                    .innerRadius(0);

        var labelArc = d3.arc()
                        .outerRadius(radius - 40)
                        .innerRadius(radius - 40);
        
        var svg = d3.select(id)
                    .append("svg")
                    .attr("width", width)
                    .attr("height", height)
                        .append("g")
                        .attr("transform", "translate(" + width/2 + "," + height/2 +")"); // Moving the center point. 1/2 the width and 1/2 the height
        
        var g = svg.selectAll("arc")
                    .data(pie)
                    .enter().append("g")
                    .attr("class", "arc");
        
        g.append("path")
            .attr("d", arc)
            .style("fill", function(d) { return color(d.data.title);});
        
        pC.update = function(data){
            pie = d3.pie().value(function(d){
                return d.amount;
            })(data);
            
            svg.selectAll("path").data(pie).transition().duration(100)
                .attrTween("d", arcTween);
        };
        
        // Animating the pie-slice requiring a custom function which specifies
        // how the intermediate paths should be drawn.
        function arcTween(a) {
            var i = d3.interpolate(this._current, a);
            this._current = i(0);
            return function(t) { return arc(i(t));    };
        }
        
        return pC;
    }
    /*function updatePieChart(id, data){
        var container = document.querySelectorAll(id)[0];
        var svg = container.querySelectorAll('svg')[0];
        container.removeChild(svg);
        
        pieChart(id, data);
    }*/
    
    // function to handle legend.
    function legend(id, data){
        var leg = {};
            
        // create table for legend.
        var legend = d3.select(id).append("table").attr('class','legend');
        
        // create one row per segment.
        var tr = legend.append("tbody").selectAll("tr").data(data).enter().append("tr");
            
        // create the first column for each segment.
        tr.append("td").append("svg").attr("width", '16').attr("height", '16').append("rect")
            .attr("width", '16').attr("height", '16')
			.attr("fill",function(d){ return color(d.title); });
            
        // create the second column for each segment.
        tr.append("td").text(function(d){ return d.title;});

        // create the third column for each segment.
        tr.append("td").attr("class",'legendFreq')
            .text(function(d){ return d3.format(",")(d.amount);});

        // create the fourth column for each segment.
        tr.append("td").attr("class",'legendPerc')
            .text(function(d){ return getLegend(d,data);});

        // Utility function to be used to update the legend.
        leg.update = function(nD){
            // update the data attached to the row elements.
            var l = legend.select("tbody").selectAll("tr").data(nD);

            // update the frequencies.
            l.select(".legendFreq").text(function(d){ return d3.format(",")(d.amount);});

            // update the percentage column.
            l.select(".legendPerc").text(function(d){ return getLegend(d,nD);});        
        }
        
        function getLegend(d,aD){ // Utility function to compute percentage.
            return d3.format("%")(d.amount/d3.sum(aD.map(function(v){ return v.amount; })));
        }

        return leg;
    }
    
    return {        
        pieChart: function(parentElementID, data){
            if(typeof document.getElementById(parentElementID) === 'undefined'){
                return;
            }
            
            // Clear element contents
            document.getElementById(parentElementID).innerHTML = '';
            
            return {
                pie: pieChart('#'+parentElementID, data), // create the pie-chart.
                pieLeg: legend('#'+parentElementID, data),  // create the legend.
                update: function(data){
                    this.pie.update(data);
                    this.pieLeg.update(data);
                }
            };
        }
    };
});

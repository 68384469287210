// app/js/controllers/HomeCtrl.js
angular.module('DataTransmitter')
    .factory('Modaler', function($q, $http, $timeout, $compile, $localStorage, $scope){
    
    $scope.modal.properties = {};
    
    $scope.modal.loadPage = function(pageName){
        $scope.modal.properties.pageName = pageName;
        $scope.compileModal($scope.modal.properties.url);
    };
    
    $scope.modal.closeModal = function(){
        $scope.modal.properties = {};
        angular.element(document.getElementById('modal-container')).remove();
    };

    return {
        open: function(data){
            $scope.modal.properties = {};
            $scope.modal.properties = data;
            
            if(typeof data.url === 'string'){
                var html = '<div ng-include="\''+ data.url +'\'" ng-click="modal.closeModal()" id="modal-blanket" class="modal-blanket"></div>';
                var temp = $compile(html)($scope);
                angular.element(document.getElementById('inner-content')).append('<div id="modal-container">'+ temp +'</div>');
            }
        },
        
        close: function(){
            $scope.modal.closeModal();
        },
        
        loadPage: function(pageName){
            $scope.modal.loadPage(pageName);
        }
    };
});

// app/js/controllers/HomeCtrl.js
LoginCtrl.controller('SetupRequiredController', function($rootScope, 
                                                            $scope, 
                                                            $route,
                                                            $routeParams,
                                                            $location, 
                                                            $localStorage, 
                                                            $sessionStorage, 
                                                            $window, 
                                                            Authentication, 
                                                            Application, 
                                                            RouteFilter){
    $rootScope.pageType = 'CiviExchange > Organisation Setup';
    $scope.pageTitle = $rootScope.authorisedUser.organisation.name;
    $rootScope.selectedNav = '';
    $scope.headerVariant = '';
    $scope.sidebarVariant = '';
});

// app/js/controllers/HomeCtrl.js
Service.factory("CiviExchange", function CiviExchange($q, $http, $timeout, $localStorage, Validate, apiService, CiviExchangeUsers, CiviExchangeOrganisations){
    var dataSet = function(data){
        if(!Validate.data.isSet(data)){
            console.error('CiviExchange API: Data is required');
            return false;
        }
        return true;
    };
    
    var updateSet = function(update){
        if(update !== true){
            return false;
        }
        return true;
    };
    
    return {            
        get: {
            user: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeUsers.getUser(data);
            },
            users: function(update){
                update = updateSet(update);
                console.log('Users Update', update);
                return CiviExchangeUsers.getUsers(update);
            },
            organisation: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.getOrganisation(data);
            },
            organisations: function(update){
                update = updateSet(update);
                console.log('Org Update', update);
                return CiviExchangeOrganisations.getOrganisations(update);
            },
            organisationUsers: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeUsers.getOrganisationUsers(data);
            },
            transferLog: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.getTransferLog(data);
            },
            transferLogs: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.getTransferLogs(data);
            },
            activityLogs: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.getActivityLogs(data);
            }
        },
        
        create: {
            user: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeUsers.createNewUser(data);
            },
            organisation: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.createNewOrganisation(data);
            },
            service: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.createOrganisationService(data);
            },
            outlet: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.createOrganisationServiceOutlet(data);
            },
            transferLog: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.storeTransferLog(data);
            },
            outletRule: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.createOutletRule(data);
            }
        },
        
        update: {
            user: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeUsers.updateUser(data);
            },
            organisation: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.updateOrganisation(data);
            },
            service: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.updateOrganisationService(data);
            },
            outlet: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.updateOrganisationServiceOutlet(data);
            },
            civicrm: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.updateCiviCRM(data);
            },
            civicrmSettings: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.updateCiviCRMSettings(data);
            }
        },
        
        validate: {
            organisation: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.validateOrganisation(data);
            }
        },
        
        disable: {
            user: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeUsers.disableUser(data);
            },
            organisation: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.disableOrganisation(data);
            }
        },
        
        delete: {
            user: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeUsers.deleteUser(data);
            },
            organisation: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.deleteOrganisation(data);
            },
            service: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.deleteOrganisationService(data);
            },
            outlet: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.deleteOrganisationServiceOutlet(data);
            },
            outletRule: function(data){
                if(!dataSet(data)){
                    return false;
                }
                return CiviExchangeOrganisations.deleteOutletRule(data);
            }
        },
        
        clearAll: function(){
            CiviExchangeUsers.clear();
            CiviExchangeOrganisations.clear();
        }
    };
});

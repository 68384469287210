// app/js/controllers/HomeCtrl.js
angular.module('DataTransmitter').factory("queue", function queue($q, $http, $timeout, $localStorage) {
        
    var queues = [];

    // function addQueue(queueId) {
    //     var foundQueue = getQueue(queueId);

    //     if (!foundQueue) {
    //         queues.push({
    //             id: queueId,
    //             items: []
    //         });
    //     }
    // }

    function getQueueIndex(queueId) {
        for (var i = 0; i < queues.length - 1; i++){
            if (queues[i].id == queueId) {
                return i;
            }
        }

        return queues.push({
            id: queueId,
            items: []
        }) - 1;
    }

    function getQueue(queueId) {
        return queues[getQueueIndex(queueId)];
    }

    function removeQueue(queueId) {
        queues[getQueueIndex(queueId)].items = [];
    }
    
    return {
        enqueue: function enqueue(queueId, element){
            var foundQueue = getQueue(queueId);

            if (foundQueue) {
                foundQueue.items.push(element);
            }
        },
        dequeue: function enqueue(queueId){
            var foundQueue = getQueue(queueId);
            return foundQueue.items.shift();
        },
        length: function length(queueId){
            var foundQueue = getQueue(queueId);
            return foundQueue.items.length;
        },
        isEmpty: function isEmpty(queueId){
            var foundQueue = getQueue(queueId);
            return foundQueue.items.length == 0;
        },
        getFront: function getFront(queueId){
            var foundQueue = getQueue(queueId);
            return !this.isEmpty(queueId) ? foundQueue.items[0] : undefined;
        },
        clear: function clear(queueId){
            removeQueue(queueId);
        }
    };
});

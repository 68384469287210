AdminParentCtrl.controller('ActivityLogsController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            $compile,
                                                            $timeout,
                                                            Validate,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            CiviExchange,
                                                            LoadLocalData,
                                                            InputValidation){
    // Redirect to User Organisation if Organisation Administrator
    if(Authentication.isOrganisationAdmin()){
        $location.path('/admin/accounts/'+$rootScope.authorisedUser.organisation_id);
    }
    
    // Global Variables
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.variant = '';
    $scope.page.header.breadcrumbs = 'Administration';
    $scope.page.header.title = 'Activity Log';
    $scope.page.header.backLink = '';
    $scope.page.sidebar.variant = 'accountsAdmin';
    $scope.page.sidebar.selectedNav = 'Activity Logs';
    
    $scope.getType = function(type){
        if(type === '0'){
            return 'Validation';
        }else if(type === '1'){
            return 'Transfer';
        }
    };
    
    // Get an element's distance from the top of the page
    var getElemDistance = function ( elem ) {
        var location = 0;
        if (elem.offsetParent) {
            do {
                location += elem.offsetTop;
                elem = elem.offsetParent;
            } while (elem);
        }
        return location >= 0 ? location : 0;
    };
    
    $scope.activityLogs = [];
    var activityLog = {
        loading: false,
        id: {
            lowest: null,
            highest: null
        },
        $elem: document.getElementById('activityLogs'),
        loadingActivityLogs: Object.create(load),
        loadActivityLogs: function(type, logId){
            if(activityLog.loading){
                return false;
            }
            activityLog.loading = true;

            var getVars = {};
            if(Validate.data.isSet(type) && Validate.data.isSet(logId)){
                getVars.type = type;
                getVars.logId = logId;
            }
            
            $('.inner-content').append(activityLog.loadingActivityLogs.begin('loadActivityLogs', 'inline', 'Retrieving logs'));
            CiviExchange.get.activityLogs(getVars).then(function(activityLogs){
                console.info('Found activity logs', activityLogs);

                for(var i = 0; i < activityLogs.length; i++){
                    var newID = parseInt(activityLogs[i].id);
                    if(!Validate.data.isSet(activityLog.id.lowest) || newID < activityLog.id.lowest){
                        activityLog.id.lowest = newID;
                    }
                    
                    if(!Validate.data.isSet(activityLog.id.highest) || newID > activityLog.id.highest){
                        activityLog.id.highest = newID;
                    }
                }
                
                activityLog.loadingActivityLogs.remove();
                var newActivityLogs = $scope.activityLogs.concat(activityLogs);
                // Alphabetical order by name sort
                newActivityLogs.sort(function(a,b) {return (a.activity.time_stamp < b.activity.time_stamp) ? 1 : ((b.activity.time_stamp < a.activity.time_stamp) ? -1 : 0);} );
                $scope.activityLogs = newActivityLogs;
                activityLog.loading = false;
                activityLog.update(5000);
            }, function(errorData){
                console.error('Issue loading activity logs', errorData);

                activityLog.loading = false;
                if(activityLog.loadingActivityLogs.retry('', 'Error recieved ('+ errorData +').')){
                    return activityLog.loadActivityLogs(type, logId);
                }else{
                    activityLog.loadingActivityLogs.remove();
                    var retError = '<p class="error">Could not retrieve logs. Please contact your administrator if this issue persists.<br>('+ errorData +')</p>';
                    console.error('Complete Fail: Issue loading activity logs', errorData);
                    activityLog.update(60000);
                }
            });
        },
        updateTimer: null,
        update: function(delay){
            $timeout.cancel(activityLog.updateTimer);
            activityLog.updateTimer = $timeout(function(){
                console.info('Loading newer activity logs');
                activityLog.loadActivityLogs('new', activityLog.id.highest);
            }, delay);
            
            $scope.$on(
                "$destroy",
                function(event) {
                    $timeout.cancel(activityLog.updateTimer);
                }
            );
        },
        scrollListener: function(){
            // Load more on scroll
            document.addEventListener('scroll', function(){
                if(activityLog.loading){
                    return false;
                }

                var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
                var windowBottom = scrollTop + window.innerHeight;
                var documentHeight = getElemDistance(activityLog.$elem) + activityLog.$elem.clientHeight;

                if(windowBottom > documentHeight){
                    console.info('Loading older activity logs');
                    activityLog.loadActivityLogs('old', activityLog.id.lowest);
                }
            });
        }
    };
    
    activityLog.loadActivityLogs();
    activityLog.scrollListener();
});
// app/js/controllers/LoadingCtrl.js
angular.module('LoadingCtrl', []).controller('LoadingController', function($scope, $location, Authentication, Application, $localStorage){
    Application.registerListener(function(){
        if($localStorage.requestedRoute){
            $location.path($localStorage.requestedRoute);
            $localStorage.requestedRoute = '';
        }else{
            $location.path('/');
        }
    });
});

// app/js/controllers/HomeCtrl.js
Service.factory("CiviCRM", function CiviCRM($q, $http, $timeout, $localStorage, apiService, Validate, CiviCRMClients, CiviCRMSessions, CiviCRMCases){
    var dataSet = function(data){
        if(!Validate.data.isSet(data)){
            console.error('CiviCRM API: Data is required');
            return false;
        }
        return true;
    };
    
    var updateSet = function(update){
        if(update !== true){
            return false;
        }
        return true;
    };
    
    return {        
        get: {
            startingClientCharacter: function(data){
                return CiviCRMClients.getStartingCharacter(data);
            },
            client: function(data, update){
                if(!dataSet(data)){
                    return false;
                }
                update = updateSet(update);
                return CiviCRMClients.getClient(data, update);
            },
            clients: function(data, update){
                if(!dataSet(data)){
                    return false;
                }
                update = updateSet(update);
                return CiviCRMClients.getClients(data, update);
            },
            sessions: function(data, update){
                if(!dataSet(data)){
                    return false;
                }
                update = updateSet(update);
                return CiviCRMSessions.getSessions(data, update);
            },
            userSessions: function(data, update){
                if(!dataSet(data)){
                    return false;
                }
                update = updateSet(update);
                return CiviCRMSessions.getUserSessions(data, update);
            },
            cases: function(data, update){
                if(!dataSet(data)){
                    return false;
                }
                update = updateSet(update);
                return CiviCRMCases.getCases(data, update);
            }
        },
        
        find: {
            clients: {
                query: function(data){
                    return CiviCRMClients.findClients(data);
                },
                firstChar: function(data){
                    return CiviCRMClients.filterClients(data);
                },
                withSessions: function(data){
                    if(!dataSet(data)){
                        return false;
                    }
                    return CiviCRMClients.withSessions(data);
                }
            },
            sessions: {
                uniqueSessionTypeIDs: function(){
                    return CiviCRMSessions.findUniqueSessionTypeIDs();
                },
                all: function(data){
                    if(!dataSet(data)){
                        return false;
                    }
                    return CiviCRMSessions.findSessions(data);
                },
                byOutlet: function(data){
                    if(!dataSet(data)){
                        return false;
                    }
                    return CiviCRMSessions.byOutlet(data);
                },
                homeMaintenance: function(data){
                    if(!dataSet(data)){
                        return false;
                    }
                    return CiviCRMSessions.homeMaintenance(data);
                },
                homeModifications: function(data){
                    if(!dataSet(data)){
                        return false;
                    }
                    return CiviCRMSessions.homeModifications(data);
                },
                alliedHealth: function(data){
                    if(!dataSet(data)){
                        return false;
                    }
                    return CiviCRMSessions.alliedHealth(data);
                }
            }
        },
        
        display: {
            clients: {
                main: function(data){
                    return CiviCRMClients.displayClientsMain(data);
                },
                filterList: function(data){
                    return CiviCRMClients.displayFilterList(data);
                }
            },
            sessions: {
                main: function(data){
                    return CiviCRMSessions.displaySessionsMain(data);
                }
            }
        },
        
        clearAll: function(){
            CiviCRMClients.clear();
            CiviCRMSessions.clear();
        }
    };
});

// app/js/controllers/HomeCtrl.js
Service.factory("CiviCRMCases", function CiviCRMCases($q, $http, $timeout, $rootScope, $localStorage, Validate, apiService, FORMATTER){
    
    var sendToApi = function(deferred, data){
        apiService.get(data, true).then(function(data){
            if(data.response.success){
                deferred.resolve(data.data);
            }else if(!data.response.success){
                deferred.reject(data.response.message);
            }
        }, function(errorCode){
            var output = FORMATTER.process(errorCode);
            console.error('CiviCRM Cases: Issue with '+data.action, output);
            deferred.reject(output);
        });
    };

    return {        
        getCase: function(data, update){
            var deferred = $q.defer();
            var that = this;
            
            data.action = 'getCase';
            sendToApi(deferred, data);
            
            return deferred.promise;
        },
        
        getCases: function(data, update){
            var deferred = $q.defer();
            var that = this;
            
            var casesIds = [];
            var cases = {};
            
            function getCase(caseId){
                var actions = {action: 'getCase', 
                               CaseId: caseId};
                
                apiService.post(actions, true).then(function(data){
                    if(data.response.success && 
                       Validate.data.isSet(data.Case)){

                        return data.Case;
                    }
                }, function(errorData){
                    console.error(actions.action, errorData);
                });
            }
            
            var duplicate = false;
            for(var i = 0; i < data.sessions.length; i++){
                for(var j = 0; j < casesIds.length; j++){
                    if(data.sessions[i].CaseId == casesIds[j]){
                        duplicate = true;
                        break;
                    }
                }
                
                if(!duplicate){
                    casesIds.push(data.sessions[i].CaseId);
                    
                    var currentCase = getCase(data.sessions[i].CaseId);
                    if(currentCase){
                        cases += currentCase;
                    }
                }
                duplicate = false;
            }
            
            deferred.resolve(cases);
            return deferred.promise;
        },
        
        clear: function(){
            
        }

    };
});

// app/js/controllers/HomeCtrl.js
Service.factory("CiviCRMClients", function CiviCRMClients($q, $http, $timeout, $localStorage, $rootScope, Validate, apiService, FORMATTER){
    var startingCharacter = 'all';
    var clients = null;
    
    var clientsMainDisplay = function(data, first, last){
        if(data.length <= 0){
            return '';
        }
        
        data.sort(function(a,b) {return (a.LastName.toUpperCase() > b.LastName.toUpperCase()) ? 1 : ((b.LastName.toUpperCase() > a.LastName.toUpperCase()) ? -1 : 0);} );
        
        var html = "";
        html += '<div class="data-list">';
        
        if(typeof first !== 'number'){
            first = 0;
        }
        
        if(typeof last !== 'number' || last <= first){
            last = data.length - 1;
        }
        
        var currentChar = '';
        
        for(var i = first; i <= last; i++){
            var value = data[i];
            var firstChar = value.LastName.charAt(0).toUpperCase();
            
            if(firstChar !== currentChar){
                currentChar = firstChar;
                html += '<div class="data-title"><h2>'+ currentChar +'</h2></div>';
                html += '<div class="data">';
                html += '<div class="data-id">ID</div>';
                html += '<div class="data-name">Name</div>';
                html += "</div>";
            }
            
            html += '<div class="data view-btn" ng-click="openClient('+ value.ClientID +')">';
            
            html += '<div class="data-id">'+ value.ClientID +'</div>';
            
            html += '<div class="data-name">'+ value.LastName +', '+ value.FirstName +'</div>';
            
            html += "</div>";
        }
        
        html += "</div>";

        return html;
    };
    
    var filterListDisplay = function(data){
        if(data.length <= 0){
            return '';
        }
        
        data.sort(function(a,b) {return (a.LastName.toUpperCase() > b.LastName.toUpperCase()) ? 1 : ((b.LastName.toUpperCase() > a.LastName.toUpperCase()) ? -1 : 0);} );
        var html = "";
        var currentChar = '';
        startingCharacter = 'all';
        
        html += '<div class="data-list">';
        html += '<div class="data-title-filter">';
        html += '<ul class="internal-wrap">';
        html += '<li data-char="all" ng-click="filterByCharacter(\'all\')">All</li>';
        for(var i = 0; i <= data.length - 1; i++){
            var value = data[i];
            var firstChar = value.LastName.charAt(0).toUpperCase();
            
            if(firstChar !== currentChar){
                if(startingCharacter === 'all'){
                    startingCharacter = currentChar;
                }
                currentChar = firstChar;
                html += '<li data-char="'+ currentChar +'" ng-click="filterByCharacter(\''+ currentChar +'\')">'+ currentChar +'</li>';
            }
        }
        html += '</ul></div></div>';

        return html;
    };

    return {        
        getClients: function(data, update){
            var deferred = $q.defer();
            var that = this;
            var error = 'CiviCRM Sessions: Issue retrieving clients';
            
            if(!Validate.data.isSet(data.startDate) || 
               !Validate.data.isSet(data.endDate)){
                console.error('CiviCRM Sessions: Data for getClients was not specified correctly');
                deferred.reject('Data for getClients was not specified correctly');
            }

            if(clients === null || update === true){
                var actions = {action: 'getCiviCRMClients', start_date: data.startDate, end_date: data.endDate};

                apiService.post(actions, true).then(function(data){
                    
                    try{
                        var reconstructedData = JSON.parse(data.data);

                        if(!data.response.success || !Validate.data.isSet(reconstructedData.results)){
                            throw error;
                        }

                        reconstructedData.results.sort(function(a,b) {return (a.LastName.toUpperCase() > b.LastName.toUpperCase()) ? 1 : ((b.LastName.toUpperCase() > a.LastName.toUpperCase()) ? -1 : 0);} );
                       	that.processClientData(reconstructedData.results).then(function(newData){
                            clients = newData;
                            deferred.resolve(clients);
                        }, function(errorData){
                            console.error(error, errorData);
                            deferred.reject(error);
                        });
                    }catch(errorData){
                        console.error(error, errorData);
                        deferred.reject(error);
                    }
                    
                }, function(errorData){
                    var output = FORMATTER.process(errorData);
                    console.error(error, output);
                    deferred.reject(output);
                });
            }else{
                deferred.resolve(clients);
            }
            return deferred.promise;
        },
        
        getClient: function(id){
            var result = clients.filter(function( obj ) {
                return obj.ClientID == id;
            })[0];
            return result;
        },
        
        processClientData: function(data){
            var deferred = $q.defer();
            var that = this;
            var error = 'CiviCRM Sessions: Issue processing client data';
            
            that.cleanData(data).then(function(newData){
                that.convertCountries(newData).then(function(newData){
                    clients = newData;
                    deferred.resolve(clients);
                }, function(errorData){
                    console.error(error, errorData);
                    deferred.reject(errorData);
                });
            }, function(errorData){
                console.error(error, errorData);
                deferred.reject(errorData);
            });
            
            return deferred.promise;
        },
        
        cleanData: function(data){
            var deferred = $q.defer();
			
			var orgUid = '';
			if(Validate.data.isSet($rootScope.authorisedUser.organisation.uid)){
				console.info('Org UID found');
				orgUid = '-'+ $rootScope.authorisedUser.organisation.uid;
			}
            
            for(var i = 0; i < data.length; i++){
				
				// Prepend Org UID
				data[i].ClientUID = data[i].ClientID + orgUid;
                
                // Clean Disabilities
                if(!Validate.data.isSet(data[i].Disabilities) || data[i].Disabilities === ''){
                    data[i].HasDisabilities = false;
                    data[i].Disabilities = 'Not Found';
                }else if(data[i].Disabilities === '6'){
                    data[i].HasDisabilities = false;
                    data[i].Disabilities = 'NO DISABILITY';
                }else{
                    data[i].HasDisabilities = true;
                    var disabilities = data[i].Disabilities.split(',');
                    data[i].Disabilities = [];
                    for(var j = 0; j < disabilities.length; j++){
                        if(disabilities[j] !== ''){
                            data[i].Disabilities.push({'DisabilityCode': disabilities[j]});
                        }
                    }
                }
				
				// Clean CountryOfBirthCode
                if(!Validate.data.isSet(data[i].CountryOfBirthCode) || 
                    data[i].CountryOfBirthCode === '' || 
				    data[i].CountryOfBirthCode === '3'){
					data[i].CountryOfBirthCode = '0003';
				}
				
				// Clean LanguageSpokenAtHomeCode
                if(!Validate.data.isSet(data[i].LanguageSpokenAtHomeCode) || 
                    data[i].LanguageSpokenAtHomeCode === '' || 
				    data[i].LanguageSpokenAtHomeCode === '0001'){
					data[i].LanguageSpokenAtHomeCode = '0002';
				}
            }
            
            deferred.resolve(data);
            return deferred.promise;
        },
        
        convertCountries: function(data){
            var deferred = $q.defer();

            var error = 'CiviCRM Sessions: Issue converting countries';
            var actions = {action: 'getCountries'};

            apiService.post(actions, true).then(function(countries){
                if(countries.response.countries.response.success){
                    
                    // Check if country conversion from CiviCRM code to DEX code is enabled
                    if($rootScope.authorisedUser.organisation.settings.convert_country != 0){
                        
                        var countriesData = countries.data.countries;
                        // Convert country codes to valid DEX codes
                        for(var i = 0; i < data.length; i++){
                            for(var j = 0; j < countriesData.length; j++){
                                if(data[i].CountryOfBirthCode == countriesData[j].civicode){
                                    data[i].CountryOfBirthCode = countriesData[j].dsscode;
                                    break;
                                }
                            }
                        }

                    }
                    
                    var statesData = countries.data.states;
                    // Convert state codes to valid DEX codes
                    for(var i = 0; i < data.length; i++){
                        for(var j = 0; j < statesData.length; j++){
                            if(data[i].State == statesData[j].civicode){
                                data[i].State = statesData[j].dsscode;
                                break;
                            }
                        }
                    }
                    
                    deferred.resolve(data);
                }else{
                    console.error(error, countries);
                    deferred.reject(countries.response.code +': '+ countries.response.message);
                }
                deferred.resolve(data);
            }, function(errorData){
                var output = FORMATTER.process(errorData);
                console.error(error, output);
                deferred.reject(output);
            });

            return deferred.promise;
        },
        
        findClients: function(data){
            var deferred = $q.defer();
            
            if(!Validate.data.isSet(data.data)){
                data.data = clients;
            }
            
            if(!Validate.data.isSet(data.query)){
                data.query = '';
            }
            
            var result = data.data;
            if(data.query !== ''){
                result = data.data.filter(function( obj ) {
                    var firstName = obj.FirstName.toLowerCase();
                    var lastName = obj.LastName.toLowerCase();
                    if(firstName.indexOf(data.query.toLowerCase()) >= 0 || lastName.indexOf(data.query.toLowerCase()) >= 0){
                        return obj;
                    }
                });
            }
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        filterClients: function(data){
            var deferred = $q.defer();
            
            if(!Validate.data.isSet(data.data)){
                data.data = clients;
            }
            
            if(!Validate.data.isSet(data.query)){
                data.query = 'all';
            }
            
            var result = data.data;
            if(data.query !== 'all'){
                result = data.data.filter(function( obj ) {
                    var lastName = obj.LastName.toLowerCase();
                    if(lastName.charAt(0).toLowerCase() == data.query.toLowerCase()){
                        return obj;
                    }
                });
            }
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        displayClientsMain: function(data){
            if(clients === null){
                return '';
            }
            if(!Validate.data.isSet(data) && !Validate.data.isSet(data.data)){
                data.data = clients;
            }
            return clientsMainDisplay(data.data, data.first, data.last);
        },
        
        displayFilterList: function(data){
            if(!Validate.data.isSet(data) && !Validate.data.isSet(data.clients)){
                data = {};
                data.clients = clients;
            }
            if(data.clients === null){
                return '';
            }
            return filterListDisplay(data.clients);
        },
        
        getStartingCharacter: function(data){
            if(!Validate.data.isSet(data) && !Validate.data.isSet(data.clients)){
                data = {};
                data.clients = clients;
            }
            if(data.clients === null || data.clients.length <= 0){
                return 'all';
            }
            
            data = data.clients;
            data.sort(function(a,b) {return (a.LastName.toUpperCase() > b.LastName.toUpperCase()) ? 1 : ((b.LastName.toUpperCase() > a.LastName.toUpperCase()) ? -1 : 0);} );
            
            return data[0].LastName.charAt(0).toUpperCase();
        },
        
        withSessions: function(data){
            var deferred = $q.defer();
            var error = 'Sessions not set';
            
            if(!Validate.data.isSet(data.sessions)){
                deferred.reject('CiviCRM Sessions: '+error);
                deferred.reject(error);
                return deferred.promise;
            }
            
            console.log('Pre Session Client Filter', clients);
            
            var result = {};
            result.clients = [];
            result.sessions = [];
            for(var i = 0; i < clients.length; i++){
                var clientFound = false;
                if(clients[i].ClientID == '2409'){
                    console.log('Client Filter: Client found', clients[i]);
                }
                for(var j = 0; j < data.sessions.length; j++){
                    if(clients[i].ClientID == data.sessions[j].ClientId){
                        if(clients[i].ClientID == '2409'){
                            console.log('Client passed test');
                        }
                        if(!clientFound){
                            clientFound = true;
                            result.clients.push(clients[i]);
                        }
                        result.sessions.push(data.sessions[j]);
                    }
                }
            }
            
            console.log('Post Session Client Filter', result.clients);
            
            deferred.resolve(result);
            return deferred.promise;
        },
        
        clear: function(){
            startingCharacter = 'all';
            clients = null;
        }

    };
});

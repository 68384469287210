// app/js/controllers/HomeCtrl.js
angular.module('DataTransmitter')
    .factory("InputValidation", function InputValidation($q, $http, $timeout, $localStorage, Validate, CiviExchange){
    var cError = 'form__input--error';
    
    var setError = function($elem, message){
        console.error('Input Validation: Input Data Invalid', message);
        if(Validate.data.isSet($elem) && Validate.data.isSet(message)){
            if(!$elem.hasClass(cError)){
                $elem.addClass(cError);
            }
            $elem.attr('title', message);
            $elem.next('.form__error').text(message);
            $elem.next('.form__error').addClass('visible');
        }
    };
    
    var removeError = function($elem, message){
        if(Validate.data.isSet($elem)){
            if($elem.hasClass(cError)){
                $elem.removeClass(cError);
            }
            $elem.attr('title', '');
            $elem.next('.form__error').text('');
            $elem.next('.form__error').removeClass('visible');
        }
    };
    
    return {        
        removeError: function($elem){
            removeError($elem);
        },
        
        length: function(value, $elem, message){
            if(!Validate.data.isSet(value) || !value.length){
                setError($elem, message);
                return false;
            }

            removeError($elem);
            return true;
        },
        
        isMaxLength: function(value, length, $elem, message){
            if(Validate.data.isSet(value) && value.length > length){
                setError($elem, message);
                return false;
            }

            removeError($elem);
            return true;
        },
        
        isNum: function(value, $elem, message){
            if(Validate.data.isSet(value) && typeof value !== 'number'){
                setError($elem, message);
                return false;
            }

            removeError($elem);
            return true;
        },
        
        equality: function(value1, value2, $elem1, $elem2, message){
            if(Validate.data.isSet(value1) && Validate.data.isSet(value2) && value1 !== value2){
                setError($elem1, message);
                setError($elem2, message);
                return false;
            }

            removeError($elem1);
            removeError($elem2);
            return true;
        },
        
        usernameExists: function(value, $elem, message, currentUser){
            if(Validate.data.isSet(currentUser) && currentUser === value){
                removeError($elem);
                return false;
            }
            
            CiviExchange.get.users().then(function(users){
                for(var i = 0; i < users.length; i++){
                    if(users[i].username === value){
                        setError($elem, message);
                        return true;
                    }
                }
                
                removeError($elem);
                return false;
            }, function(error){
                setError($elem, 'Issue checking users');
                return true;
            });
        },
        
        containsSpecialCharacters: function(value, $elem, message){
            var format = /[ !@#$%^&*()+\=\[\]{};':"\\|,.<>\/?]/;
            
            if(!Validate.data.isSet(value) || format.test(value)){
                setError($elem, message);
                return true;
            }

            removeError($elem);
            return false;
        },
        
        emailExists: function(email, $elem, message){
            var emailReg = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if(Validate.data.isSet(email) && !emailReg.test(email)){
                setError($elem, message);
                return false;
            }

            removeError($elem);
            return true;
        },
        
        email: function(email, $elem, message){
            var emailReg = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if(Validate.data.isSet(email) && !emailReg.test(email)){
                setError($elem, message);
                return false;
            }

            removeError($elem);
            return true;
        },
        
        organisationNameExists: function(value, $elem, message, currentOrg){
            if(Validate.data.isSet(currentOrg) && currentOrg === value){
                removeError($elem);
                return false;
            }
            
            CiviExchange.validate.organisation({name: value}).then(function(exists){
                if(exists){
                    setError($elem, message);
                    return true;
                }
                
                removeError($elem);
                return false;
            }, function(error){
                setError($elem, 'Issue validating organisation');
                return true;
            });
        },
        
        dssUsernameExists: function(value, $elem, message, currentOrg){
            if(Validate.data.isSet(currentOrg) && Validate.data.isSet(currentOrg.username) && currentOrg.username === value){
                removeError($elem);
                return false;
            }
            
            CiviExchange.get.organisations().then(function(organisations){
                for(var i = 0; i < organisations.length; i++){
                    if(organisations[i].username === value){
                        setError($elem, message);
                        return true;
                    }
                }
                
                removeError($elem);
                return false;
            }, function(error){
                setError($elem, 'Username already exists');
                return true;
            });
        },
        
        isURL: function(value, $elem, message){
            var urlReg = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            if(Validate.data.isSet(value) && !urlReg.test(value)){
                setError($elem, message);
                return false;
            }

            removeError($elem);
            return true;
        },
        
        isBool: function(value, $elem, message){
            if(Validate.data.isSet(value) && !(value == 0 || value == 1 || value == true || value == false || value == 'on' || value == 'off')){
                setError($elem, message);
                return false;
            }

            removeError($elem);
            return true;
        }
    };
});

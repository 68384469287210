AdminParentCtrl.controller('DSS-ServicesAdminController', function($rootScope,
                                                            $scope,
                                                            $routeParams,
                                                            $location,
                                                            $localStorage,
                                                            $sessionStorage,
                                                            $window,
                                                            Authentication,
                                                            Application,
                                                            RouteFilter,
                                                            DEX,
                                                            LoadLocalData){
    $scope.page = {};
    $scope.page.header = {};
    $scope.page.sidebar = {};
    $scope.page.header.breadcrumbs = 'Service Administration';
    $scope.page.header.title = 'Department of Social Services';
    $scope.page.header.backLink = '#!/services';
    $scope.page.sidebar.variant = 'serviceDSSAdmin';
    $scope.page.sidebar.selectedNav = 'Data Reference Codes';
    
    $scope.updateAvailable = false;
    
    // Scope variables
    $scope.dataReference = {
        code: null,
        availableOptions: [
            {name: 'All'},
            {name: 'AboriginalOrTorresStraitIslanderOrigin'},
            {name: 'AccommodationType'},
            {name: 'Ancestry'},
            {name: 'AssessedBy'},
            {name: 'AssessmentPhase'},
            {name: 'AttendanceProfile'},
            {name: 'Country'},
            {name: 'Disability'},
            {name: 'DVACardStatus'},
            {name: 'EducationLevel'},
            {name: 'EmploymentStatus'},
            {name: 'ExitReason'},
            {name: 'ExtraItem'},
            {name: 'Gender'},
            {name: 'HomelessIndicator'},
            {name: 'HouseholdComposition'},
            {name: 'IncomeFrequency'},
            {name: 'Language'},
            {name: 'MainSourceOfIncome'},
            {name: 'MigrationVisaCategory'},
            {name: 'MoneyBusinessCommunityEducationWorkshop'},
            {name: 'NDISEligibility'},
            {name: 'ParentingAgreement'},
            {name: 'ParticipationType'},
            {name: 'ReasonForAssistance'},
            {name: 'ReferralPurpose'},
            {name: 'ReferralSource'},
            {name: 'ReferralType'},
            {name: 'ScoreType'},
            {name: 'Section60ICertificateType'},
            {name: 'ServiceSetting'},
            {name: 'State'},
            {name: 'Topic'}
        ],
    };
    
    $scope.update = function() {
        console.info("Update Local Data Reference");
        $scope.compareData = '';
        var code = $scope.dataReference.code;
        if(code == 'All'){
            code = '';
        }
        
        $scope.getLocalReferenceData(code);
        $scope.getExternalReferenceData(code);
    };
    
    $scope.getLocalReferenceData = function(code) {
        LoadLocalData.get.localReferenceData({code: code}, false, 'inline').then(function(referenceData){
            console.info('Loaded Local Reference', referenceData);
            $scope.localReferenceData = referenceData.referenceData;
            $scope.localReferenceLoaded = true;
            $scope.compareDisplayData(code);
        }, function(errorData){
            console.info('Issue Loading Local Reference', errorData);
        });
    };
    
    $scope.getExternalReferenceData = function(code) {
        LoadLocalData.get.externalReferenceData({code: code}, false, 'inline').then(function(referenceData){
            console.info('Loaded External Reference', referenceData);
            $scope.referenceData = referenceData;
            $scope.externalReferenceLoaded = true;
            $scope.compareDisplayData(code);
        }, function(errorData){
            console.info('Issue Loading External Reference', errorData);
        });
    };
    
    // TODO: Move Compare Logic into ReferenceData
    $scope.localReferenceLoaded = false;
    $scope.externalReferenceLoaded = false;
    $scope.compareDisplayData = function(code){
        if($scope.localReferenceLoaded && $scope.externalReferenceLoaded){
            $scope.localReferenceLoaded = false;
            $scope.externalReferenceLoaded = false;
            $scope.compareData = DEX.display.referenceData.main(code);
            $scope.updateAvailable = DEX.find.referenceData.mismatch();
            $scope.mismatchTotalAmount = DEX.find.referenceData.mismatchTotalAmount();
        }
    };
    
    var updateData = Object.create(load);
    $scope.updateDataReference = function() {
        var code = $scope.dataReference.code;
        var error = 'Updating Local Reference Data in Database';
        $('.inner-content').append(updateData.begin('updateReferenceData', 'inline', 'Updating Reference Data'));
        DEX.update.referenceData.byCode(code).then(function(){
            $scope.compareData = '';
            $scope.update();
            updateData.remove();
        }, function(errorData){
            console.error(error, errorData);
            if(updateData.retry('', 'Error recieved ('+ errorData +').')){
                $scope.updateDataReference();
            }else{
                $scope.compareData = '<p class="error">Could not update Reference Data. Please contact your administrator if this issue persists.<br>('+ errorData +')</p>';
                console.error('Complete Fail: '+error, errorData);
            }
        });
    };
});
